<template>
  <div class="logs-component" style="padding-top: 60px;">
    <!-- Header -->
    <div class="logs-header">
      <v-icon size="24" color="#0d2c6d" class="mr-2">mdi-text-box-search</v-icon>
      <span class="text-h6 font-weight-bold">לוגים</span>
    </div>

    <v-container fluid class="pa-0">
      <v-card class="logs-card">
        <!-- Filters Section -->
        <div class="filters-section">
          <div class="d-flex flex-wrap align-center">
            <v-text-field v-model="dateLogs" type="date" outlined dense hide-details
              class="filter-field mr-3"></v-text-field>

            <v-select v-model="filterLogs" :items="logsType" outlined dense hide-details label="סינון לוגים"
              class="filter-field mr-3"></v-select>

            <v-text-field v-model="search" label="חיפוש" outlined dense hide-details class="filter-field mr-3 ml-3"
              prepend-inner-icon="mdi-magnify"></v-text-field>

            <v-btn icon class="refresh-button" @click="refreshDetails" :loading="progressShow">
              <v-icon>mdi-refresh</v-icon>
            </v-btn>

            <v-btn outlined small class="clean-cache-button mr-3" @click="cleanCache">
              <v-icon left small>mdi-cached</v-icon>
              ניקוי זיכרון זמני
            </v-btn>

            <div class="results-count mr-3">
              <span class="text-subtitle-2">תוצאות לוגים: </span>
              <span class="font-weight-medium">{{ filteredLogs.length }}</span>
            </div>

          </div>

          <!-- Results Count -->
          <!-- <div class="results-count mt-4">
              <span class="text-subtitle-2">תוצאות לוגים: </span>
              <span class="font-weight-medium">{{ filteredLogs.length }}</span>
            </div> -->
        </div>

        <!-- Loading State -->
        <v-row v-if="progressShow" class="my-4">
          <v-col class="d-flex justify-center">
            <v-progress-circular indeterminate color="#0d2c6d" size="32"></v-progress-circular>
          </v-col>
        </v-row>

        <!-- Logs List -->
        <div class="logs-list" v-else>
          <div v-for="(log, index) in paginatedLogs" :key="index" class="log-item">
            <!-- Log Header -->
            <div class="log-header">
              <div class="log-index">#{{ startIndex + index + 1 }}</div>
              <div class="log-timestamp">{{ log.date }} {{ log.time }}</div>
              <v-chip small :color="getLevelColor(log.level)" text-color="white" class="log-level">
                {{ formatLevel(log.level) }}
              </v-chip>
            </div>

            <!-- Log Message -->
            <div class="log-message" v-html="formatLogMessage(log.message)"></div>
          </div>

          <!-- Empty State -->
          <div v-if="filteredLogs.length === 0" class="empty-state">
            <v-icon size="48" color="rgba(13, 44, 109, 0.1)" class="mb-4">mdi-text-box-search-outline</v-icon>
            <p class="text-subtitle-1 text-medium-emphasis">לא נמצאו לוגים</p>
          </div>
        </div>

        <!-- Pagination -->
        <div class="pagination-section" v-if="filteredLogs.length > 0">
          <div class="pagination-info">
            <span class="text-body-2 text-medium-emphasis">מציג {{ startIndex + 1 }} - {{ endIndex }} מתוך {{
              filteredLogs.length }}</span>
          </div>
          <v-pagination v-model="currentPage" :length="totalPages" :total-visible="7" circle></v-pagination>
          <div class="items-per-page">
            <v-select v-model="itemsPerPage" :items="itemsPerPageOptions" label="פריטים בעמוד" dense outlined
              hide-details class="items-per-page-select"></v-select>
          </div>
        </div>
      </v-card>
    </v-container>

    <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>
  </div>
</template>

<script>
import SnackBar from '@/components/widgets/snackBar.vue'
import ApiServices from '@/services/api.service'
import Utils from "@/util/utils"

export default {
  name: 'LogsComp',
  components: {
    SnackBar
  },
  data: () => ({
    overlay: false,
    editPlanDialog: false,
    snackbar: false,
    snackbarColorBt: "success",
    snacbarText: "",
    progressShow: true,
    logs: "",
    dateLogs: "",
    filterLogs: "הכל",
    logsType: ["הכל", "שגיאות", "מידע"],
    search: "",
    currentPage: 1,
    itemsPerPage: 100,
    itemsPerPageOptions: [
      { text: '100 פריטים', value: 100 },
      { text: '500 פריטים', value: 500 },
      { text: '1000 פריטים', value: 1000 }
    ]
  }),
  computed: {
    filteredLogs() {
      let filtered = this.logs;

      if (this.filterLogs === "שגיאות") {
        filtered = filtered.filter(log =>
          log.level === "error:" || log.level === "exception:"
        );
      } else if (this.filterLogs === "מידע") {
        filtered = filtered.filter(log =>
          log.level !== "error:" && log.level !== "exception:"
        );
      }

      if (this.search) {
        return filtered.filter(item =>
          Object.values(item).some(value =>
            value && value.toString().toLowerCase().includes(this.search.toLowerCase())
          )
        );
      }

      return filtered;
    },
    totalPages() {
      return Math.ceil(this.filteredLogs.length / this.itemsPerPage);
    },
    startIndex() {
      return (this.currentPage - 1) * this.itemsPerPage;
    },
    endIndex() {
      return Math.min(this.startIndex + this.itemsPerPage, this.filteredLogs.length);
    },
    paginatedLogs() {
      return this.filteredLogs.slice(this.startIndex, this.endIndex);
    }
  },
  methods: {
    async getLogs() {
      try {
        this.progressShow = true;
        const token = localStorage.getItem("token");
        const api = process.env.VUE_APP_BASE_URL + `/logs/get_today_logs?date=${this.dateLogs}`;
        const res = await fetch(api, ApiServices.requestOptions("GET", "", token));
        const jsonObject = await res.json();

        if (res.status >= 400) {
          this.showSnackBar("שגיאה בקבלת נתונים", "error");
        } else if (res.status === 200 && jsonObject) {
          this.logs = jsonObject.split('\n').map(line => {
            const parts = line.split(' ');
            return {
              date: parts[0],
              time: parts[1],
              level: parts[2],
              message: parts.slice(3).join(' ')
            };
          });
          this.currentPage = 1; // Reset to first page when new data is loaded
        }
      } catch (error) {
        this.showSnackBar("שגיאה בקבלת לוגים: " + error, "error");
      } finally {
        this.progressShow = false;
      }
    },
    formatLogMessage(message) {
      const keywords = /\b(SessionId|DataName|TableName|By|Message|Item|Error)\b/g;
      return message.replace(keywords, '<strong>$1</strong>');
    },
    getLevelColor(level) {
      switch (level) {
        case 'info:': return '#10B981';
        case 'error:': return '#EF4444';
        case 'exception:': return '#EF4444';
        default: return '#F59E0B';
      }
    },
    formatLevel(level) {
      if (level) {
        return level.replace(':', '').toUpperCase();
      }
    },
    async cleanCache() {
      try {
        const api = process.env.VUE_APP_BASE_URL + "/logs/settings_clean_cache";
        const token = localStorage.getItem("token");
        const res = await fetch(api, ApiServices.requestOptions("POST", "", token));

        if (res.status === 200) {
          this.showSnackBar("הזיכרון הזמני נוקה בהצלחה", "success");
        } else {
          this.showSnackBar("שגיאה בניקוי הזיכרון הזמני", "error");
        }
      } catch (error) {
        this.showSnackBar("שגיאה: " + error.message, "error");
      }
    },
    showSnackBar(message, color) {
      this.snacbarText = message;
      this.snackbarColorBt = color;
      this.snackbar = true;
    },
    setDateLogsToCurrentDate() {
      const today = new Date();
      this.dateLogs = today.toISOString().substr(0, 10);
    },
    refreshDetails() {
      this.getLogs();
    }
  },
  watch: {
    dateLogs() {
      this.getLogs();
    },
    search() {
      this.currentPage = 1; // Reset to first page when searching
    },
    filterLogs() {
      this.currentPage = 1; // Reset to first page when filter changes
    },
    itemsPerPage() {
      this.currentPage = 1; // Reset to first page when items per page changes
    }
  },
  created() {
    this.setDateLogsToCurrentDate();
  }
}
</script>

<style scoped>
.logs-component {
  min-height: 95vh;
  background: linear-gradient(135deg, #f8fafc 0%, #f0f7ff 100%);
}

.logs-header {
  height: 64px;
  background: linear-gradient(to bottom, #dae1eb, #e2e8f0);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0d2c6d;
  box-shadow: 0 1px 3px rgba(13, 44, 109, 0.1);
}

.logs-card {
  min-height: 85vh;
  background: rgba(255, 255, 255, 0.98);
  border-radius: 16px;
  box-shadow: 0 4px 24px rgba(13, 44, 109, 0.08);
  backdrop-filter: blur(10px);
}

.filters-section {
  padding: 1.5rem;
  background: linear-gradient(to bottom, rgba(13, 44, 109, 0.02), transparent);
  border-bottom: 1px solid rgba(13, 44, 109, 0.1);
}

.filter-field {
  max-width: 200px;
}

.filter-field>>>.v-input__slot {
  border: 1px solid rgba(13, 44, 109, 0.1) !important;
  transition: all 0.3s ease;
}

.filter-field>>>.v-input__slot:hover {
  border-color: rgba(13, 44, 109, 0.2) !important;
  background: rgba(13, 44, 109, 0.03);
}

.refresh-button {
  color: #0d2c6d !important;
  background: rgba(13, 44, 109, 0.05);
  transition: all 0.3s ease;
}

.refresh-button:hover {
  background: rgba(13, 44, 109, 0.1) !important;
  transform: rotate(180deg);
}

.results-count {
  display: flex;
  align-items: center;
  color: #64748b;
}

.logs-list {
  padding: 1.5rem;
  overflow-y: auto;
  height: calc(92vh - 240px);
}

.log-item {
  padding: 1rem;
  border-bottom: 1px solid rgba(13, 44, 109, 0.1);
  transition: all 0.3s ease;
}

.log-item:hover {
  background: rgba(13, 44, 109, 0.02);
}

.log-header {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  direction: ltr;
}

.log-index {
  font-weight: 600;
  color: #0d2c6d;
  margin-right: 1rem;
  min-width: 32px;
}

.log-timestamp {
  color: #64748b;
  margin-right: 1rem;
}

.log-level {
  font-size: 0.75rem;
  font-weight: 500;
}

.log-message {
  direction: ltr;
  color: #334155;
  line-height: 1.5;
  white-space: pre-wrap;
  word-break: break-word;
}

.log-message>>>strong {
  color: #0d2c6d;
  font-weight: 600;
}

/* Custom Scrollbar */
.logs-list::-webkit-scrollbar {
  width: 8px;
}

.logs-list::-webkit-scrollbar-track {
  background: rgba(13, 44, 109, 0.02);
  border-radius: 4px;
}

.logs-list::-webkit-scrollbar-thumb {
  background: rgba(13, 44, 109, 0.1);
  border-radius: 4px;
}

.logs-list::-webkit-scrollbar-thumb:hover {
  background: rgba(13, 44, 109, 0.2);
}

/* RTL Support */
.v-application {
  direction: rtl;
}

.v-text-field>>>.v-input__prepend-inner {
  margin-right: 0;
  margin-left: 8px;
}

.v-text-field>>>.v-input__append-inner {
  margin-left: 0;
  margin-right: 8px;
}

/* Pagination Section */
.pagination-section {
  padding: 1rem 1.5rem;
  background: linear-gradient(to top, rgba(13, 44, 109, 0.02), transparent);
  border-top: 1px solid rgba(13, 44, 109, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pagination-info {
  color: #64748b;
  min-width: 150px;
}

.items-per-page {
  min-width: 150px;
}

.items-per-page-select {
  max-width: 150px;
}

.items-per-page-select>>>.v-input__slot {
  border: 1px solid rgba(13, 44, 109, 0.1) !important;
  transition: all 0.3s ease;
}

.items-per-page-select>>>.v-input__slot:hover {
  border-color: rgba(13, 44, 109, 0.2) !important;
  background: rgba(13, 44, 109, 0.03);
}

/* Empty State */
.empty-state {
  padding: 3rem 1rem;
  text-align: center;
  color: #64748b;
}

/* Responsive Design */
@media (max-width: 960px) {
  .pagination-section {
    flex-direction: column;
    gap: 1rem;
    align-items: center;
  }

  .pagination-info {
    order: 2;
  }

  .items-per-page {
    order: 3;
  }
}

@media (max-width: 600px) {
  .filter-field {
    max-width: 100%;
    margin-bottom: 0.5rem;
  }

  .logs-list {
    padding: 1rem;
  }

  .log-header {
    flex-wrap: wrap;
  }

  .log-timestamp {
    width: 100%;
    margin: 0.25rem 0;
  }

  .pagination-section {
    padding: 1rem;
  }

  .items-per-page-select {
    max-width: 120px;
  }
}

.clean-cache-button {
  color: #0d2c6d !important;
  border-color: rgba(13, 44, 109, 0.2) !important;
  transition: all 0.3s ease;
  animation: slideInRight 0.6s ease-out;
}

.clean-cache-button:hover {
  background: rgba(13, 44, 109, 0.05) !important;
  transform: translateY(-1px);
}
</style>