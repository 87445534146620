<template>
    <v-dialog v-model="openMode" max-width="480px" persistent>
        <v-card class="linked-list-card">
            <!-- Header -->
            <div class="dialog-header">
                <v-icon size="32" color="#0d2c6d" class="mb-4">mdi-link-variant</v-icon>
                <v-card-title class="text-h5 font-weight-bold primary-text pb-2">בחר את מקור הרשימה</v-card-title>
                <v-card-subtitle class="text-subtitle-1 text-medium-emphasis text-center">
                    בחר את הטבלה והשדה שישמשו כמקור לרשימה המקושרת
                </v-card-subtitle>
            </div>

            <!-- Content -->
            <v-card-text class="pt-4">
                <v-form ref="form">
                    <div class="source-fields">
                        <!-- Table Source -->
                        <div class="field-group mb-4">
                            <label class="field-label">טבלת מקור</label>
                            <v-select v-model="fieldEditedLinkedList.linkedList.tableSource" :items="tablesSourceList"
                                item-value="name" item-text="hebrew_name" outlined dense class="custom-select"
                                :rules="[v => !!v || 'שדה חובה']" required></v-select>
                        </div>

                        <!-- Field Source -->
                        <div class="field-group">
                            <label class="field-label">שדה מקור</label>
                            <v-select v-model="fieldEditedLinkedList.linkedList.fieldSource" :items="fieldsSourceList"
                                item-value="name" item-text="hebrew_name" outlined dense class="custom-select"
                                :rules="[v => !!v || 'שדה חובה']" required
                                :disabled="!fieldEditedLinkedList.linkedList.tableSource"></v-select>
                        </div>
                    </div>
                </v-form>
            </v-card-text>

            <!-- Actions -->
            <v-card-actions class="dialog-actions">
                <v-btn text class="cancel-button" @click="closeList">
                    <v-icon left>mdi-close</v-icon>
                    ביטול
                </v-btn>
                <v-btn class="save-button" @click="actionConfirmed" :disabled="!isFormValid">
                    <v-icon left>mdi-check</v-icon>
                    שמור
                </v-btn>
            </v-card-actions>
        </v-card>

        <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>
    </v-dialog>
</template>


<script>
import SnackBar from '@/components/widgets/snackBar.vue'
import ApiServices from '@/services/api.service'

export default {
    props: {
        field: Object,
        fieldIndex: Number,
        value: { type: Boolean, default: false },
    },
    components: {
        SnackBar,
    },
    data: () => ({
        snackbar: false,
        snackbarColorBt: "green",
        snacbarText: "",
        fieldEditedLinkedList: {},
        tablesSourceList: [],
        fieldsSourceList: []
    }),

    methods: {
        async getTableSourceFields() {
            try {
                this.fieldsSourceList = [];
                let token = localStorage.getItem("token");
                const tableJSON = JSON.stringify({ tableName: this.fieldEditedLinkedList.linkedList.tableSource });

                let api = process.env.VUE_APP_BASE_URL + "/user/get_table_properties";
                const res = await fetch(api, ApiServices.requestOptions("POST", tableJSON, token));
                const jsonObject = await res.json();
                if (res.status >= 400) {
                    this.showSnackBar(" שגיאה בקבלת נתונים" + jsonObject.message, "red");
                } else if (res.status === 200) {
                    this.fieldsSourceList = jsonObject.fieldsData;
                }
            } catch (error) {
                this.showSnackBar("Error get items list: " + error, "red");
            }
        },
        showSnackBar(message, color) {
            this.snacbarText = message;
            this.snackbarColorBt = color;
            this.snackbar = true
        },
        actionConfirmed() {
            this.$emit("listLinkedUpdated", this.fieldEditedLinkedList, this.fieldIndex);
            this.openMode = false;
        },
        closeList() {
            this.$emit("listLinkedUpdated");
            this.openMode = false;
        },

    },
    computed: {
        openMode: {
            get() {
                return this.value
            },
            set(v) {
                return this.$emit('input', v)
            }
        },
        isFormValid() {
            return this.fieldEditedLinkedList.linkedList.tableSource &&
                this.fieldEditedLinkedList.linkedList.fieldSource;
        }
    },
    watch: {
        'fieldEditedLinkedList.linkedList.tableSource'(newValue, oldValue) {
            console.log(newValue);
            this.getTableSourceFields();
        }
    },
    created() {
        //deep clone
        // this.fields = this.$store.state.fields;
        this.fieldEditedLinkedList = JSON.parse(JSON.stringify(this.field));
        this.tablesSourceList = this.$store.state.tables;
    },
}
</script>
<style scoped>
.linked-list-card {
    border-radius: 16px;
    overflow: hidden;
    background: rgba(255, 255, 255, 0.98);
    backdrop-filter: blur(10px);
}

.dialog-header {
    padding: 1.5rem 1.5rem 0.5rem;
    text-align: center;
    background: linear-gradient(to bottom, rgba(13, 44, 109, 0.02), transparent);
}

.primary-text {
    background: linear-gradient(135deg, #0d2c6d 0%, #1a4ba5 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.source-fields {
    padding: 1rem;
    background: rgba(13, 44, 109, 0.02);
    border-radius: 12px;
    border: 1px solid rgba(13, 44, 109, 0.1);
}

.field-group {
    margin-bottom: 1rem;
}

.field-label {
    display: block;
    font-size: 0.875rem;
    font-weight: 500;
    color: #64748b;
    margin-bottom: 0.5rem;
}

/* Custom Select Styling */
.custom-select {
    transition: all 0.3s ease;
}

.custom-select>>>.v-input__slot {
    border: 1px solid rgba(13, 44, 109, 0.1) !important;
    transition: all 0.3s ease;
}

.custom-select>>>.v-input__slot:hover {
    border-color: rgba(13, 44, 109, 0.2) !important;
    background: rgba(13, 44, 109, 0.03);
}

.custom-select.v-input--is-focused>>>.v-input__slot {
    border-color: #0d2c6d !important;
    box-shadow: 0 0 0 1px rgba(13, 44, 109, 0.2) !important;
    background: rgba(13, 44, 109, 0.02);
}

/* Dialog Actions */
.dialog-actions {
    padding: 1rem 1.5rem;
    display: flex;
    justify-content: center;
    gap: 1rem;
}

/* Buttons */
.save-button {
    background: linear-gradient(135deg, #0d2c6d 0%, #1a4ba5 100%) !important;
    color: white !important;
    padding: 0 1.5rem !important;
    height: 40px;
    text-transform: none;
    letter-spacing: 0.5px;
    font-size: 0.9375rem;
    font-weight: 500;
    border-radius: 8px;
    transition: all 0.3s ease;
}

.save-button:hover:not(:disabled) {
    opacity: 0.95;
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(13, 44, 109, 0.2) !important;
}

.save-button:disabled {
    opacity: 0.7;
    background: #94a3b8 !important;
}

.cancel-button {
    color: #64748b !important;
    padding: 0 1.5rem !important;
    height: 40px;
    text-transform: none;
    letter-spacing: 0.5px;
    font-size: 0.9375rem;
    border-radius: 8px;
    transition: all 0.3s ease;
}

.cancel-button:hover {
    background: rgba(13, 44, 109, 0.05) !important;
    color: #0d2c6d !important;
}

/* RTL Support */
.v-application {
    direction: rtl;
}

.v-select>>>.v-input__append-inner {
    margin-left: 0;
    margin-right: 8px;
}

.v-select>>>.v-input__prepend-inner {
    margin-right: 0;
    margin-left: 8px;
}
</style>