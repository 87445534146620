<template>
  <v-container style="margin-top: 70px; margin-bottom: 70px;" fluid class="operation-settings pa-0">
    <v-row class="justify-center">
      <v-col cols="12" lg="10">
        <v-card class="settings-card">
          <v-form @submit.prevent="submitHandler" ref="form">
            <!-- Header -->
            <div class="settings-header">
              <div class="d-flex align-center">
                <v-btn @click="openMode = false" icon class="back-button ml-4">
                  <v-icon>mdi-arrow-right</v-icon>
                </v-btn>
                <h1 class="text-h4 font-weight-bold primary-text mb-0">הגדרות פעולה</h1>
              </div>
            </div>

            <v-divider></v-divider>

            <!-- Content -->
            <v-container class="py-6">
              <v-row>
                <!-- Basic Info -->
                <v-col cols="12" sm="6">
                  <v-text-field v-model="editedOperation.details.name" outlined label="שם הפעולה" maxlength="32"
                    class="custom-input" :rules="[v => !!v || 'שדה חובה']" required></v-text-field>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-text-field v-model="editedOperation.details.description" outlined label="תאור הפעולה"
                    class="custom-input"></v-text-field>
                </v-col>

                <!-- Method & Condition -->
                <v-col cols="12" sm="6">
                  <v-select :items="requestMethod" v-model="editedOperation.action.method" outlined label="שיטת שליחה"
                    class="custom-input"></v-select>
                </v-col>

                <v-col cols="12" sm="6">
                  <div class="condition-card">
                    <div class="condition-header">
                      <v-icon color="#0d2c6d" small class="mr-2">mdi-code-brackets</v-icon>
                      <span class="text-subtitle-2 font-weight-medium">תנאי הפעלה</span>
                    </div>
                    <div class="d-flex align-center condition-content" style="direction: ltr;">
                      <span class="condition-bracket">if(</span>
                      <v-text-field v-model="editedOperation.action.condition[0]" hide-details dense label="value 1"
                        class="condition-input"></v-text-field>
                      <v-select :items="operatorsList" v-model="editedOperation.action.condition[1]" hide-details dense
                        class="condition-operator mx-2"></v-select>
                      <v-text-field v-model="editedOperation.action.condition[2]" hide-details dense label="value 2"
                        class="condition-input"></v-text-field>
                      <span class="condition-bracket">)</span>
                    </div>
                  </div>
                </v-col>

                <!-- Trigger Field & URL -->
                <v-col cols="3" v-if="editedOperation.details.triger === 'when_row_updated'">
                  <v-select v-model="editedOperation.action.trigerField" :items="fields" item-text="hebrew_name"
                    item-value="name" outlined label="שדה להפעלה" class="custom-input" :rules="[v => !!v || 'שדה חובה']"
                    required></v-select>
                </v-col>

                <v-col :cols="editedOperation.details.triger === 'when_row_updated' ? 9 : 12">
                  <v-text-field v-model="editedOperation.action.url" outlined label="URL" class="custom-input"
                    :rules="[v => !!v || 'שדה חובה']" required style="direction: ltr;"></v-text-field>
                </v-col>

                <!-- Headers & Body -->
                <v-col cols="12">
                  <v-textarea v-model="editedOperation.action.headers" outlined label="Headers" rows="3"
                    class="custom-input" style="direction: ltr;"></v-textarea>
                </v-col>

                <v-col cols="12">
                  <v-textarea v-model="editedOperation.action.body" :rules="[jsonRule]" outlined label="Body" rows="5"
                    class="custom-input" style="direction: ltr;"></v-textarea>
                </v-col>

                <!-- Switches -->
                <v-col cols="12">
                  <div class="switches-container">
                    <div class="switch-item d-flex align-center">
                      <v-switch v-model="socketEnabled" :color="$store.state.colorPrimary" hide-details class="ma-0"
                        inset label="הפעל בדיקה"></v-switch>
                      <v-icon v-if="socketEnabled" :color="$store.state.colorPrimary" small class="mr-4 view-icon"
                        @click.stop="socketDataDialog = true">
                        mdi-eye-outline
                      </v-icon>
                    </div>
                    <div class="switch-item">
                      <v-switch v-model="editedOperation.action.send_reports" :color="$store.state.colorPrimary"
                        hide-details inset label="שלח שגיאה למייל"></v-switch>
                    </div>

                    <div class="switch-item">
                      <v-switch v-model="editedOperation.action.mode" :color="$store.state.colorPrimary" hide-details
                        inset label="מופעל/מושבת"></v-switch>
                    </div>
                  </div>
                </v-col>

                <!-- Actions -->
                <v-col cols="12" class="d-flex justify-start pa-4">
                  <v-btn @click="saveChanges()" class="save-button ml-5" :loading="progressShow"
                    :disabled="progressShow">
                    <v-icon left>mdi-content-save</v-icon>
                    שמור שינויים
                  </v-btn>
                  <v-btn @click="deleteOperation()" class="delete-button" :loading="progressShow"
                    :disabled="progressShow">
                    <v-icon left>mdi-delete</v-icon>
                    מחק פעולה
                  </v-btn>
                </v-col>

                <v-col cols="12" v-if="progressShow" class="d-flex justify-center">
                  <v-progress-circular indeterminate :color="$store.state.colorPrimary"></v-progress-circular>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card>
      </v-col>
    </v-row>

    <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>
    <SocketDataDialog v-model="socketDataDialog" v-if="socketDataDialog" :data="socketData"></SocketDataDialog>
  </v-container>
</template>

<script>
import ApiServices from '@/services/api.service.js'
import SnackBar from '@/components/widgets/snackBar.vue'
import SocketDataDialog from '@/components/user/crmIntigration/SocketResponseDialog.vue'
import Auth from '@/services/auth.service.js'
import { io } from 'socket.io-client';

export default {
  components: {
    SnackBar,
    SocketDataDialog
  },
  props: {
    operationToEdit: Object,
    tableName: Object,
    trigers: Array,
    value: { type: Boolean, default: false },
  },
  data: () => ({
    fields: [],
    tables: [],
    progressShow: false,
    snackbar: false,
    snackbarColorBt: "green",
    snacbarText: "",
    editedOperation: {},
    requestMethod: ["GET", "POST", "PUT", "DELETE"],
    operatorsList: ["===", "!==", "<", ">", "<=", ">="],
    socket: null,
    socketEnabled: false,
    socketData: [],
    socketDataDialog: false
  }),
  computed: {
    openMode: {
      get() {
        return this.value
      },
      set(v) {
        return this.$emit('input', v)
      }
    },
    jsonRule() {
      return (value) => {
        if (this.editedOperation.action.method !== 'GET') {
          try {
            if (!this.editedOperation.action.headers || !this.editedOperation.action.headers.includes("application/json")) {
              return true
            }
            JSON.parse(value);
            return true; // Valid JSON
          } catch (e) {
            return 'The body must be valid JSON.'; // Invalid JSON
          }
        }
        return true; // If method is GET, pass validation regardless of JSON validity
      };
    }
  },
  methods: {
    async saveChanges() {
      if (this.$refs.form.validate()) {
        try {
          if (this.editedOperation.action.mode === true) {
            this.editedOperation.action.mode = "on"
          } else {
            this.editedOperation.action.mode = "off"
          }
          if (this.editedOperation.action.send_reports === true) {
            this.editedOperation.action.send_reports = "yes"
          } else {
            this.editedOperation.action.send_reports = "no"
          }
          let token = localStorage.getItem("token");
          const tableJSON = JSON.stringify(this.editedOperation);

          let api = process.env.VUE_APP_BASE_URL + "/user/update_crm_operation";
          const res = await fetch(api, ApiServices.requestOptions("PUT", tableJSON, token));
          const jsonObject = await res.json();
          if (res.status >= 400) {
            this.showSnackBar("שגיאה בעדכון הפעולה" + jsonObject.message, "red");
          } else if (res.status === 200) {
            this.$emit("operationUpdated", this.editedOperation, "הפעולה עודכנה בהצלחה!", "green");
          }
        } catch (error) {
          this.showSnackBar("Error update operation: " + error, "red");
        }
      }
    },
    async deleteOperation() {
      try {
        let token = localStorage.getItem("token");
        const tableJSON = JSON.stringify(this.editedOperation);

        let api = process.env.VUE_APP_BASE_URL + "/user/delete_crm_operation";
        const res = await fetch(api, ApiServices.requestOptions("DELETE", tableJSON, token));
        const jsonObject = await res.json();
        if (res.status >= 400) {
          this.showSnackBar("שגיאה במחיקת הפעולה" + jsonObject.message, "red");
        } else if (res.status === 200) {
          this.$emit("operationUpdated", this.editedOperation, "הפעולה נמחקה בהצלחה!", "green");
        }
      } catch (error) {
        this.showSnackBar("Error update operation: " + error, "red");
      }
    },
    // Start the socket connection with parameters
    startSocket() {
      if (this.socket) {
        console.log('Socket already connected');
        return;
      }
      this.socketDataDialog = true;
      this.socket = io(process.env.VUE_APP_BASE_URL); // Replace with your server URL

      this.socket.emit('joinRoom', {
        token: localStorage.getItem("token"),
        tableName: this.tableName.name,
        taskId: this.operationToEdit.details.id,
      });

      // Listen for data from the server
      this.socket.on('recieveMessage', (data) => {
        console.log('Data received from server:', data);
        this.socketData.push(data); // Update the data property
      });

      this.socket.on('connect', () => {
        console.log('Socket connected');
      });

      this.socket.on('disconnect', () => {
        console.log('Socket disconnected');
      });

      this.socket.on('error', (error) => {
        console.error('Socket error:', error);
      });
    },

    // Close the socket connection
    closeSocket() {
      if (this.socket) {
        this.socket.disconnect();
        this.socket = null;
        this.socketDataDialog = false;
        console.log('Socket connection closed');
      }
    },
    showSnackBar(message, color) {
      this.snacbarText = message;
      this.snackbarColorBt = color;
      this.snackbar = true
    },
  },
  watch: {
    socketEnabled(newValue) {
      console.log(newValue);
      if (newValue) {
        this.startSocket();
      } else {
        this.closeSocket();
      }
    }
  },
  beforeDestroy() {
    // Ensure socket is closed when the component is destroyed
    if (this.socket) {
      this.socket.disconnect();
    }
  },
  mounted() {

    let auth = Auth.checkAuth();
    if (!auth) {
      this.$router.replace({ name: "login" })
    }
    // deep clone
    this.editedOperation = JSON.parse(JSON.stringify(this.operationToEdit));
    if (this.editedOperation.action.mode === "on" || this.editedOperation.action.mode === true) {
      this.editedOperation.action.mode = true
    } else {
      this.editedOperation.action.mode = false
    }
    if (this.editedOperation.action.send_reports === "yes" || this.editedOperation.action.send_reports === true) {
      this.editedOperation.action.send_reports = true
    } else {
      this.editedOperation.action.send_reports = false
    }
    this.fields = this.$store.state.fields;
    this.fields.unshift({ hebrew_name: "הכל", name: "הכל" });
    // this.tables = this.$store.state.tables;
  },

}
</script>

.divider {
border-top: 0.5px solid #cfcccc;
/* Set the border style, width, and color */
margin-top: 10px;
/* Optional: Add margin to adjust spacing */
margin-bottom: 10px;
/* Optional: Add margin to adjust spacing */
}

.divider-col {
border-left: 1px solid #ccc !important;
/* Adjust the color and thickness as needed */
padding-left: 10px;
/* Add padding to create space between the border and content */
margin-left: 10px;
/* Adjust the margin to align the divider with the cards above */
}


/* Custom Input Styling */
.custom-input {
transition: all 0.3s ease;
}

.custom-input >>> .v-input__slot {
border: 1px solid rgba(13, 44, 109, 0.1) !important;
transition: all 0.3s ease;
}

.custom-input >>> .v-input__slot:hover {
border-color: rgba(13, 44, 109, 0.2) !important;
background: rgba(13, 44, 109, 0.03);
}

.custom-input.v-input--is-focused >>> .v-input__slot {
border-color: #0d2c6d !important;
box-shadow: 0 0 0 1px rgba(13, 44, 109, 0.2) !important;
background: rgba(13, 44, 109, 0.02);
}

<style scoped>
.operation-settings {
  background: linear-gradient(135deg, #f8fafc 0%, #f0f7ff 100%);
  min-height: 100vh;
  padding-top: 70px;
  padding-bottom: 90px;
}

.settings-card {
  border-radius: 16px;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.98);
  backdrop-filter: blur(10px);
  box-shadow: 0 4px 24px rgba(13, 44, 109, 0.08);
}

.settings-header {
  padding: 1.5rem;
  background: linear-gradient(to bottom, rgba(13, 44, 109, 0.02), transparent);
}

.primary-text {
  background: linear-gradient(135deg, #0d2c6d 0%, #1a4ba5 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.back-button {
  background: rgba(13, 44, 109, 0.05);
  transition: all 0.3s ease;
}

.back-button:hover {
  background: rgba(13, 44, 109, 0.1);
  transform: translateX(2px);
}

.back-button .v-icon {
  color: #0d2c6d;
}

/* Custom Input Styling */
.custom-input {
  transition: all 0.3s ease;
}

.custom-input>>>.v-input__slot {
  border: 1px solid rgba(13, 44, 109, 0.1) !important;
  transition: all 0.3s ease;
}

.custom-input>>>.v-input__slot:hover {
  border-color: rgba(13, 44, 109, 0.2) !important;
  background: rgba(13, 44, 109, 0.03);
}

.custom-input.v-input--is-focused>>>.v-input__slot {
  border-color: #0d2c6d !important;
  box-shadow: 0 0 0 1px rgba(13, 44, 109, 0.2) !important;
  background: rgba(13, 44, 109, 0.02);
}

/* Condition Card */
.condition-card {
  border: 1px solid rgba(13, 44, 109, 0.1);
  border-radius: 8px;
  background: rgba(13, 44, 109, 0.02);
  padding: 1rem;
}

.condition-header {
  display: flex;
  align-items: center;
  margin-bottom: 0.75rem;
  color: #0d2c6d;
}

.condition-content {
  background: white;
  border-radius: 6px;
  padding: 0.5rem;
}

.condition-bracket {
  font-family: monospace;
  font-weight: 600;
  color: #0d2c6d;
  margin: 0 0.5rem;
}

.condition-input {
  max-width: 200px;
}

.condition-operator {
  max-width: 80px;
}

/* Code Font */
.code-font>>>textarea {
  font-family: 'Courier New', monospace;
  font-size: 0.875rem;
}

/* Switches Container */
.switches-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem;
  background: rgba(13, 44, 109, 0.02);
  border-radius: 8px;
}

.switch-item {
  position: relative;
  /* display: flex;
  align-items: center; */
}

/* Buttons */
.save-button {
  background: linear-gradient(135deg, #0d2c6d 0%, #1a4ba5 100%) !important;
  color: white !important;
  padding: 0 1.5rem !important;
  height: 40px;
  text-transform: none;
  letter-spacing: 0.5px;
  font-size: 0.9375rem;
  font-weight: 500;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.save-button:hover:not(:disabled) {
  opacity: 0.95;
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(13, 44, 109, 0.2) !important;
}

.delete-button {
  background: rgba(239, 68, 68, 0.1) !important;
  color: #ef4444 !important;
  padding: 0 1.5rem !important;
  height: 40px;
  text-transform: none;
  letter-spacing: 0.5px;
  font-size: 0.9375rem;
  font-weight: 500;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.delete-button:hover:not(:disabled) {
  background: rgba(239, 68, 68, 0.15) !important;
  transform: translateY(-1px);
}

/* RTL Support */
.v-application {
  direction: rtl;
}

.v-text-field>>>.v-input__prepend-inner {
  margin-right: 0;
  margin-left: 8px;
}

.v-text-field>>>.v-input__append-inner {
  margin-left: 0;
  margin-right: 8px;
}

.view-icon {
  cursor: pointer;
  transition: all 0.3s ease;
}

.view-icon:hover {
  opacity: 0.8;
  transform: scale(1.1);
}
</style>