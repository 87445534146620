<template>
  <v-container class="users-list-container">
    <!-- Header Section -->
    <v-card class="header-card mb-6" elevation="0">
      <v-row align="center" class="px-4 py-3">
        <v-col cols="12" sm="6" md="4">
          <h1 class="text-h4 font-weight-bold " style="color: #0d2c6d;">ניהול משתמשים</h1>
          <span class="subtitle-1 grey--text">סה"כ משתמשים: {{ users.length }}</span>
        </v-col>
        <v-col cols="12" sm="6" md="8" class="d-flex justify-end align-center flex-wrap">
          <v-btn class="ma-2" dark :color="$store.state.colorPrimary" @click="openCreateDIalog" elevation="2">
            <v-icon left>mdi-account-plus</v-icon>
            משתמש חדש
          </v-btn>
          <v-btn class="ma-2" outlined :color="$store.state.colorPrimary" @click="downloadExcel">
            <v-icon left>mdi-microsoft-excel</v-icon>
            ייצוא לאקסל
          </v-btn>
        </v-col>
      </v-row>
    </v-card>

    <!-- Main Content -->
    <v-card class="content-card">
      <!-- Search and Filters -->
      <v-card-text class="pb-0">
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field v-model="search" prepend-inner-icon="mdi-magnify" label="חיפוש משתמש" outlined dense clearable
              hide-details class="search-field"></v-text-field>
          </v-col>
          <v-col cols="12" md="8" class="d-flex justify-end">
            <v-btn-toggle v-model="filterRole" class="elevation-0" mandatory>
              <v-btn text value="all">
                הכל
              </v-btn>
              <v-btn text value="admin">
                מנהלים
              </v-btn>
              <v-btn text value="agent">
                סוכנים
              </v-btn>
              <v-btn text value="user">
                משתמשים
              </v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>
      </v-card-text>

      <!-- Data Table -->
      <v-data-table :headers="headers" :items="filteredUsers" @click:row="switchToUser" :search="search"
        style="cursor: pointer;" :loading="progressShow" :footer-props="{
          'items-per-page-options': [20, 50, 100],
          'items-per-page-text': 'שורות בעמוד:', showFirstLastPage: true,
          firstIcon: 'mdi-chevron-double-left',
          lastIcon: 'mdi-chevron-double-right',
          prevIcon: 'mdi-chevron-left',
          nextIcon: 'mdi-chevron-right'
        }" class="elevation-0">
        <!-- Status Column -->
        <!-- <template v-slot:item.status="{ item }">
          <v-chip small :color="getStatusColor(item.status)" text-color="white">
            {{ item.status ? 'פעיל' : 'מושבת' }}
          </v-chip>
        </template> -->

        <!-- Status Column -->
        <template v-slot:item.status="{ item }">
          <v-switch :disabled="item.role === 'superAdmin'" v-model="item.status" :color="getStatusColor(item.status)"
            @change="enableOrdisable(item.status, item.id)" hide-details>
            <template v-slot:label>
              <!-- <v-chip small :color="getStatusColor(item.status)" text-color="white">
                {{ item.status ? 'פעיל' : 'מושבת' }}
              </v-chip> -->
            </template>
          </v-switch>
        </template>

        <!-- Role Column -->
        <template v-slot:item.role="{ item }">
          <v-chip small :color="getRoleColor(item.role)" outlined>
            {{ getRoleText(item.role) }}
          </v-chip>
        </template>

        <!-- Permission Column -->
        <template v-slot:item.permission="{ item }">
          {{ getPermissionText(item.permission) }}
        </template>

        <!-- Actions Column -->
        <template v-slot:item.actions="{ item }">
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list dense>
              <v-list-item @click="getFullDetails(item)">
                <v-list-item-icon>
                  <v-icon color="primary">mdi-account-details</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>פרטים נוספים</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item @click="openEditDialog(item)">
                <v-list-item-icon>
                  <v-icon color="primary">mdi-pencil</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>עריכה</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item v-if="item.role === 'user' || item.role === 'agent'" @click="switchToUser(item)">
                <v-list-item-icon>
                  <v-icon color="primary">mdi-login</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>כניסה למערכת</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>

        <!-- Loading State -->
        <template v-slot:loading>
          <v-skeleton-loader v-for="n in 5" :key="n" type="list-item-avatar-three-line"
            class="my-2"></v-skeleton-loader>
        </template>

        <!-- No Data State -->
        <template v-slot:no-data>
          <v-card-text class="text-center pa-8">
            <v-icon size="64" color="grey lighten-1">mdi-account-off-outline</v-icon>
            <h3 class="mt-4 mb-2">לא נמצאו משתמשים</h3>
            <v-btn color="primary" @click="getUsersDetails" class="mt-2">
              רענן נתונים
            </v-btn>
          </v-card-text>
        </template>
      </v-data-table>
    </v-card>

    <!-- Dialogs -->
    <AddEditDialog v-model="addEditDialog" v-if="addEditDialog" :formTitle="formTitle" :itemToEdit="editedItem" :usersList="users"
      :databases="databases" @userAdded="newUserSaved" @userEdited="editedUserSaved" />

    <Alert-dialog v-model="alertDialog" :formTitle="formTitle" :itemIndex="itemIndex" :item="itemToDelete"
      @userDeleted="userDeleted" />

    <!-- Snackbar -->
    <v-snackbar v-model="snackbar" :color="snackbarColorBt" :timeout="3000" bottom right elevation="24">
      {{ snacbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">
          סגור
        </v-btn>
      </template>
    </v-snackbar>

    <!-- Loading Overlay -->
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64" :color="$store.state.colorPrimary"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>


<script>
import ApiServices from '@/services/api.service'
import SnackBar from '@/components/widgets/snackBar.vue'
import TableToExcel from "@linways/table-to-excel";
import AddEditDialog from '@/components/admin/dialogs/AddEditUserDialog'
import AlertDialog from '@/components/admin/dialogs/AlertDialog'
import Auth from '@/services/auth.service.js'
import utils from '@/util/utils'
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

export default {
  components: {
    AddEditDialog,
    AlertDialog,
    SnackBar
  },
  data: () => ({
    headers: [
      { text: '#', value: "index", sortable: false },
      { text: 'שם', align: 'start', value: 'name', },
      { text: 'טלפון', value: 'phone' },
      { text: 'אימייל', value: 'email' },
      { text: 'קבוצה', value: 'role' },
      { text: 'הרשאות', value: 'permission' },
      { text: 'סטטוס', value: 'status' },
      { text: 'מסד נתונים', value: 'user_data_name' },
      ...(Auth.getUserRole() === "admin" || Auth.getUserRole() === "superAdmin" ? [{ text: 'משוייך לסוכן', value: 'agent.name' }] : []), // Conditional inclusion
      { text: 'נוצר ע"י', value: 'createdBy.name' },
      { text: "מנהל תמיכה", value: 'supportUser.name'},
      { text: 'פעילות אחרונה', value: 'last_visit' },
      { text: 'פעולות', value: 'actions', sortable: false },

    ],
    filterRole: 'all',
    alertDialog: false,
    addEditDialog: false,
    formTitle: "",
    users: [],
    databases: [],
    progressShow: false,
    overlay: false,
    snackbar: false,
    snackbarColorBt: "green",
    snacbarText: "",
    editedItem: {},
    itemIndex: 0,
    itemToDelete: {},
    userName: "",
    email: "",
    search: "",
  }),

  methods: {

    async getUsersDetails() {
      try {
        this.users = [];
        this.progressShow = true;
        let token = localStorage.getItem("token");

        let api = process.env.VUE_APP_BASE_URL + "/admin/get_users_list";
        const res = await fetch(api, ApiServices.requestOptions("POST", "", token));
        const jsonObject = await res.json();
        if (res.status >= 400) {
          this.showSnackBar("שגיאה בקבלת נתונים", "red");
          this.progressShow = false;
        } else if (res.status === 200) {
          this.users = jsonObject;
          this.dateFormatted();
          this.progressShow = false;
        }
      } catch (error) {
        this.progressShow = false;
        this.showSnackBar("Error get dates list: " + error, "red");
        let d = utils.getCurrentDate()
      }
    },
    dateFormatted() {
      this.users = this.users.map((user) => {
        return {
          ...user,
          created_at: utils.getCurrentDate(user.created_at),
          modified_at: utils.getCurrentDate(user.modified_at)
        }
      });
    },
    async getDatabases() {
      try {
        this.databases = [];
        let token = localStorage.getItem("token");

        let api = process.env.VUE_APP_BASE_URL + "/admin/get_databases_list";
        const res = await fetch(api, ApiServices.requestOptions("POST", "", token));
        const jsonObject = await res.json();
        if (res.status >= 400) {
          this.showSnackBar("שגיאה בקבלת שמות מסדי הנתונים", "red");
        } else if (res.status === 200) {
          this.databases = jsonObject;
        }
      } catch (error) {
        this.showSnackBar("Error get databases names list: " + error, "red");
      }
    },
    showSnackBar(message, color) {
      this.snacbarText = message;
      this.snackbarColorBt = color;
      this.snackbar = true
    },
    async enableOrdisable(statusChanged, id) {
      console.log(statusChanged);
      console.log(id);

      let message = statusChanged ? "הסוכן הופעל בהצלחה" : "הסוכן הושהה בהצלחה";
      let editUserstatus = JSON.stringify({ id: id, status: statusChanged })
      let token = localStorage.getItem("token");
      this.overlay = true;

      try {
        let api = process.env.VUE_APP_BASE_URL + "/admin/change_user_status";
        const res = await fetch(api, ApiServices.requestOptions("PUT", editUserstatus, token));
        this.overlay = false;
        if (res.status >= 400) {
          const jsonObject = await res.json();
          this.showSnackBar("שגיאה בשינוי סטטוס " + jsonObject.message, "red");
        } else if (res.status === 200) {
          this.showSnackBar(message, "green");
        }
      } catch (error) {
        this.overlay = false;
        this.showSnackBar("Error change user status: " + error, "red");
      }
    },

    downloadExcel() {

      const englishHeaders = [
        { text: 'id', align: 'start', value: 'code_verify', },
        { text: 'name', align: 'start', value: 'code_verify', },
        { text: 'phone', value: 'status' },
        { text: 'email', value: 'seller_owner' },
        { text: 'role', value: 'mobile_activated' },
        { text: 'permission', value: 'created_at' },
        { text: 'status', value: 'modified_at' },
        { text: 'user_data_name', value: 'user_data_name' },
        { text: 'last_visit', value: 'last_visit' },
        { text: 'status', value: 'modified_at' },
        { text: 'api_token', value: 'api_token' },
        { text: 'special_page', value: 'special_page' },
        { text: 'created_at', value: 'created_at' },
        { text: 'modified_at', value: 'modified_at' },

      ];

      const usersData = this.users.map(user => {
        // Create an object with all user data
        return {
          id: user.id,
          name: user.name,
          phone: user.phone,
          email: user.email,
          role: user.role,
          permission: user.permission,
          status: user.status,
          user_data_name: user.user_data_name,
          last_visit: user.last_visit,
          api_token: user.api_token,
          special_page: user.special_page,
          created_at: user.created_at,
          modified_at: user.modified_at,
        };
      });

      // Create a new workbook
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Sheet 1');

      // Add the headers to the worksheet
      const headerRow = worksheet.addRow(englishHeaders.map(header => header.text));

      // Set the style of the header row
      headerRow.font = { bold: true };

      // Add the user data to the worksheet
      usersData.forEach(user => {
        const dataRow = worksheet.addRow(Object.values(user));
      });

      // Auto-fit the column widths
      worksheet.columns.forEach(column => {
        column.width = 15;
      });

      // Generate a unique filename
      const timestamp = new Date().toISOString().replace(/:/g, '-');
      const filename = `usersList_${timestamp}.xlsx`;

      // Save the workbook as an Excel file
      workbook.xlsx.writeBuffer()
        .then(buffer => {
          const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          saveAs.saveAs(blob, filename);
        })
        .catch(error => {
          console.error('Error exporting data:', error);
        });

    },
    async enableOrdisable(statusChanged, id) {

      let message = statusChanged ? "הסוכן הופעל בהצלחה" : "הסוכן הושהה בהצלחה";
      let editUserstatus = JSON.stringify({ id: id, status: statusChanged })
      let token = localStorage.getItem("token");
      this.overlay = true;

      try {
        let api = process.env.VUE_APP_BASE_URL + "/admin/change_user_status";
        const res = await fetch(api, ApiServices.requestOptions("PUT", editUserstatus, token));
        this.overlay = false;
        if (res.status >= 400) {
          const jsonObject = await res.json();
          this.showSnackBar("שגיאה בשינוי סטטוס " + jsonObject.message, "red");
        } else if (res.status === 200) {
          this.showSnackBar(message, "green");
        }
      } catch (error) {
        this.overlay = false;
        this.showSnackBar("Error change user status: " + error, "red");
      }
    },
    refreshPage() {
      location.reload();
    },
    openEditDialog(item, index) {
      this.formTitle = "ערוך משתמש"
      this.editedItem = item
      this.editedItem.itemIndex = index;
      this.addEditDialog = true
    },

    openDeleteDialog(item, index) {
      this.itemIndex = index;
      this.itemToDelete = item
      this.formTitle = "האם אתה בטוח שברצונך למחוק את המשתמש?"
      this.alertDialog = true
    },
    openCreateDIalog() {
      this.formTitle = "הוסף משתמש חדש"
      this.editedItem = {}
      this.addEditDialog = true
    },
    newUserSaved(item, text, color) {

      this.showSnackBar(text, color)
      if (item === "") {
        return
      }
      this.users.push(item);

    },
    editedUserSaved(item, text, color) {
      this.showSnackBar(text, color)
      if (item === "") {
        return
      }
      const index = this.users.findIndex(item1 => item1.id === item.id);
      let g = Object.assign(this.users[index], item);

    },
    userDeleted(itemIndex, text, color) {
      if (itemIndex === "") {
        this.showSnackBar(text, color)
        return
      }
      this.users.splice(itemIndex, 1);
      this.showSnackBar(text, color)
    },
    getFullDetails(user, index) {
      this.$router.push({ name: "userDetails", params: { user: user } })
    },
    getUserDetails() {
      this.userName = Auth.getUserName();
      this.email = Auth.getUserEmail();
    },
    logOut() {
      localStorage.removeItem("token");
      localStorage.removeItem("token2");
      this.$router.replace({ name: "login" })
    },
    async switchToUser(item) {
      if (item.role === 'user' || item.role === 'agent') {
        this.overlay = true;
        let token = localStorage.getItem("token");
        const role = Auth.getUserRole();
        if (role === "admin" || role === "superAdmin") {
          localStorage.setItem('tokenAdmin', token);
        } else if (role === "agent") {
          localStorage.setItem('tokenAgent', token);
        } else { return }

        let MyJSON = JSON.stringify({ id: item.id });
        let api = process.env.VUE_APP_BASE_URL + "/admin/swich_to_user"

        try {
          const response = await fetch(api, ApiServices.requestOptions("POST", MyJSON, token));
          const jsonObject = await response.json();
          if (response.status >= 400) {
            this.showSnackBar("שגיאת התחברות: " + jsonObject.message, "red");
            this.overlay = false;
          } else if (response.status === 200) {
            localStorage.setItem('token', jsonObject.token);
            if (item.role === "agent") {
              window.location.reload();
            } else {
              this.$router.replace({ name: "user" })
            }
            this.overlay = false;
          }
        } catch (error) {
          this.overlay = false;
          this.showSnackBar("Error Login: " + error, "red");
        }
      }
    },
    getStatusColor(status) {
      return status ? 'success' : 'grey'
    },

    getRoleColor(role) {
      switch (role) {
        case 'superAdmin': return 'deep-purple'
        case 'admin': return 'primary'
        case 'agent': return 'warning'
        default: return 'grey'
      }
    },
    getRoleText(role) {
      switch (role) {
        case 'superAdmin': return 'מנהל ראשי'
        case 'admin': return 'מנהל'
        case 'agent': return 'סוכן'
        default: return 'משתמש'
      }
    },
    getPermissionText(role) {
      switch (role) {
        case 'edit': return 'הכל'
        case 'basicEdit': return 'עריכה בסיסית'
        case 'view': return 'צפייה'
        default: return 'משתמש'
      }
    }
  },
  computed: {
    filteredUsers() {
      if (this.filterRole === 'all') return this.users;
      if (this.filterRole === 'admin') {
        return this.users.filter(user => user.role === "admin" || user.role === "superAdmin")
      }
      return this.users.filter(user => user.role === this.filterRole)
    }
  },
  mounted() {
    this.getUserDetails();
    this.getUsersDetails();
    this.getDatabases();
  },
}
</script>
<style scoped>
.users-list-container {
  background-color: #f5f7fa;
  min-height: 100vh;
  padding-top: 2rem;
  margin-top: 60px;
}

.header-card {
  background: white;
  border-radius: 12px;
}

.content-card {
  background: white;
  border-radius: 12px;
  overflow: hidden;
}

/* RTL Specific Styles */
.v-application {
  direction: rtl;
}

.v-data-table {
  direction: rtl;
}

.v-data-table>>>th {
  font-weight: 600 !important;
}

.v-data-table>>>td {
  font-size: 0.875rem !important;
}

/* Responsive Design */
@media (max-width: 600px) {
  .users-list-container {
    padding-top: 1rem;
  }

  .header-card {
    margin-bottom: 1rem !important;
  }

  .v-data-table>>>td {
    font-size: 0.75rem !important;
  }
}

/* Animations */
.v-data-table>>>tbody tr {
  transition: background-color 0.2s ease;
}

.v-data-table>>>tbody tr:hover {
  background-color: #f5f7fa !important;
}

/* Custom Scrollbar */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.v-switch {
  margin: 0;
  padding: 0;
  display: inline-flex;
  align-items: center;
}
</style>
