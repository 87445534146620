<template>
  <v-dialog v-model="openMode" max-width="900px" persistent>
    <v-card class="add-edit-dialog">
      <!-- Dialog Header -->
      <v-card-title class="dialog-header">
        <v-row no-gutters align="center">
          <v-col>
            <h2 class="text-h5 font-weight-bold " style="color: #0d2c6d;">{{ formTitle }}</h2>
          </v-col>
          <v-col cols="auto">
            <v-btn icon @click="openMode = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>

      <v-divider></v-divider>

      <!-- Dialog Content -->
      <v-card-text class="pt-6">
        <v-form @submit.prevent="submitHandler" ref="form" v-model="isFormValid">
          <v-container>
            <!-- Basic Information Section -->
            <v-row>
              <v-col cols="12">
                <h3 class="subtitle-1 font-weight-bold mb-3">מידע בסיסי</h3>
              </v-col>

              <v-col cols="12" sm="6" md="4">
                <v-text-field v-model="itemEdited.name" label="שם" outlined dense :rules="[v => !!v || 'שדה חובה']"
                  required prepend-inner-icon="mdi-account"></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="4">
                <v-text-field v-model="itemEdited.email" label="אימייל" outlined dense
                  :rules="[v => !!v || 'שדה חובה', v => /.+@.+\..+/.test(v) || 'אימייל לא תקין']" required
                  prepend-inner-icon="mdi-email"></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="4">
                <v-text-field v-model="itemEdited.phone" label="טלפון" outlined dense prepend-inner-icon="mdi-phone"
                  placeholder="050-123-4567"></v-text-field>
              </v-col>
            </v-row>

            <!-- Security Section -->
            <v-row class="mt-4">
              <v-col cols="12">
                <h3 class="subtitle-1 font-weight-bold mb-3">אבטחה והרשאות</h3>
              </v-col>

              <v-col cols="12" sm="6" md="4">
                <v-text-field v-model="itemEdited.password" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPassword ? 'text' : 'password'" label="סיסמה" outlined dense :rules="passwordRules"
                  required prepend-inner-icon="mdi-lock" @click:append="showPassword = !showPassword"></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="4">
                <v-select v-model="itemEdited.role" :items="roleList" label="תפקיד" outlined dense
                  :item-text="'hebrewName'" :item-value="'name'" :rules="[v => !!v || 'שדה חובה']" required
                  prepend-inner-icon="mdi-shield-account" :disabled="isRoleDisabled"></v-select>
              </v-col>

              <v-col cols="12" sm="6" md="4">
                <v-select v-model="itemEdited.permission" :items="permissionList" label="הרשאות" outlined dense
                  :item-text="'hebrewName'" :item-value="'name'" :rules="[v => !!v || 'שדה חובה']" required
                  prepend-inner-icon="mdi-key" :disabled="itemEdited.role === 'superAdmin'"></v-select>
              </v-col>
            </v-row>

            <!-- Additional Settings Section -->
            <v-row class="mt-4">
              <v-col cols="12">
                <h3 class="subtitle-1 font-weight-bold mb-3">הגדרות נוספות</h3>
              </v-col>

              <v-col cols="12" sm="6" md="4" v-if="showDatabaseField">
                <v-text-field v-model="itemEdited.user_data_name" :items="databases" label="מסד נתונים" outlined dense
                  :rules="[v => !!v || 'שדה חובה']" required prepend-inner-icon="mdi-database"></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="4" v-if="showDatabaseSelectField">
                <v-select v-model="itemEdited.user_data_name" :items="databases" label="מסד נתונים" outlined dense
                  :rules="[v => !!v || 'שדה חובה']" required prepend-inner-icon="mdi-database"></v-select>
              </v-col>

              <v-col cols="12" sm="6" md="4" v-if="itemEdited.role === 'user'">
                <v-text-field v-model="itemEdited.external_system_number" label="מספר מערכת תוכן/מרכזייה" outlined dense
                  prepend-inner-icon="mdi-identifier"></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="4">
                <v-text-field v-model="itemEdited.special_page" label="דף נוסף" outlined dense
                  prepend-inner-icon="mdi-file"></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="4" v-if="itemEdited.role === 'user'">
                <v-select v-model="itemEdited.support_user" :items="adminsSupport" label="מנהל תמיכה" outlined dense
                  :item-text="'name'" :item-value="'id'" :rules="[v => !!v || 'שדה חובה']" required
                  prepend-inner-icon="mdi-key" ></v-select>
              </v-col>
            </v-row>

            <!-- Access Restrictions -->
            <v-row v-if="showAccessRestrictions" class="mt-4">
              <v-col cols="12">
                <h3 class="subtitle-1 font-weight-bold mb-3">הגבלות גישה</h3>
              </v-col>

              <v-col cols="12" sm="6">
                <v-switch v-model="itemEdited.limited_tables_access" label="הגבלת טבלאות" color="primary" hide-details
                  class="mb-4"></v-switch>

                <v-select v-if="itemEdited.limited_tables_access" v-model="itemEdited.allowed_tables"
                  :items="tablesList" label="טבלאות מאושרות" outlined dense multiple chips :item-text="'hebrew_name'"
                  :item-value="'name'" :rules="[v => !!v || 'שדה חובה']" required></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-form>

        <!-- Loading State -->
        <v-row v-if="progressShow" class="mt-4">
          <v-col class="d-flex justify-center">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <!-- Dialog Actions -->
      <v-card-actions class="dialog-actions">
        <v-spacer></v-spacer>
        <v-btn text @click="openMode = false" :disabled="progressShow">
          ביטול
        </v-btn>
        <v-btn :color="$store.state.colorPrimary" :loading="progressShow" :dark="isFormValid" :disabled="!isFormValid"
          @click="formTitle === 'ערוך משתמש' ? saveEditedUser() : saveNewUser()">
          {{ progressShow ? 'שומר...' : 'שמור' }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <!-- Snackbar -->
    <v-snackbar v-model="snackbar" :color="snackbarColorBt" :timeout="4000" bottom right>
      {{ snacbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">
          סגור
        </v-btn>
      </template>
    </v-snackbar>
  </v-dialog>
</template>


<script>
import ApiServices from '@/services/api.service'
import Auth from '@/services/auth.service';

export default {
  props: {
    itemToEdit: Object,
    formTitle: String,
    databases: Array,
    usersList: Array,
    value: { type: Boolean, default: false },

  },
  data: () => ({
    progressShow: false,
    snackbar: false,
    snackbarColorBt: "green",
    snacbarText: "",
    itemEdited: {},
    permissionList: [{ hebrewName: "הכל", name: "edit" }, { hebrewName: "עריכה בסיסית", name: "basicEdit" }, { hebrewName: "צפייה בלבד", name: "view" }],
    roleListDisabled: [{ hebrewName: "משתמש", name: "user" }, { hebrewName: "סוכן", name: "agent" }, { hebrewName: "מנהל", name: "admin" }, { hebrewName: "מנהל ראשי", name: "superAdmin" }],
    oldDataName: "",
    role: "",
    tablesList: [],
    showPassword: false,
    isFormValid: false
  }),

  methods: {
    async saveNewUser() {
      if (this.$refs.form.validate()) {

        const datesJSON = JSON.stringify({ newUser: this.itemEdited, adminToken: localStorage.getItem("tokenAdmin") || null });
        this.progressShow = true;
        let token = localStorage.getItem("token");

        try {
          let api = process.env.VUE_APP_BASE_URL + "/admin/create_user";
          const res = await fetch(api, ApiServices.requestOptions("POST", datesJSON, token));
          this.progressShow = false;
          const jsonObject = await res.json();
          if (res.status >= 400) {
            this.$emit("userAdded", "", " :שגיאה בהוספת משתמש" + jsonObject.message, "red");
          } else if (res.status === 200) {
            this.itemEdited.id = jsonObject.id
            this.itemEdited.status = 1
            this.$emit("userAdded", this.itemEdited, "המשתמש נוסף בהצלחה", "green");
            this.openMode = false;
          }
        } catch (error) {
          this.progressShow = false;
          this.showSnackBar("Error saved user: " + error, "red");
          this.$emit("userAdded", "", "Error saved user: " + error, "red");
        }
      }
    },
    async saveEditedUser() {
      if (this.$refs.form.validate()) {
        const datesJSON = JSON.stringify(this.itemEdited);
        this.progressShow = true;
        let token = localStorage.getItem("token");

        try {
          let api = process.env.VUE_APP_BASE_URL + "/admin/update_user";
          const res = await fetch(api, ApiServices.requestOptions("PUT", datesJSON, token));
          this.progressShow = false;
          const jsonObject = await res.json();
          if (res.status >= 400) {
            this.$emit("userEdited", "", " :שגיאה בעריכת משתמש" + jsonObject.message, "red");
            this.openMode = false;
          } else if (res.status === 200) {
            let date = new Date().toLocaleString('he-IL', { timeZone: 'Asia/Jerusalem' });
            this.itemEdited.modified_at = date;
            this.$emit("userEdited", this.itemEdited, "המשתמש עודכן בהצלחה", "green");
            this.openMode = false;
          }
        } catch (error) {
          this.progressShow = false;
          this.showSnackBar("Error saved list: " + error, "red");
          this.$emit("userEdited", "", "Error edit date: " + error, "red");
        }
      }
    },
    async getUserLimitedTables() {
      let token = localStorage.getItem("token");
      try {
        let api = process.env.VUE_APP_BASE_URL + "/user/get_user_limited_tables_list?email=" + this.itemEdited.email;
        const res = await fetch(api, ApiServices.requestOptions("GET", "", token));
        const jsonObject = await res.json();
        if (res.status >= 400) {
        } else if (res.status === 200) {
          this.tablesList = jsonObject
        }
      } catch (error) {
        console.log(error);

      }
    },
    showSnackBar(message, color) {
      this.snacbarText = message;
      this.snackbarColorBt = color;
      this.snackbar = true
    },
    validateNumericInput(event) {
      const charCode = event.charCode;
      if (charCode < 48 || charCode > 57) {
        event.preventDefault();
      }
    },
  },
  computed: {
    openMode: {
      get() { return this.value },
      set(v) { this.$emit('input', v) }
    },

    roleList() {
      if (this.role === "superAdmin") {
        return [
          { hebrewName: "משתמש", name: "user" },
          { hebrewName: "סוכן", name: "agent" },
          { hebrewName: "מנהל", name: "admin" }
        ]
      }
      return [
        { hebrewName: "משתמש", name: "user" },
        { hebrewName: "סוכן", name: "agent" }
      ]
    },

    isRoleDisabled() {
      return this.formTitle !== 'הוסף משתמש חדש' || (this.role !== 'superAdmin' && this.role !== 'admin')
    },
    showDatabaseField() {
      return this.itemEdited.role === 'user' && this.itemEdited.permission === 'edit'
    },
    showDatabaseSelectField() {
      return (
        this.itemEdited.role === 'user' &&
        (this.itemEdited.permission === 'view' || this.itemEdited.permission === 'basicEdit')
      )
    },

    showAccessRestrictions() {
      return (
        this.formTitle !== 'הוסף משתמש חדש' &&
        this.itemEdited.permission !== 'edit'
      )
    },

    passwordRules() {
      const rules = []

      if (this.formTitle === 'הוסף משתמש חדש') {
        rules.push(v => !!v || 'שדה חובה')
      }

      rules.push(v => !v || /^[A-Za-z0-9]{9,}$/.test(v) || 'סיסמה חייבת להכיל לפחות 9 תווים באנגלית ובמספרים')

      return rules
    },
    adminsSupport() {
      return this.usersList.filter(user => user.role === 'admin' || user.role === 'superAdmin')
    },
  },
  watch: {
    'itemEdited.limited_tables_access'(newValue, oldValue) {
      if ((!oldValue && newValue && this.itemEdited.permission !== "edit") || (oldValue === false && newValue === true)) {
        this.getUserLimitedTables();
      }
      // Add your logic here
    },
    'itemEdited.permission'(newValue, oldValue) {
      if (oldValue !== newValue && newValue !== "edit" && this.formTitle === "ערוך משתמש") {
        this.getUserLimitedTables();
      }
      // Add your logic here
    }
  },
  mounted() {
    this.role = Auth.getUserRole();
    if (this.role === "agent") {
      this.itemEdited.role = "user";
    }

    if (this.formTitle === "ערוך משתמש") {
      this.itemEdited = JSON.parse(JSON.stringify(this.itemToEdit));

      // Check if allowed_tables is a JSON string
      if (this.itemEdited.allowed_tables && !Array.isArray(this.itemEdited.allowed_tables)) {
        try {
          this.$set(this.itemEdited, 'allowed_tables', JSON.parse(this.itemEdited.allowed_tables));
        } catch (e) {
          console.error('Invalid JSON string:', this.itemEdited.allowed_tables);
        }
      }
    }
  },
}
</script>
<style scoped>
.add-edit-dialog {
  border-radius: 8px;
}

.dialog-header {
  background-color: #f8f9fa;
  padding: 16px 24px;
}

.dialog-actions {
  padding: 16px 24px;
}

/* RTL Support */
.v-application {
  direction: rtl;
}

.v-text-field>>>.v-input__prepend-inner {
  margin-right: 0;
  margin-left: 8px;
}

.v-text-field>>>.v-input__append-inner {
  margin-left: 0;
  margin-right: 8px;
}

/* Responsive Design */
@media (max-width: 600px) {
  .dialog-header {
    padding: 12px 16px;
  }

  .dialog-actions {
    padding: 12px 16px;
  }

  .v-card-text {
    padding: 12px;
  }
}

/* Animations */
.v-dialog-transition-enter-active,
.v-dialog-transition-leave-active {
  transition: opacity 0.2s ease-in-out;
}

.v-dialog-transition-enter,
.v-dialog-transition-leave-to {
  opacity: 0;
}
</style>