<template>
    <div class="details-container">
      <v-card elevation="3" class="details-card">
        <!-- Header Section -->
        <v-card-title class="details-header d-flex align-center pa-6">
          <h1 class="text-h4 font-weight-bold primary--text flex-grow-1">פרטי המשתמש</h1>
          <v-chip
            :color="getStatusColor"
            text-color="white"
            small
            class="mr-2"
          >
            {{ getStatusText }}
          </v-chip>
        </v-card-title>
  
        <v-divider></v-divider>
  
        <!-- User Details Section -->
        <v-card-text class="pa-6">
          <v-row>
            <!-- Basic Information -->
            <v-col cols="12">
              <div class="section-title primary--text mb-4">מידע בסיסי</div>
              <v-row>
                <v-col cols="12" sm="6" md="4" v-for="field in basicFields" :key="field.key">
                  <v-card outlined class="detail-card">
                    <v-card-text>
                      <div class="d-flex align-center mb-2">
                        <v-icon color="secondary" class="ml-2">{{ field.icon }}</v-icon>
                        <span class="text-subtitle-1 font-weight-medium secondary--text">{{ field.label }}</span>
                      </div>
                      <div class="text-body-1 mt-2" :class="{ 'user-select-all': field.selectable }">
                        {{ userDetails[field.key] || '---' }}
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
  
            <!-- System Information -->
            <v-col cols="12" class="mt-4">
              <div class="section-title primary--text mb-4">מידע מערכת</div>
              <v-row>
                <v-col cols="12" sm="6" md="4" v-for="field in systemFields" :key="field.key">
                  <v-card outlined class="detail-card">
                    <v-card-text>
                      <div class="d-flex align-center mb-2">
                        <v-icon color="secondary" class="ml-2">{{ field.icon }}</v-icon>
                        <span class="text-subtitle-1 font-weight-medium secondary--text">{{ field.label }}</span>
                      </div>
                      <div class="text-body-1 mt-2" :class="{ 'user-select-all': field.selectable }">
                        {{ formatFieldValue(field.key, userDetails[field.key]) || '---' }}
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
  </template>
  
  <script>
  export default {
    name: 'UserFullDetails',
    data() {
      return {
        userDetails: {
          name: "",
          nameInvoice: "",
          address: "",
          phone: "",
          email: "",
          role: "",
          permission: "",
          api_token: "",
          status: "",
          special_page: "",
          user_data_name: "",
          last_visit: "",
          created_at: "",
          modified_at: "",
          id: ""
        },
        basicFields: [
          { key: 'name', label: 'שם', icon: 'mdi-account' },
          { key: 'phone', label: 'טלפון', icon: 'mdi-phone' },
          { key: 'email', label: 'אימייל', icon: 'mdi-email' },
          { key: 'role', label: 'קבוצה', icon: 'mdi-account-group' },
          { key: 'permission', label: 'הרשאות', icon: 'mdi-shield-account' },
          { key: 'api_token', label: 'Api Token', icon: 'mdi-key-variant', selectable: true }
        ],
        systemFields: [
          { key: 'user_data_name', label: 'מסד נתונים', icon: 'mdi-database' },
          { key: 'special_page', label: 'דף נוסף', icon: 'mdi-file-document' },
          { key: 'last_visit', label: 'פעילות אחרונה', icon: 'mdi-clock-outline' },
          { key: 'created_at', label: 'נוצר בתאריך', icon: 'mdi-calendar-plus' },
          { key: 'modified_at', label: 'עודכן בתאריך', icon: 'mdi-calendar-edit' },
          { key: 'id', label: 'ID', icon: 'mdi-identifier', selectable: true }
        ]
      }
    },
    computed: {
      getStatusColor() {
        const status = this.userDetails.status
        if (typeof status === 'boolean') {
          return status ? 'success' : 'error'
        }
        return 'grey'
      },
      getStatusText() {
        const status = this.userDetails.status
        if (typeof status === 'boolean') {
          return status ? 'פעיל' : 'לא פעיל'
        }
        return 'לא ידוע'
      }
    },
    methods: {
      formatFieldValue(key, value) {
        if (value === undefined || value === null || value === '') return '---'
  
        switch (key) {
          case 'created_at':
          case 'modified_at':
          case 'last_visit':
            return new Date(value).toLocaleString('he-IL', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
              hour: '2-digit',
              minute: '2-digit'
            })
          case 'status':
            return typeof value === 'boolean' ? (value ? 'פעיל' : 'לא פעיל') : value
          default:
            return value
        }
      }
    },
    mounted() {
      this.userDetails = this.$route.params.user || this.userDetails
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .details-container {
    padding: 24px;
    margin-top: 64px;
    min-height: calc(100vh - 64px);
    background-color: #f5f5f5;
  }
  
  .details-card {
    max-width: 1200px;
    margin: 0 auto;
    border-radius: 8px;
    overflow: hidden;
  }
  
  .details-header {
    background: linear-gradient(to left, rgba(255,255,255,0.95), rgba(255,255,255,0.95));
  }
  
  .section-title {
    font-size: 1.5rem;
    font-weight: 600;
    padding-bottom: 8px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  }
  
  .detail-card {
    height: 100%;
    transition: transform 0.2s, box-shadow 0.2s;
    border-radius: 8px;
  
    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 4px 8px rgba(0,0,0,0.1) !important;
    }
  }
  
  .user-select-all {
    user-select: all;
    cursor: pointer;
    padding: 4px 8px;
    background-color: rgba(0, 0, 0, 0.03);
    border-radius: 4px;
    font-family: monospace;
  }
  
  // RTL specific styles
  :deep(.v-card__title) {
    justify-content: flex-start;
  }
  
  :deep(.v-icon) {
    margin-left: 8px;
    margin-right: 0;
  }
  
  // Responsive adjustments
  @media (max-width: 600px) {
    .details-container {
      padding: 16px;
      margin-top: 32px;
    }
  
    .section-title {
      font-size: 1.25rem;
    }
  }
  </style>