var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"800px","transition":"dialog-bottom-transition","retain-focus":false,"content-class":"add-edit-dialog"},model:{value:(_vm.openMode),callback:function ($$v) {_vm.openMode=$$v},expression:"openMode"}},[_c('v-card',{staticClass:"add-edit-card"},[_c('v-card-title',{staticClass:"header"},[_c('div',{staticClass:"d-flex align-center justify-space-between w-100"},[_c('span',{staticClass:"text-h5 font-weight-medium"},[_vm._v(_vm._s(_vm.formTitle))]),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.openMode = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)]),_c('v-divider'),_c('v-card-text',{staticClass:"form-content"},[_c('v-container',[_c('v-row',_vm._l((_vm.filteredFields),function(field,index){return _c('v-col',{key:index,staticClass:"field-col",attrs:{"cols":"12","sm":"6"}},[(field.type !== 'רשימה' && field.type !== 'רשימה מקושרת')?_c('v-text-field',{staticClass:"field-input",attrs:{"label":field.text,"type":_vm.getFieldType(field),"rules":_vm.getFieldRules(field),"prefix":_vm.getFieldPrefix(field),"suffix":_vm.getFieldSuffix(field),"autofocus":index === 0,"hide-spin-buttons":"","outlined":"","dense":"","placeholder":_vm.getFieldPlaceholder(field)},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-icon',{attrs:{"small":"","color":"grey"}},[_vm._v(_vm._s(_vm.getFieldIcon(field)))])]},proxy:true}],null,true),model:{value:(_vm.itemEdited[field.value]),callback:function ($$v) {_vm.$set(_vm.itemEdited, field.value, $$v)},expression:"itemEdited[field.value]"}}):(field.type === 'רשימה')?_c('v-autocomplete',{staticClass:"field-input",attrs:{"items":field.list,"label":field.text,"autofocus":index === 0,"outlined":"","dense":"","clearable":"","menu-props":{
                bottom: true,
                offsetY: true,
                transition: 'slide-y-transition'
              }},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-icon',{attrs:{"small":"","color":"grey"}},[_vm._v("mdi-format-list-bulleted")])]},proxy:true}],null,true),model:{value:(_vm.itemEdited[field.value]),callback:function ($$v) {_vm.$set(_vm.itemEdited, field.value, $$v)},expression:"itemEdited[field.value]"}}):(field.type === 'רשימה מקושרת')?_c('v-autocomplete',{staticClass:"field-input",attrs:{"items":_vm.fieldsSourceList,"item-value":"name","item-text":"hebrew_name","label":field.text,"outlined":"","dense":"","clearable":"","autofocus":index === 0,"menu-props":{
                bottom: true,
                offsetY: true,
                transition: 'slide-y-transition'
              }},on:{"mouseover":function($event){return _vm.getSourceList(field.linkedList)}},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-icon',{attrs:{"small":"","color":"grey"}},[_vm._v("mdi-link-variant")])]},proxy:true},{key:"no-data",fn:function(){return [_c('div',{staticClass:"pa-2 text-center"},[_c('v-icon',[_vm._v("mdi-alert-circle-outline")]),_vm._v(" לא נמצאו תוצאות ")],1)]},proxy:true}],null,true),model:{value:(_vm.itemEdited[field.value]),callback:function ($$v) {_vm.$set(_vm.itemEdited, field.value, $$v)},expression:"itemEdited[field.value]"}}):_vm._e()],1)}),1)],1)],1),_c('v-overlay',{attrs:{"value":_vm.progressShow,"absolute":""}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary","size":"64"}})],1),_c('v-divider'),_c('v-card-actions',{staticClass:"actions"},[_c('v-spacer'),_c('v-btn',{staticClass:"action-button mr-2",attrs:{"text":"","disabled":_vm.progressShow},on:{"click":function($event){_vm.openMode = false}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-close")]),_vm._v(" ביטול ")],1),_c('v-btn',{staticClass:"action-button",attrs:{"color":"primary","loading":_vm.progressShow,"disabled":_vm.progressShow},on:{"click":function($event){_vm.formTitle === 'ערוך רשומה' ? _vm.saveEditedItem() : _vm.saveNewItem()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(_vm.formTitle === 'ערוך רשומה' ? 'mdi-content-save' : 'mdi-plus'))]),_vm._v(" "+_vm._s(_vm.formTitle === 'ערוך רשומה' ? 'שמור שינויים' : 'צור רשומה')+" ")],1)],1)],1),_c('SnackBar',{attrs:{"snacbarText":_vm.snacbarText,"snackbarColorBt":_vm.snackbarColorBt},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }