<template>
  <div>
    <nav-bar></nav-bar>
    <v-container class="guides-container" fluid>
      <v-row justify="center" align="center">
        <v-col cols="12" sm="11" md="10" lg="8">
          <v-card elevation="3" class="guides-card">
            <!-- Header Section -->
            <v-card-title class="guides-header text-center d-block pa-6">
              <h1  class="display-1 font-weight-bold primary--text">קישורים מומלצים</h1>
              <v-divider class="mx-4 my-3"></v-divider>
              <p class="subtitle-1 grey--text text--darken-1">
                מדריכים ומסמכים שימושיים לעבודה עם המערכת
              </p>
            </v-card-title>

            <v-card-text>
              <v-row>
                <!-- Tables Section -->
                <v-col cols="12" md="4">
                  <v-card class="guide-section-card" outlined>
                    <v-card-title class="primary--text justify-center">
                      <v-icon large color="primary" class="ml-2">mdi-table</v-icon>
                      טבלאות
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-list nav dense>
                      <v-list-item v-for="(guide, index) in tableGuides" :key="index" :href="guide.url" target="_blank"
                        class="guide-link">
                        <v-list-item-icon>
                          <v-icon color="primary">{{ guide.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>{{ guide.title }}</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-icon small color="grey">mdi-open-in-new</v-icon>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-col>

                <!-- API Section -->
                <v-col cols="12" md="4">
                  <v-card class="guide-section-card" outlined>
                    <v-card-title class="primary--text justify-center">
                      <v-icon large color="primary" class="ml-2">mdi-api</v-icon>
                      API
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-list nav dense>
                      <v-list-item v-for="(guide, index) in apiGuides" :key="index" @click="openLink(guide.url)" target="_blank"
                        class="guide-link">
                        <v-list-item-icon>
                          <v-icon color="primary">{{ guide.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>{{ guide.title }}</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                          <template v-if="guide.copyable">
                            <v-btn icon small @click.stop="copyToClipboard(guide.url)" class="copy-button">
                              <v-icon small>mdi-content-copy</v-icon>
                            </v-btn>
                          </template>
                          <v-icon v-else small color="grey">mdi-open-in-new</v-icon>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-col>

                <!-- CRM Integration Section -->
                <v-col cols="12" md="4">
                  <v-card class="guide-section-card" outlined>
                    <v-card-title class="primary--text justify-center">
                      <v-icon large color="primary" class="ml-2">mdi-connection</v-icon>
                      אינטיגרציה לCRM
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-list nav dense>
                      <v-list-item v-for="(guide, index) in crmGuides" :key="index" :href="guide.url" target="_blank"
                        class="guide-link">
                        <v-list-item-icon>
                          <v-icon color="primary">{{ guide.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>{{ guide.title }}</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-icon small color="grey">mdi-open-in-new</v-icon>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-snackbar v-model="snackbar" :color="snackbarColorBt" :timeout="3000" top right>
      {{ snacbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">
          סגור
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Auth from '@/services/auth.service.js'
import NavBar from '@/components/widgets/navBar.vue'

export default {
  name: 'GuidesView',
  components: {
    NavBar
  },
  data() {
    return {
      snackbar: false,
      snackbarColorBt: "success",
      snacbarText: "",
      tableGuides: [
        {
          title: 'יצירת טבלה ושדות',
          url: 'https://ivrapi.clickcall.co.il/files/users_panel_guides/create-table.pdf',
          icon: 'mdi-table-plus'
        },
        {
          title: 'מאפייני שדות',
          url: 'https://ivrapi.clickcall.co.il/files/users_panel_guides/fields-properties.pdf',
          icon: 'mdi-format-list-checks'
        },
        {
          title: 'ייבוא נתונים מאקסל',
          url: 'https://ivrapi.clickcall.co.il/files/users_panel_guides/import-from-excel.pdf',
          icon: 'mdi-microsoft-excel'
        },
        {
          title: 'תזמון משימות',
          url: 'https://ivrapi.clickcall.co.il/files/users_panel_guides/mission-schedule.pdf',
          icon: 'mdi-clock-outline'
        }
      ],
      apiGuides: [
        {
          title: 'מידע כללי',
          url: 'https://ivrapi.clickcall.co.il/files/users_panel_guides/api/general-details.pdf',
          icon: 'mdi-information'
        },
        {
          title: 'פוסטמן API',
          url: 'https://ivrapi.clickcall.co.il/files/users_panel_guides/api/users_panel_api_postman.json',
          icon: 'mdi-postage-stamp',
          copyable: true
        }
      ],
      crmGuides: [
        {
          title: 'הוראות',
          url: 'https://ivrapi.clickcall.co.il/files/users_panel_guides/CRM/crm-instructions.pdf',
          icon: 'mdi-book-open-page-variant'
        }
      ]
    }
  },
  methods: {
    copyToClipboard(text) {
      console.log(344444);
      
      navigator.clipboard.writeText(text).then(
        () => {
          this.showSnackBar("הקישור הועתק בהצלחה", "success");
        },
        (err) => {
          console.error("Failed to copy text: ", err);
          this.showSnackBar("שגיאה בהעתקת הקישור", "error");
        }
      );
    },
    showSnackBar(message, color) {
      this.snacbarText = message;
      this.snackbarColorBt = color;
      this.snackbar = true;
    },
    openLink(url) {
    window.open(url, '_blank');
  },
  },
  mounted() {
    document.title = 'מדריכים | CLICKCALL';

    let auth = Auth.checkAuth();
    if (!auth) {
      this.$router.replace({ name: "login" });
    }
  }
}
</script>

<style lang="scss" scoped>
.guides-container {
  min-height: calc(100vh - 64px);
  padding-top: 64px;
  background-color: #f5f5f5;
}

.guides-card {
  border-radius: 8px;
  overflow: hidden;
}

.guides-header {
  background: linear-gradient(to left, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95));
}

.guide-section-card {
  height: 100%;
  transition: transform 0.2s, box-shadow 0.2s;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
  }
}

.guide-link {
  transition: background-color 0.2s;

  &:hover {
    background-color: #f5f5f5;

    .v-icon {
      transform: scale(1.1);
    }
  }

  .v-icon {
    transition: transform 0.2s;
  }
}

.copy-button {
  opacity: 0.6;
  transition: opacity 0.2s;

  &:hover {
    opacity: 1;
  }
}

// RTL specific styles
:deep(.v-list-item__icon) {
  margin-left: 16px !important;
  margin-right: 0 !important;
}

:deep(.v-list-item__action) {
  margin-right: auto !important;
  margin-left: 0 !important;
}

// Responsive adjustments
@media (max-width: 600px) {
  .guides-container {
    padding-top: 32px;
  }

  .guide-section-card {
    margin-bottom: 16px;
  }
}
</style>