<template>
    <v-dialog v-model="openMode" max-width="480px" persistent>
        <v-card class="list-dialog-card">
            <!-- Header -->
            <div class="dialog-header">
                <v-icon size="32" color="#0d2c6d" class="mb-4">mdi-format-list-bulleted</v-icon>
                <v-card-subtitle class="text-h5 font-weight-bold primary-text pb-2">
                    ערוך רשימה
                </v-card-subtitle>
                <v-card-subtitle class="text-subtitle-1 text-medium-emphasis text-center">
                    נהל את פריטי הרשימה
                </v-card-subtitle>
            </div>

            <!-- Content -->
            <v-card-text class="list-content pt-4">
                <div class="list-header d-flex align-center mb-4">
                    <div class="list-count">
                        <span class="text-subtitle-1">נתונים: </span>
                        <span class="font-weight-bold">{{ fieldEditedList.list.length }}</span>
                    </div>
                    <v-btn small fab class="add-button mr-2" @click="addItem">
                        <v-icon small color="white">mdi-plus</v-icon>
                    </v-btn>
                </div>

                <div class="list-items">
                    <v-slide-y-transition group>
                        <div v-for="(item, index) in fieldEditedList.list" :key="index" class="list-item">
                            <v-text-field v-model="fieldEditedList.list[index]" :label="'נתון ' + (index + 1)"
                                :rules="[v => !!v || 'שדה חובה']" dense outlined class="custom-input" required>
                                <template v-slot:append>
                                    <v-icon small class="delete-icon" @click="deleteItem(index)">
                                        mdi-close
                                    </v-icon>
                                </template>
                            </v-text-field>
                        </div>
                    </v-slide-y-transition>
                </div>
            </v-card-text>

            <!-- Actions -->
            <v-card-actions class="dialog-actions">
                <v-btn text class="cancel-button" @click="closeList">
                    <v-icon left>mdi-close</v-icon>
                    ביטול
                </v-btn>
                <v-btn class="save-button" @click="actionConfirmed">
                    <v-icon left>mdi-check</v-icon>
                    שמור
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>


<script>
export default {
    props: {
        field: Object,
        fieldIndex: Number,
        value: { type: Boolean, default: false },
    },
    data: () => ({
        snackbar: false,
        snackbarColorBt: "green",
        snacbarText: "",
        fieldEditedList: {}
    }),

    methods: {
        actionConfirmed() {
            this.$emit("listUpdated", this.fieldEditedList, this.fieldIndex);
            this.openMode = false;
        },
        closeList() {
            this.$emit("listUpdated");
            this.openMode = false;
        },
        addItem() {
            this.fieldEditedList.list.push("");
        },
        deleteItem(index) {
            this.fieldEditedList.list.splice(index, 1);
        }
    },
    computed: {
        openMode: {
            get() {
                return this.value
            },
            set(v) {
                return this.$emit('input', v)
            }
        },
    },
    created() {
        //deep clone
        this.fieldEditedList = JSON.parse(JSON.stringify(this.field));
    },
}
</script>
<style scoped>
.list-dialog-card {
    border-radius: 16px;
    overflow: hidden;
    background: rgba(255, 255, 255, 0.98);
    backdrop-filter: blur(10px);
}

.dialog-header {
    padding: 1.5rem 1.5rem 0.5rem;
    text-align: center;
    background: linear-gradient(to bottom, rgba(13, 44, 109, 0.02), transparent);
}

.primary-text {
    background: linear-gradient(135deg, #0d2c6d 0%, #1a4ba5 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.list-content {
    max-height: 60vh;
    overflow-y: auto;
}

.list-header {
    background: rgba(13, 44, 109, 0.02);
    padding: 0.75rem;
    border-radius: 8px;
    border: 1px solid rgba(13, 44, 109, 0.1);
}

.list-count {
    color: #64748b;
}

.add-button {
    background: linear-gradient(135deg, #0d2c6d 0%, #1a4ba5 100%) !important;
    box-shadow: 0 4px 12px rgba(13, 44, 109, 0.2);
    transition: all 0.3s ease;
}

.add-button:hover {
    transform: translateY(-1px);
    box-shadow: 0 6px 16px rgba(13, 44, 109, 0.3) !important;
}

.list-items {
    padding: 0.5rem 0;
}

.list-item {
    margin-bottom: 0.5rem;
    position: relative;
}

/* Custom Input Styling */
.custom-input {
    transition: all 0.3s ease;
}

.custom-input>>>.v-input__slot {
    border: 1px solid rgba(13, 44, 109, 0.1) !important;
    transition: all 0.3s ease;
}

.custom-input>>>.v-input__slot:hover {
    border-color: rgba(13, 44, 109, 0.2) !important;
    background: rgba(13, 44, 109, 0.03);
}

.custom-input.v-input--is-focused>>>.v-input__slot {
    border-color: #0d2c6d !important;
    box-shadow: 0 0 0 1px rgba(13, 44, 109, 0.2) !important;
    background: rgba(13, 44, 109, 0.02);
}

.delete-icon {
    color: #ef4444;
    cursor: pointer;
    opacity: 0.6;
    transition: all 0.3s ease;
}

.delete-icon:hover {
    opacity: 1;
    transform: scale(1.1);
}

/* Dialog Actions */
.dialog-actions {
    padding: 1rem 1.5rem;
    display: flex;
    justify-content: center;
    gap: 1rem;
}

/* Buttons */
.save-button {
    background: linear-gradient(135deg, #0d2c6d 0%, #1a4ba5 100%) !important;
    color: white !important;
    padding: 0 1.5rem !important;
    height: 40px;
    text-transform: none;
    letter-spacing: 0.5px;
    font-size: 0.9375rem;
    font-weight: 500;
    border-radius: 8px;
    transition: all 0.3s ease;
}

.save-button:hover {
    opacity: 0.95;
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(13, 44, 109, 0.2) !important;
}

.cancel-button {
    color: #64748b !important;
    padding: 0 1.5rem !important;
    height: 40px;
    text-transform: none;
    letter-spacing: 0.5px;
    font-size: 0.9375rem;
    border-radius: 8px;
    transition: all 0.3s ease;
}

.cancel-button:hover {
    background: rgba(13, 44, 109, 0.05) !important;
    color: #0d2c6d !important;
}

/* RTL Support */
.v-application {
    direction: rtl;
}

.v-text-field>>>.v-input__prepend-inner {
    margin-right: 0;
    margin-left: 8px;
}

.v-text-field>>>.v-input__append-inner {
    margin-left: 0;
    margin-right: 8px;
}

/* Custom Scrollbar */
.list-content::-webkit-scrollbar {
    width: 8px;
}

.list-content::-webkit-scrollbar-track {
    background: rgba(13, 44, 109, 0.02);
    border-radius: 4px;
}

.list-content::-webkit-scrollbar-thumb {
    background: rgba(13, 44, 109, 0.1);
    border-radius: 4px;
}

.list-content::-webkit-scrollbar-thumb:hover {
    background: rgba(13, 44, 109, 0.2);
}
</style>