<template>
    <v-dialog
      v-model="openMode"
      max-width="800px"
      transition="dialog-bottom-transition"
      :retain-focus="false"
      content-class="add-edit-dialog"
    >
      <v-card class="add-edit-card">
        <!-- Header -->
        <v-card-title class="header">
          <div class="d-flex align-center justify-space-between w-100">
            <span class="text-h5 font-weight-medium">{{ formTitle }}</span>
            <v-btn icon @click="openMode = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </v-card-title>
  
        <v-divider></v-divider>
  
        <!-- Form Content -->
        <v-card-text class="form-content">
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                v-for="(field, index) in filteredFields"
                :key="index"
                class="field-col"
              >
                <!-- Text/Number/Date Fields -->
                <v-text-field
                  v-if="field.type !== 'רשימה' && field.type !== 'רשימה מקושרת'"
                  v-model="itemEdited[field.value]"
                  :label="field.text"
                  :type="getFieldType(field)"
                  :rules="getFieldRules(field)"
                  :prefix="getFieldPrefix(field)"
                  :suffix="getFieldSuffix(field)"
                  :autofocus="index === 0"
                  hide-spin-buttons
                  outlined
                  dense
                  class="field-input"
                  :placeholder="getFieldPlaceholder(field)"
                >
                  <template v-slot:prepend>
                    <v-icon small color="grey">{{ getFieldIcon(field) }}</v-icon>
                  </template>
                </v-text-field>
  
                <!-- Regular List -->
                <v-autocomplete
                  v-else-if="field.type === 'רשימה'"
                  v-model="itemEdited[field.value]"
                  :items="field.list"
                  :label="field.text"
                  :autofocus="index === 0"
                  outlined
                  dense
                  clearable
                  class="field-input"
                  :menu-props="{
                    bottom: true,
                    offsetY: true,
                    transition: 'slide-y-transition'
                  }"
                >
                  <template v-slot:prepend>
                    <v-icon small color="grey">mdi-format-list-bulleted</v-icon>
                  </template>
                </v-autocomplete>
  
                <!-- Linked List -->
                <v-autocomplete
                  v-else-if="field.type === 'רשימה מקושרת'"
                  v-model="itemEdited[field.value]"
                  :items="fieldsSourceList"
                  item-value="name"
                  item-text="hebrew_name"
                  :label="field.text"
                  @mouseover="getSourceList(field.linkedList)"
                  outlined
                  dense
                  clearable
                  :autofocus="index === 0"
                  class="field-input"
                  :menu-props="{
                    bottom: true,
                    offsetY: true,
                    transition: 'slide-y-transition'
                  }"
                >
                  <template v-slot:prepend>
                    <v-icon small color="grey">mdi-link-variant</v-icon>
                  </template>
                  <template v-slot:no-data>
                    <div class="pa-2 text-center">
                      <v-icon>mdi-alert-circle-outline</v-icon>
                      לא נמצאו תוצאות
                    </div>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
  
        <!-- Loading Progress -->
        <v-overlay :value="progressShow" absolute>
          <v-progress-circular
            indeterminate
            color="primary"
            size="64"
          ></v-progress-circular>
        </v-overlay>
  
        <!-- Actions -->
        <v-divider></v-divider>
        
        <v-card-actions class="actions">
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="openMode = false"
            class="action-button mr-2"
            :disabled="progressShow"
          >
            <v-icon left>mdi-close</v-icon>
            ביטול
          </v-btn>
          <v-btn
            color="primary"
            @click="formTitle === 'ערוך רשומה' ? saveEditedItem() : saveNewItem()"
            class="action-button"
            :loading="progressShow"
            :disabled="progressShow"
          >
            <v-icon left>{{ formTitle === 'ערוך רשומה' ? 'mdi-content-save' : 'mdi-plus' }}</v-icon>
            {{ formTitle === 'ערוך רשומה' ? 'שמור שינויים' : 'צור רשומה' }}
          </v-btn>
        </v-card-actions>
      </v-card>
  
      <SnackBar
        v-model="snackbar"
        :snacbarText="snacbarText"
        :snackbarColorBt="snackbarColorBt"
      ></SnackBar>
    </v-dialog>
  </template>
  
  <script>
  import ApiServices from '@/services/api.service'
  import utils from '@/util/utils'
  import SnackBar from '@/components/widgets/snackBar.vue'
  
  export default {
    name: 'AddEditDialog',
    
    components: {
      SnackBar,
    },
  
    props: {
      itemToEdit: {
        type: Object,
        default: () => ({}),
      },
      fields: {
        type: Array,
        required: true,
      },
      formTitle: {
        type: String,
        required: true,
      },
      tableName: {
        type: Object,
        required: true,
      },
      value: {
        type: Boolean,
        default: false,
      },
    },
  
    data: () => ({
      progressShow: false,
      snackbar: false,
      snackbarColorBt: "green",
      snacbarText: "",
      itemEdited: {},
      fieldsSourceList: [],
      fieldErrors: {},
    }),
  
    computed: {
      openMode: {
        get() {
          return this.value
        },
        set(v) {
          return this.$emit('input', v)
        }
      },
      filteredFields() {
        return this.fields.filter((field) => 
          field.value !== "date_created" && 
          field.value !== "date_modified" && 
          field.value !== "id" && 
          field.show !== false
        );
      },
    },
  
    methods: {
      getFieldIcon(field) {
        const icons = {
          'מספר': 'mdi-numeric',
          'טקסט': 'mdi-form-textbox',
          'לינק': 'mdi-link',
          'תאריך': 'mdi-calendar',
          'תאריך ושעה': 'mdi-calendar-clock',
        }
        return icons[field.type] || 'mdi-form-textbox'
      },
  
      getFieldType(field) {
        const types = {
          'מספר': 'number',
          'טקסט': 'text',
          'לינק': 'text',
          'תאריך': 'date',
          'תאריך ושעה': 'datetime-local',
        }
        return types[field.type] || 'text'
      },
  
      getFieldPlaceholder(field) {
        const placeholders = {
          'מספר': 'הזן מספר...',
          'טקסט': 'הזן טקסט...',
          'לינק': 'הזן קישור...',
          'תאריך': 'בחר תאריך...',
          'תאריך ושעה': 'בחר תאריך ושעה...',
        }
        return placeholders[field.type] || 'הזן ערך...'
      },
  
      getFieldPrefix(field) {
        return field.type === 'לינק' ? 'https://' : ''
      },
  
      getFieldSuffix(field) {
        return field.type === 'מספר' && field.unit ? field.unit : ''
      },
  
      getFieldRules(field) {
        const rules = []
        
        if (!field.allowNull) {
          rules.push(v => !!v || 'שדה חובה')
        }
  
        if (field.type === 'מספר') {
          rules.push(v => !isNaN(v) || 'חייב להיות מספר')
        }
  
        if (field.type === 'לינק') {
          rules.push(v => !v || /^https?:\/\/.+/.test(v) || 'כתובת URL לא תקינה')
        }
  
        return rules
      },
  
      async saveNewItem() {
        try {
          this.progressShow = true
          const itemJSON = JSON.stringify({ tableName: this.tableName, item: this.itemEdited })
          let token = localStorage.getItem("token")
          
          const res = await fetch(
            `${process.env.VUE_APP_BASE_URL}/user/add_item`,
            ApiServices.requestOptions("POST", itemJSON, token)
          )
          
          const jsonObject = await res.json()
          
          if (res.status >= 400) {
            throw new Error(jsonObject.message)
          }
          
          this.itemEdited.id = jsonObject.id
          this.itemEdited.date_created = utils.getCurrentDate()
          this.$emit("itemAdded", this.itemEdited, "הרשומה נוספה בהצלחה", "green")
          this.openMode = false
          
        } catch (error) {
          this.$emit("itemAdded", "", `שגיאה בהוספת רשומה: ${error.message}`, "error")
        } finally {
          this.progressShow = false
        }
      },
  
      async saveEditedItem() {
        try {
          this.progressShow = true
          const itemJSON = JSON.stringify({ tableName: this.tableName, item: this.itemEdited })
          let token = localStorage.getItem("token")
          
          const res = await fetch(
            `${process.env.VUE_APP_BASE_URL}/user/update_item`,
            ApiServices.requestOptions("PUT", itemJSON, token)
          )
          
          const jsonObject = await res.json()
          
          if (res.status >= 400) {
            throw new Error(jsonObject.message)
          }
          
          this.itemEdited.date_modified = utils.getCurrentDate()
          this.$emit("itemEdited", this.itemEdited, "הרשומה עודכנה בהצלחה", "green")
          this.openMode = false
          
        } catch (error) {
          this.$emit("itemEdited", "", `שגיאה בעדכון רשומה: ${error.message}`, "error")
        } finally {
          this.progressShow = false
        }
      },
  
      async getSourceList(linkedList) {
        try {
          this.fieldsSourceList = []
          let token = localStorage.getItem("token")
          
          const tableJSON = JSON.stringify({
            tableName: linkedList.tableSource,
            fieldName: linkedList.fieldSource
          })
  
          const res = await fetch(
            `${process.env.VUE_APP_BASE_URL}/user/get_field_data_linked_list`,
            ApiServices.requestOptions("POST", tableJSON, token)
          )
          
          const jsonObject = await res.json()
          
          if (res.status >= 400) {
            throw new Error(jsonObject.message)
          }
          
          this.fieldsSourceList = jsonObject
          
        } catch (error) {
          this.showSnackBar(`שגיאה בטעינת רשימה: ${error.message}`, "error")
        }
      },
  
      showSnackBar(message, color) {
        this.snacbarText = message
        this.snackbarColorBt = color
        this.snackbar = true
      },
    },
  
    mounted() {
      if (this.formTitle === 'ערוך רשומה') {
        this.itemEdited = JSON.parse(JSON.stringify(this.itemToEdit))
      } else {
        this.fields.forEach((field) => {
          this.$set(this.itemEdited, field.value, '')
        })
      }
    },
  }
  </script>
  
  <style scoped>
  .add-edit-dialog {
    border-radius: 8px;
    overflow: hidden;
  }
  
  .add-edit-card {
    border-radius: 8px;
    overflow: hidden;
  }
  
  .header {
    background: #f8f9fa;
    padding: 16px 24px;
  }
  
  .form-content {
    padding: 24px;
    max-height: 70vh;
    overflow-y: auto;
  }
  
  .field-col {
    transition: all 0.3s ease;
  }
  
  .field-input {
    transition: all 0.2s ease;
  }
  
  .field-input:hover {
    transform: translateY(-1px);
  }
  
  .actions {
    padding: 16px 24px;
    background: #f8f9fa;
  }
  
  .action-button {
    min-width: 120px;
    text-transform: none;
    letter-spacing: 0;
  }
  
  /* Scrollbar Styling */
  .form-content::-webkit-scrollbar {
    width: 8px;
  }
  
  .form-content::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }
  
  .form-content::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 4px;
  }
  
  .form-content::-webkit-scrollbar-thumb:hover {
    background: #999;
  }
  
  /* RTL Specific Styles */
  :dir(rtl) {
    text-align: right;
  }
  
  .v-text-field >>> .v-input__prepend-outer {
    margin-right: 0;
    margin-left: 9px;
  }
  
  .v-btn >>> .v-icon {
    margin-right: 0;
    margin-left: 8px;
  }
  
  /* Transitions */
  .dialog-bottom-transition-enter-active,
  .dialog-bottom-transition-leave-active {
    transition: transform 0.3s ease-in-out;
  }
  
  .dialog-bottom-transition-enter,
  .dialog-bottom-transition-leave-to {
    transform: translateY(100%);
  }
  
  /* Responsive Design */
  @media (max-width: 600px) {
    .add-edit-dialog {
      margin: 0;
    }
  
    .form-content {
      padding: 16px;
    }
  
    .actions {
      padding: 12px 16px;
    }
  
    .action-button {
      min-width: 100px;
    }
  }
  </style>