<template>
  <v-container fluid class="task-settings-container">
    <v-row class="justify-center">
      <v-col cols="12" lg="10">
        <v-card class="settings-card">
          <v-form @submit.prevent="submitHandler" ref="form">
            <!-- Header Section -->
            <div class="header-section">
              <v-btn class="back-button" @click="openMode = false" icon elevation="0">
                <v-icon>mdi-arrow-right</v-icon>
              </v-btn>
              <h1 class="header-title">הגדרות משימה</h1>
            </div>

            <v-divider></v-divider>

            <!-- Main Content -->
            <div class="content-section">
              <!-- Task Name -->
              <div class="section-wrapper">
                <v-text-field v-model="editedTask.name" outlined dense label="שם המשימה" maxlength="32"
                  class="task-name-field" :rules="[v => !!v || 'שדה חובה']" required></v-text-field>
              </div>

              <!-- Actions and Settings Grid -->
              <v-row class="settings-grid">
                <!-- Actions Section -->
                <v-col cols="12" md="6" class="settings-column">
                  <div class="section-title">בחר פעולה</div>

                  <div class="actions-grid">
                    <v-hover v-for="item in actions" :key="item.id">
                      <template v-slot:default="{ hover }">
                        <v-card class="action-card" :class="{
                          'action-selected': selectedItemAction === item.id,
                          'action-hover': hover
                        }" @click="chooseAction(item)" elevation="0">
                          <v-icon class="action-icon" :color="selectedItemAction === item.id ? 'white' : 'primary'">
                            {{ getActionIcon(item.name) }}
                          </v-icon>
                          <span class="action-text">{{ item.hebrew_name }}</span>
                        </v-card>
                      </template>
                    </v-hover>
                  </div>

                  <!-- Additional Fields Based on Action -->
                  <div class="additional-fields" v-if="editedTask.action === 'update_field'">
                    <v-select :items="fieldsFiltered" v-model="editedTask.field" item-value="name"
                      item-text="hebrew_name" label="בחר תא לעדכון" outlined dense class="mb-4"
                      :rules="[v => !!v || 'שדה חובה']" required></v-select>

                    <v-text-field v-model="editedTask.value" outlined dense label="ערך לעדכון"></v-text-field>
                  </div>

                  <div class="forward-actions" v-if="editedTask.action === 'forward_row'">
                    <v-btn @click="copyTable()" color="primary" class="copy-table-btn mb-4" elevation="0">
                      <v-icon left>mdi-content-copy</v-icon>
                      שכפל טבלה
                    </v-btn>

                    <v-select :items="tablesFiltered" v-model="editedTask.table_to_forward" item-value="name"
                      item-text="hebrew_name" label="בחר טבלה להעברה" outlined dense :rules="[v => !!v || 'שדה חובה']"
                      required></v-select>
                  </div>
                </v-col>

                <!-- Triggers Section -->
                <v-col cols="12" md="6" class="settings-column">
                  <div class="section-title">הגדרות הפעלה</div>

                  <v-select :items="trigers" v-model="editedTask.triger" item-value="name" item-text="hebrew_name"
                    label="גורם מפעיל" outlined dense class="mb-4" :rules="[v => !!v || 'שדה חובה']"
                    required></v-select>

                  <!-- Time Options -->
                  <v-select v-if="editedTask.triger === 'after_days'" :items="trigers[0].options"
                    v-model="editedTask.option" label="בחר ימים" outlined dense :rules="[v => !!v || 'שדה חובה']"
                    required></v-select>

                  <v-select v-if="editedTask.triger === 'after_hours'" :items="trigers[1].options"
                    v-model="editedTask.option" label="בחר שעות" outlined dense :rules="[v => !!v || 'שדה חובה']"
                    required></v-select>

                  <v-select v-if="editedTask.triger === 'after_minutes'" :items="trigers[2].options"
                    v-model="editedTask.option" label="בחר דקות" outlined dense :rules="[v => !!v || 'שדה חובה']"
                    required></v-select>

                  <v-select v-if="editedTask.triger === 'custome_date_field'" :items="dateFieldsFiltered"
                    v-model="editedTask.custom_field_date" item-value="name" item-text="hebrew_name"
                    label="בחר שדה לתאריך ביצוע" outlined dense :rules="[v => !!v || 'שדה חובה']" required></v-select>
                </v-col>
              </v-row>

              <!-- Task Status -->
              <div class="status-section " style="direction: rtl;">
               
                <v-switch v-model="editedTask.mode" :color="$store.state.colorPrimary"  inset dense
                  hide-details :label="editedTask.mode ? 'משימה פעילה' : 'משימה מושבתת'">
                </v-switch>
              </div>

              <!-- Action Buttons -->
              <div class="actions-section">
                <v-btn @click="saveChanges()" color="primary" class="save-button ml-5" elevation="0"
                  :loading="progressShow">
                  <v-icon left>mdi-content-save</v-icon>
                  שמור שינויים
                </v-btn>

                <v-btn @click="deleteTask()" class="delete-button" elevation="0" :loading="progressShow">
                  <v-icon left>mdi-delete</v-icon>
                  מחק משימה
                </v-btn>
              </div>

            </div>
          </v-form>
        </v-card>
      </v-col>
    </v-row>

    <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>
  </v-container>
</template>

<script>
import ApiServices from '@/services/api.service.js'
import SnackBar from '@/components/widgets/snackBar.vue'
import Auth from '@/services/auth.service.js'

export default {
  components: {
    SnackBar,
  },
  props: {
    taskToEdit: Object,
    tableName: Object,
    trigers: Array,
    actions: Array,
    value: { type: Boolean, default: false },
  },
  data: () => ({
    tasks: [],
    fields: [],
    tables: [],
    progressShow: false,
    snackbar: false,
    snackbarColorBt: "green",
    snacbarText: "",
    createDialog: false,
    editedTask: {},
    selectedItemAction: null,
  }),
  computed: {
    openMode: {
      get() {
        return this.value
      },
      set(v) {
        return this.$emit('input', v)
      }
    },
    fieldsFiltered() {
      return this.fields.filter(field => field.name !== "date_created" && field.name !== "date_modified")
    },
    dateFieldsFiltered() {
      return this.fields.filter(field =>
        field.name !== "date_created" &&
        field.name !== "date_modified" &&
        (field.type === "תאריך" || field.type === "תאריך ושעה")
      )
    },
    tablesFiltered() {
      return this.tables.filter(table => table.name !== this.tableName.name)
    },
  },
  methods: {
    getActionIcon(action) {
      const icons = {
        'update_field': 'mdi-pencil',
        'forward_row': 'mdi-arrow-right-bold',
        // Add more mappings as needed
      }
      return icons[action] || 'mdi-cog'
    },
    async saveChanges() {
      if (this.$refs.form.validate()) {

        if (!this.selectedItemAction) {
          return this.showSnackBar("לא נבחרה פעולה", "red")
        }
        try {
          this.progressShow = true
          this.editedTask.mode = this.editedTask.mode ? "on" : "off"

          let token = localStorage.getItem("token")
          const tableJSON = JSON.stringify(this.editedTask)

          let api = process.env.VUE_APP_BASE_URL + "/user/update_task"
          const res = await fetch(api, ApiServices.requestOptions("PUT", tableJSON, token))
          const jsonObject = await res.json()

          if (res.status >= 400) {
            this.showSnackBar("שגיאה בעדכון המשימה" + jsonObject.message, "red")
          } else if (res.status === 200) {
            this.$emit("taskUpdated", this.editedTask, "המשימה עודכנה בהצלחה!", "green")
          }
        } catch (error) {
          this.showSnackBar("Error update task: " + error, "red")
        } finally {
          this.progressShow = false
        }
      }
    },
    async deleteTask() {
      try {
        this.progressShow = true
        let token = localStorage.getItem("token")
        const tableJSON = JSON.stringify(this.editedTask)

        let api = process.env.VUE_APP_BASE_URL + "/user/delete_task"
        const res = await fetch(api, ApiServices.requestOptions("DELETE", tableJSON, token))
        const jsonObject = await res.json()

        if (res.status >= 400) {
          this.showSnackBar("שגיאה במחיקת המשימה" + jsonObject.message, "red")
        } else if (res.status === 200) {
          this.$emit("taskUpdated", this.editedTask, "המשימה נמחקה בהצלחה!", "green")
        }
      } catch (error) {
        this.showSnackBar("Error update task: " + error, "red")
      } finally {
        this.progressShow = false
      }
    },
    async copyTable() {
      try {
        this.progressShow = true
        let token = localStorage.getItem("token")
        const tableJSON = JSON.stringify({ tableName: this.tableName, fields: this.fields })

        let api = process.env.VUE_APP_BASE_URL + "/user/copy_table"
        const res = await fetch(api, ApiServices.requestOptions("POST", tableJSON, token))
        const jsonObject = await res.json()

        if (res.status >= 400) {
          this.showSnackBar("שגיאה בשכפול הטבלה" + jsonObject.message, "red")
        } else if (res.status === 200) {
          this.showSnackBar("הטבלה שוכפלה בהצלחה!", "green")
          this.tables.push({
            name: jsonObject.name,
            hebrew_name: jsonObject.hebrew_name,
            date_created: jsonObject.date_created
          })
          this.$emit("tableCopied")
        }
      } catch (error) {
        this.showSnackBar("Error update task: " + error, "red")
      } finally {
        this.progressShow = false
      }
    },
    showSnackBar(message, color) {
      this.snacbarText = message
      this.snackbarColorBt = color
      this.snackbar = true
    },
    chooseAction(item) {
      this.selectedItemAction = item.id
      this.editedTask.action = item.name
    }
  },
  mounted() {
    let auth = Auth.checkAuth()
    if (!auth) {
      this.$router.replace({ name: "login" })
    }
    this.editedTask = { ...this.taskToEdit }
    this.fields = this.$store.state.fields
    this.tables = this.$store.state.tables
    this.actions.forEach((actionItem) => {
      if (this.editedTask.action === actionItem.name) {
        this.selectedItemAction = actionItem.id
      }
    })
    this.editedTask.mode = this.editedTask.mode === "on" || this.editedTask.mode === true
  },
}
</script>

<style scoped>
.task-settings-container {
  background: linear-gradient(135deg, #f8fafc 0%, #f0f7ff 100%);
  min-height: 100vh;
  padding: 2rem 1rem;
}

.settings-card {
  border-radius: 16px;
  overflow: hidden;
  background: #ffffff;
  box-shadow: 0 4px 24px rgba(13, 44, 109, 0.08) !important;
}

.header-section {
  padding: 1.5rem 2rem;
  background: linear-gradient(to right, #f8fafc, #ffffff);
  display: flex;
  align-items: center;
  gap: 1rem;
}

.back-button {
  background: rgba(13, 44, 109, 0.05);
  transition: all 0.3s ease;
}

.back-button:hover {
  background: rgba(13, 44, 109, 0.1);
  transform: translateX(2px);
}

.header-title {
  color: #1a237e;
  font-size: 1.75rem;
  font-weight: 600;
  margin: 0;
  font-family: 'Rubik', sans-serif;
}

.content-section {
  padding: 2rem;
}

.section-wrapper {
  margin-bottom: 2rem;
}

.section-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #1a237e;
  margin-bottom: 1rem;
  font-family: 'Rubik', sans-serif;
}

.settings-grid {
  margin: 0 -1rem;
}

.settings-column {
  padding: 1rem;
}

.actions-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.action-card {
  padding: 1rem;
  border: 1px solid rgba(13, 44, 109, 0.1);
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  background: #ffffff;
}

.action-card.action-selected {
  background: linear-gradient(135deg, #1a237e 0%, #3949ab 100%);
  border-color: transparent;
}

.action-card.action-selected .action-text {
  color: white;
}

.action-card.action-hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(13, 44, 109, 0.08);
}

.action-icon {
  font-size: 1.5rem;
}

.action-text {
  font-size: 1rem;
  font-weight: 500;
  color: #1a237e;
}

.additional-fields {
  margin-top: 1.5rem;
}

.status-section {
  margin: 2rem 0;
  padding: 1rem;
  background: rgba(13, 44, 109, 0.02);
  border-radius: 12px;
}



.status-label {
  font-size: 1rem;
  font-weight: 500;
  color: #64748b;
}

.actions-section {
  display: flex;
  gap: 1rem;
  margin-top: 2rem;
}

/* Buttons */
.save-button {
  background: linear-gradient(135deg, #0d2c6d 0%, #1a4ba5 100%) !important;
  color: white !important;
  padding: 0 1.5rem !important;
  height: 40px;
  text-transform: none;
  letter-spacing: 0.5px;
  font-size: 0.9375rem;
  font-weight: 500;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.save-button:hover:not(:disabled) {
  opacity: 0.95;
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(13, 44, 109, 0.2) !important;
}

.delete-button {
  background: rgba(239, 68, 68, 0.1) !important;
  color: #ef4444 !important;
  padding: 0 1.5rem !important;
  height: 40px;
  text-transform: none;
  letter-spacing: 0.5px;
  font-size: 0.9375rem;
  font-weight: 500;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.delete-button:hover:not(:disabled) {
  background: rgba(239, 68, 68, 0.15) !important;
  transform: translateY(-1px);
}

.copy-table-btn {
  width: 100%;
  text-transform: none;
  letter-spacing: 0;
}

/* RTL Support */
.task-settings-container {
  direction: rtl;
}

.action-card {
  text-align: right;
}


.v-input {
  text-align: right;
}

.v-label {
  right: 0;
  left: auto;
}

.v-text-field__prefix {
  padding-left: 0;
  padding-right: 4px;
}

.v-text-field__suffix {
  padding-right: 0;
  padding-left: 4px;
}
</style>