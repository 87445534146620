<template>
  <v-container class="item-details-container" style="margin-top: 50px;">
    <v-card class="item-card elevation-3">
      <!-- Header Section -->
      <div class="header-section">
        <v-btn @click="backToTableData()" class="back-button" fab small elevation="2">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" :color="$store.state.colorPrimary">
                mdi-arrow-right
              </v-icon>
            </template>
            <span>חזור לטבלה</span>
          </v-tooltip>
        </v-btn>
        <h1 class="page-title" :style="{ color: $store.state.colorPrimary }">
          פרטי הרשומה
        </h1>
      </div>

      <v-divider></v-divider>

      <!-- Loading State -->
      <v-card-text>
        <v-row class="my-5" v-if="isLoading">
          <v-col class="d-flex justify-center align-center" style="min-height: 300px">
            <v-progress-circular indeterminate :color="$store.state.colorPrimary" size="64"></v-progress-circular>
          </v-col>
        </v-row>

        <!-- Content -->
        <v-row v-else class="details-grid">
          <v-col v-for="(field, index) in $store.state.fields" :key="index" cols="12" md="6" class="field-column">
            <div class="field-container" :class="{ 'field-hovered': hoveredField === field.name }"
              @mouseover="hoveredField = field.name" @mouseleave="hoveredField = null">
              <!-- Field Label -->
              <div class="field-label">
                <v-icon small :color="$store.state.colorPrimary" class="field-icon">
                  {{ getFieldIcon(field.type) }}
                </v-icon>
                <span class="label-text">{{ field.hebrew_name }}</span>
              </div>

              <!-- Field Value -->
              <div >
                <!-- View Mode -->
                <v-row >
                  <v-col cols="12" sm="10" v-if="editingField !== field.name" class="field-content">
                    <!-- Date Fields -->
                    <span v-if="field.name === 'date_created' || field.name === 'date_modified'" class="date-value">
                      {{ formatDate(itemDetails[field.name]) }}
                    </span>

                    <!-- Regular Fields -->
                    <span v-else-if="field.type !== 'לינק'" class="text-value">
                      {{ itemDetails[field.name] }}
                    </span>

                    <!-- Link Fields -->
                    <div v-else-if="itemDetails[field.name]" class="link-actions">
                      <!-- Audio Player -->
                      <v-menu v-if="isAudio(itemDetails[field.name])" v-model="isMenuOpen[index]" offset-y
                        :close-on-content-click="false">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn class="mr-2" small outlined :color="$store.state.colorPrimary" v-bind="attrs"
                            v-on="on">
                            <v-icon left small>mdi-play</v-icon>
                            נגן
                          </v-btn>
                        </template>

                        <v-card class="audio-player-card">
                          <audio controls :src="itemDetails[field.name]" class="audio-player">
                            <source :src="itemDetails[field.name]" type="audio/wav" />
                          </audio>
                        </v-card>
                      </v-menu>

                      <!-- Download Button -->
                      <v-btn small outlined :color="$store.state.colorPrimary" :href="itemDetails[field.name]"
                        :download="fileNameFromUrl(itemDetails[field.name])">
                        <v-icon left small>mdi-download</v-icon>
                        הורד
                      </v-btn>
                    </div>
                  </v-col>

                  <!-- Edit Mode -->
                  <v-col cols="12" v-else class="edit-field-container" style="width: 100%;">
                    <div class="edit-input">
                      <!-- Text/Number Input -->
                      <v-text-field v-if="field.type !== 'רשימה' && field.type !== 'רשימה מקושרת'"
                        class="overflow-wrapper" v-model="itemDetails[field.name]" :type="getFieldType(field)" dense
                        outlined hide-spin-buttons @keydown.enter="saveEdit(key)" autofocus></v-text-field>

                      <!-- Regular List -->
                      <v-autocomplete v-else-if="field.type === 'רשימה'" v-model="itemDetails[field.name]"
                        :items="field.list" dense outlined @keydown.enter="saveEdit(key)"
                        :menu-props="{ bottom: true, offsetY: true }"></v-autocomplete>

                      <!-- Linked List -->
                      <v-autocomplete v-else v-model="itemDetails[field.name]" :items="fieldsSourceList"
                        item-value="name" item-text="hebrew_name" dense outlined
                        @mouseover="getSourceList(field.linkedList)" @keydown.enter="saveEdit(key)"
                        :menu-props="{ bottom: true, offsetY: true }"></v-autocomplete>
                    </div>

                    <!-- Edit Actions -->
                    <div class="edit-actions">
                      <v-btn small text color="error" @click="cancelEdit" class="action-button">
                        <v-icon left small>mdi-close</v-icon>
                        ביטול
                      </v-btn>
                      <v-btn small color="success" @click="saveEdit(key)" class="action-button" :loading="isSaving">
                        <v-icon left small>mdi-check</v-icon>
                        שמור
                      </v-btn>
                    </div>
                  </v-col>

                  <!-- Action Buttons -->
                  <v-col cols="12" sm="2" v-if="editingField !== field.name && hoveredField === field.name" class="field-actions">
                    <v-btn icon x-small class="action-btn" @click.stop="copyToClipboard(itemDetails[field.name])">
                      <v-icon small>mdi-content-copy</v-icon>
                    </v-btn>
                    <v-btn v-if="canEdit(field)" icon x-small class="action-btn"
                      @click.stop="startEditing(field.name, itemDetails[field.name])">
                      <v-icon small>mdi-pencil</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>
  </v-container>
</template>


<script>
import SnackBar from '@/components/widgets/snackBar.vue'
import ApiServices from '@/services/api.service'
import Utils from '@/util/utils'
import Auth from '@/services/auth.service.js'

export default {
  name: "DynamicItemDetails",
  components: {
    SnackBar,
  },
  props: {
    itemId: {
      type: String,
      required: true,
    },
    tableName: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      editingField: null,
      hoveredField: null,
      tempValue: "",
      itemDetails: {},
      snackbar: false,
      snackbarColorBt: "green",
      snacbarText: "",
      fieldsSourceList: [],
      isMenuOpen: [], // Initially, the menu is closed
      permission: Auth.getUserPermission(),
      isLoading: false
    };
  },
  methods: {
    getFieldIcon(type) {
      const icons = {
        'מספר': 'mdi-numeric',
        'טקסט': 'mdi-form-textbox',
        'לינק': 'mdi-link',
        'תאריך': 'mdi-calendar',
        'תאריך ושעה': 'mdi-calendar-clock',
        'רשימה': 'mdi-format-list-bulleted',
        'רשימה מקושרת': 'mdi-format-list-bulleted-type'
      }
      return icons[type] || 'mdi-text'
    },
    canEdit(field) {
      return field.name !== 'date_created' &&
        field.name !== 'date_modified' &&
        field.name !== 'id' &&
        this.permission !== 'view'
    },
    formatKey(key) {
      const field = this.$store.state.fields.find(f => f.name === key)
      return field ? field.hebrew_name : key
    },
    getKeyDetails(key) {
      const field = this.$store.state.fields.find(f => f.name === key)
      return field ? field.type !== 'רשימה' && field.type !== 'רשימה מקושרת' : true
    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(
        () => {
          this.showSnackBar("הועתק " + text, "green")
        },
        (err) => {
          console.error("Failed to copy text: ", err)
        }
      )
    },
    startEditing(key, value) {
      this.editingField = key
      this.tempValue = value
    },
    saveEdit(key) {
      this.$set(this.itemDetails, key, this.tempValue)
      this.editingField = null
      this.saveEditedItem()
    },
    cancelEdit() {
      this.editingField = null
    },
    backToTableData() {
      this.$emit('backToTableData', this.tableName)
    },
    showSnackBar(message, color) {
      this.snacbarText = message
      this.snackbarColorBt = color
      this.snackbar = true
    },
    async getTItemAndTableFields() {
      this.progressShow = true;
      let token = localStorage.getItem("token");
      try {
        let api = process.env.VUE_APP_BASE_URL + "/user/get_table_properties?tableName=" + this.tableName.name;
        const res = await fetch(api, ApiServices.requestOptions("GET", "", token));
        this.progressShow = false;
        const jsonObject = await res.json();
        if (res.status >= 400) {
          this.showSnackBar("שגיאה: " + jsonObject.message, "red");
        } else if (res.status === 200) {
          this.$store.state.fields = jsonObject.table_fields
          this.getItemDetails();
        }
      } catch (error) {
        this.progressShow = false;
        this.showSnackBar("Error saved list: " + error, "red");
      }
    },
    async getItemDetails() {
      this.progressShow = true;
      let token = localStorage.getItem("token");
      try {
        let api = process.env.VUE_APP_BASE_URL + "/user/get_item?tableName=" + this.tableName.name + "&itemId=" + this.itemId;
        const res = await fetch(api, ApiServices.requestOptions("GET", "", token));
        const jsonObject = await res.json();
        if (res.status >= 400) {
          this.isLoading = false;
          this.showSnackBar("שגיאה בקבלת רשומה: " + jsonObject.message, "red");
        } else if (res.status === 200) {
          this.itemDetails = jsonObject;
          let date = Utils.getCurrentDate();
          this.itemDetails.date_modified = date;
          this.isLoading = false;
        }
      } catch (error) {
        this.isLoading = false;
        this.showSnackBar("Error saved list: " + error, "red");
      }

    },
    async saveEditedItem() {

      const driversJSON = JSON.stringify({ tableName: this.tableName, item: this.itemDetails });
      this.progressShow = true;
      let token = localStorage.getItem("token");

      try {
        let api = process.env.VUE_APP_BASE_URL + "/user/update_item";
        const res = await fetch(api, ApiServices.requestOptions("PUT", driversJSON, token));
        this.progressShow = false;
        const jsonObject = await res.json();
        if (res.status >= 400) {
          this.showSnackBar("שגיאה בעדכון רשומה: " + jsonObject.message, "red");
        } else if (res.status === 200) {
          let date = Utils.getCurrentDate();
          this.itemDetails.date_modified = date;
          this.showSnackBar("הרשומה עודכנה בהצלחה", "green");
        }
      } catch (error) {
        this.progressShow = false;
        this.showSnackBar("Error saved list: " + error, "red");
      }

    },
    getFieldType(field) {
      if (field.type === 'מספר') {
        return 'number';
      } else if (field.type === 'טקסט' || field.type === 'לינק') {
        return 'text';
      } else if (field.type === 'תאריך') {
        return 'date';
      } else {
        return 'datetime-local';
      }
    },
    async getSourceList(linkedList) {
      try {
        this.fieldsSourceList = [];
        let token = localStorage.getItem("token");
        const tableJSON = JSON.stringify({ tableName: linkedList.tableSource, fieldName: linkedList.fieldSource });
        let api = process.env.VUE_APP_BASE_URL + "/user/get_field_data_linked_list";
        const res = await fetch(api, ApiServices.requestOptions("POST", tableJSON, token));
        const jsonObject = await res.json();
        if (res.status >= 400) {
          this.showSnackBar(" שגיאה בקבלת נתונים" + jsonObject.message, "red");
        } else if (res.status === 200) {
          this.fieldsSourceList = jsonObject;
        }
      } catch (error) {
        this.showSnackBar("Error get items list: " + error, "red");
      }
    },
    isAudio(link) {
      try {
        // Extract the file extension
        const parts = link.split('.');
        const extension = parts.length > 1 ? parts[parts.length - 1].toLowerCase() : null;
        // If there's no extension, return true, can be clickcall audio
        if (extension.length > 4) {
          return true;
        }
        //it probably extension, Check if the extension matches any of the audio formats
        return ['mp3', 'ogg', 'wav'].includes(extension);
      } catch (e) {
        console.log(e);
      }
    },
    fileNameFromUrl(src) {
      // Splits the URL by '/' and then takes the last element
      return src.split('/').pop();
    },
    toggleMenu(index) {
      this.$set(this.isMenuOpen, index, !this.isMenuOpen[index]); // Toggle the menu state using Vue's $set method
    },
    formatDate(date) {
      if (!date) {
        return "";
      }
      return Utils.getPrettyDate(date);
    }
  },
  computed: {
  },
  mounted() {
    console.log(33333);
    this.isLoading = true;
    if (this.$store.state.fields.length === 0) {
      this.getTItemAndTableFields();
    } else {
      this.getItemDetails();
    }
  },
};
</script>

<style scoped>
.item-details-container {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.item-card {
  border-radius: 12px;
  overflow: hidden;
  transition: all 0.3s ease;
}

.header-section {
  padding: 1.5rem;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.back-button {
  transition: transform 0.2s ease;
}

.back-button:hover {
  transform: translateX(3px);
}

.page-title {
  font-size: 1.75rem;
  font-weight: 600;
  margin: 0;
}

.details-grid {
  padding: 1rem;
}

.field-column {
  padding: 0.75rem;
}

.field-container {
  background: #f8f9fa;
  border-radius: 8px;
  padding: 1rem;
  transition: all 0.2s ease;
  border: 1px solid transparent;
}

.field-container:hover {
  background: #ffffff;
  border-color: #e2e8f0;
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.field-label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
}

.field-icon {
  opacity: 0.7;
}

.label-text {
  font-weight: 500;
  color: #64748b;
  font-size: 0.9rem;
}

.field-value-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 2rem;
}

.field-content {
  flex-grow: 1;
}

.text-value {
  color: #1e293b;
  font-size: 1rem;
  display: inline-block;     /* allows width control */
  max-width: 100%;           /* restrict to parent's width */
  overflow: hidden;          /* hides overflowing text */
  text-overflow: ellipsis;   /* adds "..." at the end */
  white-space: nowrap;       /* prevents wrapping to new line */
}

.date-value {
  color: #64748b;
  font-size: 0.9rem;
}

.link-actions {
  display: flex;
  gap: 0.5rem;
}

.field-actions {
  justify-content: end;
  display: flex;
  gap: 0.25rem;
  opacity: 0;
  transition: opacity 0.2s ease;
}

.field-container:hover .field-actions {
  opacity: 1;
}

.action-btn {
  transition: all 0.2s ease;
}

.action-btn:hover {
  background-color: #f1f5f9;
}

.audio-player-card {
  padding: 1rem;
  min-width: 300px;
}

.audio-player {
  width: 100%;
}

.edit-field {
  flex-grow: 1;
  margin-right: 1rem;
}

/* RTL Specific Styles */
:dir(rtl) {
  text-align: right;
}

.v-text-field>>>.v-input__prepend-outer {
  margin-right: 0;
  margin-left: 9px;
}

.v-btn>>>.v-icon {
  margin-right: 0;
  margin-left: 8px;
}

/* Responsive Design */
@media (max-width: 600px) {
  .header-section {
    padding: 1rem;
  }

  .page-title {
    font-size: 1.5rem;
  }

  .field-container {
    padding: 0.75rem;
  }

  .field-actions {
    opacity: 1;
  }
}


.overflow-wrapper >>> .v-input__slot input {
  overflow: hidden;
  /* or overflow: auto; if scrolling is needed */
  text-overflow: ellipsis;
  /* for ellipsis (...) at end of overflowing text */
  white-space: nowrap;
  /* prevent line break inside text input */
}
</style>