<template>
    <v-card elevation="3" class="edit-card">
        <!-- Header Section -->
        <v-card-title class="edit-header text-center d-block pa-6">
            <h1 class="text-h4 font-weight-bold primary--text">עריכת פרטי משתמש</h1>
            <v-divider class="mx-4 my-3"></v-divider>
        </v-card-title>

        <!-- Edit Form -->
        <v-card-text class="pa-6">
            <v-form @submit.prevent="saveChanges" ref="form" v-model="valid">
                <v-row>
                    <v-col cols="12" md="6" v-for="field in editableFields" :key="field.key">
                        <v-card outlined class="pa-4">
                            <div class="d-flex align-center mb-4">
                                <v-icon color="primary" class="ml-2">{{ field.icon }}</v-icon>
                                <span class="text-subtitle-1 font-weight-medium primary--text">{{ field.label }}</span>
                            </div>

                            <v-text-field v-model="userDetailsEdited[field.key]" :rules="field.rules"
                                :label="field.label" :hint="field.hint" :type="field.type || 'text'" outlined dense
                                hide-details="auto" class="mt-2" dir="rtl"></v-text-field>
                        </v-card>
                    </v-col>
                </v-row>

                <!-- Action Buttons -->
                <v-card-actions class="mt-6 px-0">
                    <v-spacer></v-spacer>
                    <v-btn color="grey darken-1" text large class="ml-4" @click="cancelChanges"
                        :disabled="progressShow">
                        <v-icon left>mdi-close</v-icon>
                        ביטול
                    </v-btn>
                    <v-btn color="primary" large @click="saveChanges" :loading="progressShow"
                        :disabled="!valid || progressShow">
                        <v-icon left>mdi-content-save</v-icon>
                        שמור שינויים
                    </v-btn>
                </v-card-actions>
            </v-form>
        </v-card-text>

        <!-- Loading Overlay -->
        <v-overlay :value="progressShow" absolute>
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </v-overlay>

        <!-- Snackbar -->
        <v-snackbar v-model="snackbar" :color="snackbarColorBt" :timeout="3000" top right>
            {{ snacbarText }}
            <template v-slot:action="{ attrs }">
                <v-btn text v-bind="attrs" @click="snackbar = false">
                    סגור
                </v-btn>
            </template>
        </v-snackbar>
    </v-card>
</template>

<script>
import ApiServices from '@/services/api.service'

export default {
    name: 'UserAccountEdit',
    props: {
        userDetails: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            valid: false,
            progressShow: false,
            snackbar: false,
            snackbarColorBt: "success",
            snacbarText: "",
            userDetailsEdited: {
                name: "",
                phone: "",
                email: ""
            },
            editableFields: [
                {
                    key: 'name',
                    label: 'שם',
                    icon: 'mdi-account',
                    rules: [v => !!v || 'שדה חובה'],
                    hint: 'הזן את שמך המלא'
                },
                {
                    key: 'phone',
                    label: 'פלאפון',
                    icon: 'mdi-phone',
                    rules: [
                        v => !!v || 'שדה חובה',
                        v => /^[0-9-+\s()]*$/.test(v) || 'מספר טלפון לא תקין'
                    ],
                    type: 'tel',
                    hint: 'הזן מספר טלפון תקין'
                },
                {
                    key: 'email',
                    label: 'אימייל',
                    icon: 'mdi-email',
                    rules: [
                        v => !!v || 'שדה חובה',
                        v => /.+@.+\..+/.test(v) || 'כתובת אימייל לא תקינה'
                    ],
                    type: 'email',
                    hint: 'הזן כתובת אימייל תקינה'
                }
            ]
        }
    },
    methods: {
        async saveChanges() {
            if (this.$refs.form.validate()) {
                try {
                    this.progressShow = true
                    const editedJSON = JSON.stringify(this.userDetailsEdited)
                    let token = localStorage.getItem("token")
                    let api = process.env.VUE_APP_BASE_URL + "/user/update_user_details"

                    const res = await fetch(api, ApiServices.requestOptions("PUT", editedJSON, token))
                    const jsonObject = await res.json()

                    if (res.status >= 400) {
                        this.showSnackBar(jsonObject.message, "error")
                    } else if (res.status === 200) {
                        this.$emit("Edited", this.userDetailsEdited)
                    }
                } catch (error) {
                    this.showSnackBar("שגיאה בשמירת הנתונים: " + error, "error")
                } finally {
                    this.progressShow = false
                }
            }
        },
        cancelChanges() {
            this.$emit("Cancle")
        },
        showSnackBar(message, color) {
            this.snacbarText = message
            this.snackbarColorBt = color
            this.snackbar = true
        }
    },
    mounted() {
        this.userDetailsEdited = { ...this.userDetails }
    }
}
</script>

<style lang="scss" scoped>
.edit-card {
    border-radius: 8px;
    overflow: hidden;
}

.edit-header {
    background: linear-gradient(to left, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95));
}

// RTL specific styles
:deep(.v-text-field) {
    text-align: right;

    .v-label {
        right: 0;
        left: auto;
    }

    .v-input__prepend-inner {
        margin-right: 0;
        margin-left: 8px;
    }
}

// Transitions
.v-enter-active,
.v-leave-active {
    transition: opacity 0.3s ease;
}

.v-enter,
.v-leave-to {
    opacity: 0;
}
</style>