<template>
  <div>
    <nav-bar></nav-bar>
    <v-container class="account-container" fluid>
      <v-row justify="center" align="center">
        <v-col cols="12" sm="11" md="10" lg="8">
          <transition name="fade" mode="out-in">
            <SellerAccountEdit v-if="editChoose" :userDetails="details" @Edited="edited" @Cancle="editChoose = false" />
            <SellerAccount v-else @Edit="openEdit" />
          </transition>
        </v-col>
      </v-row>
    </v-container>

    <v-snackbar v-model="snackbar" :color="snackbarColorBt" :timeout="3000" top right>
      {{ snacbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">
          סגור
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import SellerAccount from '@/components/user/accountPage/UserAccount.vue'
import SellerAccountEdit from '@/components/user/accountPage/UserAccountEdit.vue'
import NavBar from '@/components/widgets/navBar.vue'
import Auth from '@/services/auth.service.js'

export default {
  name: 'AccountView',
  components: {
    SellerAccount,
    SellerAccountEdit,
    NavBar
  },
  data() {
    return {
      editChoose: false,
      snackbar: false,
      snackbarColorBt: "success",
      snacbarText: "",
      details: {},
    }
  },
  methods: {
    openEdit(details) {
      this.details = details
      this.editChoose = true
    },
    edited(details) {
      this.editChoose = false
      this.showSnackBar("הפרטים עודכנו בהצלחה!", "success")
      this.details = details
    },
    showSnackBar(message, color) {
      this.snacbarText = message
      this.snackbarColorBt = color
      this.snackbar = true
    }
  },
  mounted() {
    document.title = 'החשבון שלי | CLICKCALL'

    let auth = Auth.checkAuth()
    if (!auth) {
      this.$router.replace({ name: "login" })
    }
  }
}
</script>

<style lang="scss" scoped>
.account-container {
  min-height: calc(100vh - 64px);
  padding-top: 64px;
  background-color: #f5f5f5;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>