<template>
  <v-container style="margin-top: 40px;">
    <v-card elevation="0" class="table-header">
      <!-- Table Title Section -->
      <div class="d-flex justify-space-between align-center mb-6">
        <h1 class="text-h4 font-weight-bold" style="color: #0d2c6d;">
          {{ tableName.hebrew_name }}
        </h1>

        <!-- Admin Actions -->
        <div v-if="permission === 'edit'" class="admin-actions d-flex">
          <v-speed-dial v-model="fab" direction="right" transition="slide-x-reverse-transition" open-on-hover>
            <template v-slot:activator>
              <v-btn v-model="fab" color="#0d2c6d" dark small fab>
                <v-icon v-if="fab">mdi-close</v-icon>
                <v-icon v-else>mdi-cog</v-icon>
              </v-btn>
            </template>

            <v-btn fab dark small color="primary" @click="openEditTablePage">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">mdi-table</v-icon>
                </template>
                <span>מאפייני טבלה</span>
              </v-tooltip>
            </v-btn>

            <v-btn fab dark small color="primary" @click="openTaskSchedulePage">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">mdi-calendar-clock-outline</v-icon>
                </template>
                <span>תזמון משימות</span>
              </v-tooltip>
            </v-btn>

            <v-btn fab dark small color="primary" @click="openCrmIntigrationPage">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">mdi-puzzle-outline</v-icon>
                </template>
                <span>אינטיגרציה ל CRM</span>
              </v-tooltip>
            </v-btn>

            <v-btn fab dark small color="error" @click="deleteTableDialog = true">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">mdi-delete-outline</v-icon>
                </template>
                <span>מחק טבלה</span>
              </v-tooltip>
            </v-btn>
          </v-speed-dial>
        </div>
      </div>

      <v-divider class="mb-6"></v-divider>

      <!-- Table Actions -->
      <v-card elevation="0" class="table-actions pa-4 rounded-lg">
        <div class="d-flex flex-wrap align-center justify-space-between">
          <!-- Left Side Actions -->
          <div class="d-flex flex-wrap align-center">
            <v-btn v-if="FieldsNotEmpty && permission !== 'view'" color="primary" class="ml-2 mb-2" medium
              @click="openCreateDIalog">
              <v-icon left>mdi-plus</v-icon>
              צור רשומה חדשה
            </v-btn>

            <!-- Import Menu -->
            <v-menu v-if="items.length > 0 && permission !== 'view'" offset-y :close-on-content-click="false">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="info" class="action-button ml-2 mb-2" medium v-bind="attrs" v-on="on">
                  <v-icon left small>mdi-file-upload-outline</v-icon>
                  אפשרויות ייבוא וייצוא
                </v-btn>
              </template>

              <v-card class="import-menu pa-4" min-width="300">
                <h3 class="subtitle-1 font-weight-bold mb-4">אפשרויות ייבוא</h3>

                <v-list dense>
                  <v-list-item @click="$refs.fileInput.click()">
                    <v-list-item-icon>
                      <v-icon color="success">mdi-file-excel</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>ייבוא מקובץ Excel</v-list-item-title>
                      <v-list-item-subtitle>תמיכה ב-XLSX ו-XLS</v-list-item-subtitle>
                    </v-list-item-content>
                    <!-- Hidden File Input -->
                    <input type="file" ref="fileInput" accept=".xls, .xlsx" style="display: none"
                      @change="handleFileUpload" />
                  </v-list-item>
                  <v-list-item @click="downloadExcel">
                    <v-list-item-icon>
                      <v-icon color="success">mdi-file-excel</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>ייצוא לקובץ XLSX</v-list-item-title>
                      <v-list-item-subtitle>קובץ ערכים מופרדים בפסיקים</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>

                <v-divider class="my-3"></v-divider>

                <div class="text-caption grey--text text--darken-1 " style="cursor: pointer;"
                  @click="downloadExapmleExcel">
                  <v-icon small class="mr-1">mdi-information-outline</v-icon>
                  לחץ להורדת תבנית דוגמה
                </div>
              </v-card>
            </v-menu>


            <v-btn color="grey lighten-1" class="ml-2 mb-2" medium @click="refreshDetails">
              <v-icon left small>mdi-refresh</v-icon>
              רענן
            </v-btn>

            <v-btn color="grey lighten-1" class="ml-2 mb-2" medium @click="extendTable">
              <v-icon left small>
                {{ settings.expended_rows ? 'mdi-arrow-collapse-horizontal' : 'mdi-arrow-expand-horizontal' }}
              </v-icon>
              {{ settings.expended_rows ? 'צמצם שורות' : 'הרחב שורות' }}
            </v-btn>

            <v-btn v-if="selected.length > 0" color="error" class="ml-2 mb-2" small @click="openDeleteDialog(selected)">
              <v-icon left small>mdi-delete</v-icon>
              מחק בחירה
            </v-btn>
          </div>

          <!-- Right Side Actions -->
          <div class="d-flex align-center flex-wrap">
            <div class="records-count grey--text text--darken-1 ml-4">
              סה"כ רשומות: {{ totalItemsLocal ? totalItemsLocal : totalItems }}
            </div>

            <v-text-field v-model="search" append-icon="mdi-magnify" label="חיפוש" hide-details dense outlined clearable
              class="search-field ml-4" style="max-width: 300px" @input="handleSearchInput"></v-text-field>
          </div>
        </div>
      </v-card>
    </v-card>


    <v-data-table id="table1" :headers="visibleHeaders" :items="items" item-key="id" :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc" @update:sort-by="onSortChange" @update:sort-desc="onSortChange"
      :items-per-page="itemsPerPage" @update:items-per-page="onItemsPerPageChange" :page.sync="page"
      :server-items-length="totalItems" @update:page="onPageChange" color="red"
      style="overflow-y: auto; max-height: 84vh;" class="elevation-0" :single-select="singleSelect" :class="{ resizable: !settings.expended_rows }"
      :show-select="settings.multiple_selection && permission !== 'view'" v-model="selected"
      :header-props="{ style: 'background-color: #ff0000' }" :footer-props="{
        'items-per-page-options': [20, 50, 100, 500], 'items-per-page-text': 'שורות בעמוד:', showFirstLastPage: true,
        firstIcon: 'mdi-chevron-double-left',
        lastIcon: 'mdi-chevron-double-right',
        prevIcon: 'mdi-chevron-left',
        nextIcon: 'mdi-chevron-right'
      }">

      <!-- add coin icon to headers -->
      <template v-for="header in headers" v-slot:[`header.${header.value}`]="{ header }">
        <v-icon v-if="!header.coin" :size="settings.expended_rows ? 'medium' : 'small'" style="margin-left: 8px;">
          {{ getHeaderIcon(header.type) }}</v-icon>
        <span style="color: #0d2c6d;"
          :style="settings.expended_rows ? getColumnHeaderStyle(header.value) : setColumnsHeaderWithoutStyle(header.value)">
          {{ header.text }} {{ header.coin ? ' ' + header.coin : "" }}</span>
      </template>

      <template v-for="header in headers" v-slot:[`item.${header.value}`]="{ item, index }">
        <div :style="{
          display: settings.show_copy_icon ? 'flex' : null,
          alignItems: settings.show_copy_icon ? 'center' : null,
          ...settings.expended_rows ? getColumnStyle(header.value) : setColumnsWithoutStyle(header.value)
        }">
          <!-- original values values -->
          <v-btn v-if="item[header.value] && settings.show_copy_icon" icon small class="ml-1"
            @click="copyToClipboard(item[header.value])">
            <v-icon color="rgba(0, 0, 0, 0.3)" small>mdi-content-copy</v-icon>
          </v-btn>
          <span
            v-if="(header.type !== 'לינק' && !header.coin) || (item[header.value] === '' && !header.coin) || (tableExported === true && !header.coin)">
            <!-- this is regular field -->
            {{ item[header.value] }}
          </span>
          <!-- add coin icon to rows -->
          <span v-else-if="header.coin">{{ item[header.value] }} {{ header.coin }} </span>
          <!-- if type is  link change the values -->
          <div v-else style="display: flex; justify-content: center;">
            <div v-if="item[header.value]">

              <v-menu v-model="isMenuOpen[index]" offset-y persistent :close-on-content-click="false">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn elevation="2" v-if="isAudio(item[header.value])" icon outlined
                    :color="$store.state.colorPrimary" :menu-props="{ bottom: true, offsetY: true }">
                    <v-icon v-on="on" v-bind="attrs" dark>
                      mdi-play
                    </v-icon>
                  </v-btn>
                </template>
                <v-list v-if="isMenuOpen[index]">
                  <v-list-item>
                    <div class="menu-content pa-4">
                      <audio controls playsinline :src="item[header.value]">
                        <source :src="item[header.value]" type="audio/wav" />
                      </audio>
                    </div>
                  </v-list-item>
                </v-list>
              </v-menu>
              <a :href="item[header.value]" :download="fileNameFromUrl(item[header.value])"
                style="margin-inline-start: 10px;">
                <v-btn elevation="2" icon outlined :color="$store.state.colorPrimary">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" dark>
                        mdi-download
                      </v-icon>
                    </template>
                    <span>הורד קובץ</span>
                  </v-tooltip>
                </v-btn>
              </a>


            </div>
          </div>
        </div>
      </template>

      <template v-slot:item.date_created="{ item, index }">
        <div :class="settings.expended_rows ? 'single-line-cell' : ''">
          <v-btn v-if="settings.show_copy_icon" icon small class="ml-1" @click="copyToClipboard(item.date_created)">
            <v-icon color="rgba(0, 0, 0, 0.3)" small>mdi-content-copy</v-icon>
          </v-btn>
          <span style="font-size: small;">{{ formatDate(item.date_created) }}</span>
        </div>
      </template>

      <template v-slot:item.date_modified="{ item, index }">
        <div :class="settings.expended_rows ? 'single-line-cell' : ''">
          <v-btn v-if="settings.show_copy_icon && item.date_modified" icon small class="ml-1"
            @click="copyToClipboard(item.date_modified)">
            <v-icon color="rgba(0, 0, 0, 0.3)" small>mdi-content-copy</v-icon>
          </v-btn>
          <span style="font-size: small;">{{ formatDate(item.date_modified) }}</span>
        </div>
      </template>

      <template v-slot:item.index="{ item, index }">
        <span @click="openItemPage(item, index)" style="color: blue; cursor: pointer;">{{ (page - 1) * itemsPerPage + index + 1 }}</span>
      </template>

      <template v-slot:no-data>
        <div class="no-data-state">
          <v-icon size="48" color="grey lighten-1">mdi-database-off-outline</v-icon>
          <h3 class="mt-4 mb-2">לא נמצאו נתונים</h3>
          <v-btn color="primary" @click="refreshDetails" class="mt-2">
            <v-icon left>mdi-refresh</v-icon>
            רענן
          </v-btn>
        </div>
      </template>

      <template v-slot:item.actions="{ item, index }">
        <div v-if="selected.length <= 0" :class="settings.expended_rows ? 'single-line-cell' : ''">
          <v-tooltip bottom v-if="permission !== 'view'">
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" :color="$store.state.colorPrimary" medium class="mr-2 me-3"
                @click="openEditDialog(item, index)">
                mdi-pencil-outline
              </v-icon>
            </template>
            <span>עריכה</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" :color="$store.state.colorPrimary" medium class="mr-2 me-3"
                @click="openItemPage(item, index)">
                mdi-eye-outline
              </v-icon>
            </template>
            <span>צפייה ברשומה</span>
          </v-tooltip>
          <v-tooltip bottom v-if="permission !== 'view'">
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" :color="$store.state.colorPrimary" medium class="me-3" @click="openDeleteDialog(item)">
                mdi-delete-outline
              </v-icon>
            </template>
            <span>מחיקה</span>
          </v-tooltip>
        </div>
      </template>

    </v-data-table>
    <AddEditDialog v-model="addEditDialog" v-if="addEditDialog" :formTitle="formTitle" :itemToEdit="editedItem"
      :fields="headersForEdit" :tableName="tableName" @itemAdded="newItemsaved" @itemEdited="editedItemsaved">
    </AddEditDialog>

    <DeleteItemDialog v-model="alertDialog" v-if="alertDialog" :formTitle="formTitle" :item="itemToDelete"
      @itemDeleted="itemDeleted" :tableName="tableName"> </DeleteItemDialog>

    <DeleteTableDialog v-model="deleteTableDialog" v-if="deleteTableDialog" @tableDeleted="tableDeleted"
      :tableName="tableName"> </DeleteTableDialog>

    <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

    <SnackBarLoading v-model="loading" />

    <ImportResultsDialog v-model="showImportDialog" v-if="showImportDialog" :loading="loading"
      :imported-success="importedSuccess" :imported-failed="importedFailed" @close="closeImportDialog"
      @import="handleImport" />
  </v-container>
</template>

<script>
import ApiServices from '@/services/api.service'
import TableToExcel, { f, isArray } from "@linways/table-to-excel";
import * as XLSX from 'xlsx/xlsx.mjs';
import AddEditDialog from '@/components/user/tableData/dialogs/AddEditDialog'
import DeleteItemDialog from '@/components/user/tableData/dialogs/DeleteItemDialog'
import DeleteTableDialog from '@/components/user/editTable/dialogs/DeleteTableDialog'
import SnackBar from '@/components/widgets/snackBar.vue'
import SnackBarLoading from '@/components/widgets/snackBarLoading.vue'
import Auth from '@/services/auth.service.js'
import _ from 'lodash'; // Import lodash or use another debounce utility
import Utils from '@/util/utils.js'
import axios from 'axios'
import ImportResultsDialog from '@/components/user/tableData/dialogs/ImportResultsDialog.vue'

export default {
  components: {
    AddEditDialog,
    DeleteItemDialog,
    DeleteTableDialog,
    SnackBar,
    SnackBarLoading,
    ImportResultsDialog
  },
  props: {
    tableName: Object,
    value: { type: Boolean, default: false },
  },
  data: () => ({
    isResponsive: false, // Initially set to false
    showImportDialog: false,
    importedSuccess: [],
    importedFailed: [],
    progressSave: false,
    headers: [],
    fab: false,
    headersForEdit: [],
    alertDialog: false,
    addEditDialog: false,
    deleteTableDialog: false,
    formTitle: "",
    items: [],
    loading: false,
    snackbar: false,
    snackbarColorBt: "green",
    snacbarText: "",
    editedItem: {},
    itemToDelete: {},
    userName: "",
    email: "",
    search: "",
    file: null,
    nonAudioExtensions: ['.pdf', '.txt', '.docx', '.xlsx'],
    tableExported: false,
    exportDialog: false,
    tableToExport: null,
    permission: "",
    itemsPerPage: 20,
    page: 1, // Current page
    totalItems: 0, // Total number of customers for pagination controls
    totalItemsLocal: 0, // Total number of local search
    sortBy: "date_created",
    sortDesc: true, // default sort direction
    singleSelect: false,
    selected: [],
    settings: { multiple_selection: true, expended_rows: false },
    isMenuOpen: [], // Initially, the menu is closed
    // showClipboardIcon: [], // Initially, the menu is closed
    audioUrl: '',
  }),
  computed: {
    openMode: {
      get() {
        return this.value
      },
      set(v) {
        return this.$emit('input', v)
      }
    },
    visibleHeaders() {
      return this.headers.filter(header => header.show);
    },
    FieldsNotEmpty() {
      for (const field of this.headersForEdit) {
        if (field.value !== "date_created" && field.value !== "date_modified" && field.value !== "id") {
          return true;
        }
      }
      return false;
    },
    columnWidths() {
      const charWidth = 11; // Approximate width of one character in pixels
      const baseWidth = 40; // Add 20px for padding
      const minWidth = 60; // Minimum width for a column
      const maxWidth = 2000; // Maximum width for a column

      // Calculate the width for each column
      const widths = {};
      this.headers.forEach(header => {
        const columnValues = this.items.map(item => item[header.value]?.toString() || "");
        let longestValueLength = Math.max(...columnValues.map(value => value.length), 0);
        if (header.type === "לינק") {
          longestValueLength = 13;
        }
        if (longestValueLength === 0) {
          longestValueLength = header.value.length;
        }
        const calculatedWidth = Math.min(
          Math.max(longestValueLength * charWidth + baseWidth, minWidth),
          maxWidth
        );
        widths[header.value] = `${calculatedWidth}px`;
      });

      return widths;
    }
  },
  methods: {
    isAudio(link) {
      // Extract the file extension
      const parts = link.split('.');
      const extension = parts.length > 1 ? parts[parts.length - 1].toLowerCase() : null;
      // If there's no extension, return true, can be clickcall audio
      if (extension.length > 4) {
        return true;
      }
      //it probably extension, Check if the extension matches any of the audio formats
      return ['mp3', 'ogg', 'wav'].includes(extension);
    },
    toggleMenu(index) {
      this.$set(this.isMenuOpen, index, !this.isMenuOpen[index]); // Toggle the menu state using Vue's $set method
    },
    getColumnStyle(column) {
      return {
        width: `${this.columnWidths[column]} !important`,
        whiteSpace: "nowrap !important",
        overflow: "hidden !important",
        textOverflow: "ellipsis !important",
      };
    },
    setColumnsWithoutStyle(column) {
      return {
        whiteSpace: "nowrap !important",       // Prevent wrapping of text
        overflow: "hidden !important",        // Ensure both horizontal and vertical overflow are hidden
        textOverflow: "ellipsis !important",  // Add ellipsis for overflowing text
      };
    },
    getColumnHeaderStyle(column) {
      return {
        width: `${this.columnWidths[column]} !important`,
        whiteSpace: "nowrap !important",
        overflow: "hidden !important",
        textOverflow: "ellipsis !important",
        fontSize: "16px !important",
      };
    },
    setColumnsHeaderWithoutStyle() {
      return {
        fontSize: this.headers.length > 8 ? "11px !important" : "13px !important",
        whiteSpace: "nowrap !important",       // Prevent wrapping of text
        overflow: "hidden !important",        // Ensure both horizontal and vertical overflow are hidden
        textOverflow: "ellipsis !important",  // Add ellipsis for overflowing text
      };
    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(
        () => {
          this.showSnackBar("הועתק " + text, "green");
        },
        (err) => {
          console.error("Failed to copy text: ", err);
        }
      );
    },

    async getData() {
      let auth = Auth.checkAuth();
      if (!auth) {
        this.$router.replace({ name: "login" })
      }

      try {
        this.loading = true;
        let token = localStorage.getItem("token");
        const tableJSON = JSON.stringify({ tableName: this.tableName.name, page: this.page, itemsPerPage: this.itemsPerPage, sortBy: this.sortBy, sortDesc: this.sortDesc });

        let api = process.env.VUE_APP_BASE_URL + "/user/get_table_data";
        const res = await fetch(api, ApiServices.requestOptions("POST", tableJSON, token));
        const jsonObject = await res.json();
        if (res.status >= 400) {
          this.showSnackBar(" שגיאה בקבלת נתונים" + jsonObject.message, "red");
        } else if (res.status === 200) {
          if (!this.headers[0]) {
            this.setHeaders(jsonObject.jsonData.table_fields);
            this.$store.state.fields = jsonObject.jsonData.table_fields
          }
          this.items = jsonObject.jsonData.data;
          this.totalItems = jsonObject.totalItems;
          this.settings = jsonObject.jsonData.table_settings;
        }
      } catch (error) {
        this.showSnackBar("Error get items list: " + error, "red");
      }finally {
        this.loading = false;
      }
    },
    async getSearchData(newSearch = false) {
      let auth = Auth.checkAuth();
      if (!auth) {
        this.$router.replace({ name: "login" })
      }

      if (newSearch) {
        this.page = 1
      }
      try {
        this.loading = true;
        let token = localStorage.getItem("token");
        const tableJSON = JSON.stringify({ tableName: this.tableName.name, content: this.search, sortBy: this.sortBy, sortDesc: this.sortDesc, page: this.page, itemsPerPage: this.itemsPerPage, });
        let api = process.env.VUE_APP_BASE_URL + "/user/get_table_search_data";
        const res = await fetch(api, ApiServices.requestOptions("POST", tableJSON, token));
        const jsonObject = await res.json();
        this.loading = false;
        if (res.status >= 400) {
          this.showSnackBar(" שגיאה בקבלת נתונים" + jsonObject.message, "red");
        } else if (res.status === 200) {
          this.items = jsonObject.jsonData.data;
          this.totalItems = jsonObject.totalItems;

        }
      } catch (error) {
        this.loading = false;
        this.showSnackBar("Error get items search list: " + error, "red");
      }
    },
    onPageChange(newPage) {
      this.page = newPage;
      this.refreshDetails();
    },
    onItemsPerPageChange(newItemsPerPage) {
      this.itemsPerPage = newItemsPerPage;
      // No need to call fetchCustomers() if you're using a watcher on itemsPerPage
    },
    setHeaders(headers1) {
      this.headers.push({ align: "center", text: '#', value: "index", show: true, sortable: false })
      for (let i = 0; i < headers1.length; i++) {
        const isCentered = ['id', 'date_created', 'date_modified'].includes(headers1[i].name) || headers1[i].type === 'לינק';
        this.headers.push({ align: isCentered ? "center" : "start", text: headers1[i].hebrew_name, value: headers1[i].name, unique: headers1[i].unique, show: headers1[i].show, type: headers1[i].type, allowNull: headers1[i].allowNull, list: headers1[i].list, linkedList: headers1[i].linkedList, coin: headers1[i].coin, sortable: true })
        this.headersForEdit.push({ align: isCentered ? "center" : "start", text: headers1[i].hebrew_name, value: headers1[i].name, unique: headers1[i].unique, show: headers1[i].show, type: headers1[i].type, allowNull: headers1[i].allowNull, list: headers1[i].list, linkedList: headers1[i].linkedList, coin: headers1[i].coin, sortable: true })
      }
      this.headers.push({ align: "center", text: 'פעולות', value: 'actions', show: true, sortable: false })
    },
    itemsImportedSuccessMessage(text, color) {
      this.showSnackBar(text, color)
      setTimeout(() => {
        this.$emit("refreshData");
      }, 500);

    },
    showSnackBar(message, color) {
      this.snacbarText = message;
      this.snackbarColorBt = color;
      this.snackbar = true
    },
    async downloadExcel() {
      try {
        this.loading = true;
        let token = localStorage.getItem("token");
        let api = `${process.env.VUE_APP_BASE_URL}/user/export_table_to_excel?tableName=${this.tableName.name}&filter=${this.search}&sortBy=${this.sortBy}&sortDesc=${this.sortDesc}`;
        const res = await fetch(api, ApiServices.requestOptions("GET", "", token));
        if (res.status >= 400) {
          this.showSnackBar("שגיאה בקבלת נתונים", "red");
        } else if (res.status === 200) {
          // Handle successful response
          const blob = await res.blob();
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = `${this.tableName.name}.xlsx`; // Suggested file name
          document.body.appendChild(a);
          a.click();
          a.remove();
          window.URL.revokeObjectURL(url);
        }
      } catch (error) {
        this.showSnackBar("Error get items search list: " + error, "red");
      } finally {
        this.loading = false;
      }
    },
    async downloadExapmleExcel() {
      this.loading = true;
      const table = document.getElementById('table1');
      const clonedTable = table.cloneNode(true);

      // Replace the table headers with the English headers in the cloned table
      const tableHeaders = clonedTable.getElementsByTagName('th');
      for (let i = 0; i < this.headers.length; i++) {
        for (let c = 0; c < tableHeaders.length; c++) {
          let header = tableHeaders[c].textContent.trim().toLowerCase(); // Normalize text

          if (header.includes("₪") || header.includes("$") || header.includes("€")) {
            // Remove currency symbols and trim spaces
            header = header.replace(/[₪$€]/g, "").trim().toLowerCase();
          }

          if (header === this.headers[i].text.trim().toLowerCase()) {
            tableHeaders[c].textContent = this.headers[i].value; // Set the English header
            break; // Exit the inner loop once a match is found
          }
        }
      }

      // Remove the first header
      if (tableHeaders[0]) {
        tableHeaders[0].parentNode.removeChild(tableHeaders[0]);
      }

      // Remove the last header
      if (tableHeaders[tableHeaders.length - 1]) {
        tableHeaders[tableHeaders.length - 1].parentNode.removeChild(tableHeaders[tableHeaders.length - 1]);
      }

      // Remove all rows except the first row (headers)
      const tableRows = clonedTable.getElementsByTagName('tr');
      while (tableRows.length > 1) {
        tableRows[1].parentNode.removeChild(tableRows[1]);
      }

      // Download the Excel file from the cloned table
      TableToExcel.convert(clonedTable, {
        name: 'exampleFile.xlsx',
        sheet: {
          name: 'Sheet 1',
        },
      });
      this.loading = false;
    },
    refreshDetails() {
      if (this.search) {
        this.debouncedGetSearchData(false);
      } else {
        this.debouncedGetData();
      }
    },
    extendTable() {
      this.settings.expended_rows = !this.settings.expended_rows;
    },
    openExportDialog() {
      const table = document.getElementById('table1');
      const clonedTable = table.cloneNode(true);
      this.tableToExport = clonedTable;
      this.exportDialog = true
    },
    openEditDialog(item, index) {
      this.formTitle = "ערוך רשומה"
      this.editedItem = item
      this.addEditDialog = true
    },
    openItemPage(item, index) {
      this.$emit("openItemPageDetails", this.tableName, item.id);
    },
    openDeleteDialog(item) {
      this.itemToDelete = item
      if (Array.isArray(item)) {
        this.formTitle = "האם אתה בטוח שברצונך למחוק את הרשומות שנבחרו?"
      } else {
        this.formTitle = "האם אתה בטוח שברצונך למחוק את הרשומה?"
      }
      this.alertDialog = true
    },
    openCreateDIalog() {
      this.formTitle = "הוסף רשומה חדשה"
      this.editedItem = {}
      this.addEditDialog = true
    },
    openEditTablePage() {
      this.$emit("openEditTable", this.tableName);
    },
    openTaskSchedulePage() {
      this.$emit("openTaskSchedule", this.tableName);
    },
    openCrmIntigrationPage() {
      this.$emit("openCrmIntigration", this.tableName);
    },
    tableDeleted(text, color) {
      this.showSnackBar(text, color)
      this.$emit("refreshTables");
    },
    newItemsaved(item, text, color) {

      this.showSnackBar(text, color)
      if (item === "") {
        return
      }
      if (this.items.length <= 0) {
        this.refreshDetails();
        return;
      }
      this.items.push(item);
      this.totalItems++;
    },
    editedItemsaved(item, text, color) {
      this.showSnackBar(text, color)
      if (item === "") {
        return
      }

      const index = this.items.findIndex(item1 => item1.id === item.id);
      let g = Object.assign(this.items[index], item);
    },

    itemDeleted(item, text, color) {
      if (!item) {
        this.showSnackBar(text, color)
        return
      }
      if (Array.isArray(item)) {
        item.forEach(singleItem => {
          const index = this.items.findIndex(item1 => item1.id === singleItem.id);
          this.items.splice(index, 1);
          this.totalItems--;
          this.selected = [];
        });
        text = "הרשומות נמחקו בהצלחה!";
      } else {
        // Use splice to remove the item at the specified index
        const index = this.items.findIndex(item1 => item1.id === item.id);
        this.items.splice(index, 1);
        this.totalItems--;
      }

      this.showSnackBar(text, color)
    },
    async handleFileUpload(event) {
      const file = event.target.files[0];
      if (!file) {
        this.showSnackBar('No file selected', 'red');
        return;
      }
      this.file = file;
      const formData = new FormData();
      formData.append('file', file);
      formData.append('tableName', this.tableName.name);
      this.loading = true;
      console.log(123456);
      try {
        const response = await axios.post(`${process.env.VUE_APP_BASE_URL}/user/validate_items_imported`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
          timeout: 120000, // Set timeout to 2 minutes (in milliseconds)
        });

        // Parse server response
        const { importedSuccess, importedFailed } = response.data;

        this.importedSuccess = importedSuccess || [];
        this.importedFailed = importedFailed || [];
        this.loading = false;
        this.showImportDialog = true; // Show the dialog
        console.log(response.data);

      } catch (error) {
        this.showSnackBar('Error uploading or processing file: ' + error.message, 'red');
      }
    },
    closeImportDialog() {
      this.ImportResultsDialog = false;
      this.file = null;
    },
    async handleImport() {
      console.log(this.file);
      
      if (!this.file) {
        this.showSnackBar('No file selected', 'red');
        return;
      }
      const formData = new FormData();
      formData.append('file', this.file);
      formData.append('tableName', this.tableName.name);
      this.loading = true;
      try {
        const response = await axios.post(`${process.env.VUE_APP_BASE_URL}/user/add_items_imported`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
          timeout: 120000, // Set timeout to 2 minutes (in milliseconds)
        });

        this.showImportDialog = false; // Show the dialog
        this.showSnackBar('הרשומות נוספו בהצלחה', 'green');
        this.refreshDetails();
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.showSnackBar('Error uploading or processing file: ' + error.message, 'red');
      }
    },
    fileNameFromUrl(src) {
      // Splits the URL by '/' and then takes the last element
      return src.split('/').pop();
    },
    onSortChange() {
      // Called whenever sortBy or sortDesc changes
      this.refreshDetails();
    },
    handleSearchInput() {
      if (this.search) {
        if (this.items.length == this.totalItems) {
          // Perform local search
          this.items = this.items.filter(item =>
            Object.values(item).some(value =>
              value && value.toString().toLowerCase().includes(this.search.toLowerCase())
            )
          );
          this.totalItemsLocal = this.items.length
        } else {
          this.totalItemsLocal = 0;
          this.debouncedGetSearchData(true)
        }
      } else {
        this.totalItemsLocal = 0;
        this.debouncedGetData()
      }
    },
    formatDate(date) {
      if (!date) {
        return "";
      }
      return Utils.getPrettyDate(date);
    },
    getHeaderIcon(header) {
      console.log(header);

      switch (header) {
        case "תאריך":
          return "mdi-calendar";
        case "תאריך ושעה":
          return "mdi-calendar-clock";
        case "מספר":
          return "mdi-pound";
        case "לינק":
          return "mdi-link";
        case "טקסט":
          return "mdi-format-text";
        case "רשימה":
          return "mdi-format-list-bulleted";
        case "רשימה מקושרת":
          return "mdi-format-list-bulleted-type";
      }
    },
  },
  watch: {
    tableName() {
      this.refreshDetails();
    },
    itemsPerPage(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.refreshDetails();
      }
    },
    selected(newVal, oldVal) {
      console.log(newVal, oldVal);
    }
  },
  mounted() {
    let auth = Auth.checkAuth();
    if (!auth) {
      this.$router.replace({ name: "login" })
    }
    this.getData();
    this.permission = Auth.getUserPermission();

    // Create a debounced version of getData to avoid multiple rapid calls
    this.debouncedGetData = _.debounce(this.getData, 300);
    // Create a debounced version of getData to avoid multiple rapid calls
    this.debouncedGetSearchData = _.debounce((newSearch) => {
      this.getSearchData(newSearch);
    }, 700);
  },
}
</script>
<style scoped>
 .resizable >>> .v-data-table-header th {
  white-space: nowrap;
  user-select: none;
  cursor: pointer;
  resize: horizontal;
  overflow: auto;
}
/* .v-data-table >>> th {
  white-space: nowrap;
  user-select: none;
  cursor: pointer;
  resize: horizontal;
  overflow: auto;
} */

@media (min-width: 766px) {

  .topTable {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }
}

.divider {
  border-top: 0.5px solid #cfcccc;
  /* Set the border style, width, and color */
  margin-top: 10px;
  /* Optional: Add margin to adjust spacing */
  margin-bottom: 20px !important;
  /* Optional: Add margin to adjust spacing */
}

.user-info {
  display: flex;
  flex-direction: column;
}

.user-info span {
  margin-right: 2px;
}

.logout-button-container {
  display: flex;
  align-items: center;
}


/* change second row to grey in table in all website*/
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, .04);
}


.v-icon.theme--light {
  color: your-color;
}

::-webkit-scrollbar {
  width: 10px;
  /* Adjust the width of the scrollbar */
}

/* Customizes the scrollbar track (space to scroll) */
::-webkit-scrollbar-track {
  background-color: #FFFFFF;
  /* Sets the track color to white */
}

/* Customizes the scrollbar thumb (the draggable element) */
::-webkit-scrollbar-thumb {
  background-color: rgb(219, 218, 218);
  /* Sets the thumb color to blue */
  border-radius: 3px;
  /* Optional: Adds rounded corners to the thumb */
}


.table-header {
  background: white;
  border-radius: 8px;
}

.table-actions {
  background: #f8f9fa;
  transition: all 0.3s ease;
}

.search-field {
  transition: all 0.3s ease;
}

.search-field:focus-within {
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.v-btn {
  text-transform: none !important;
}

/* RTL specific styles */
.v-btn .v-icon {
  margin-left: 4px;
  margin-right: 0;
}

.records-count {
  font-weight: 500;
}

/* Responsive adjustments */
@media (max-width: 960px) {
  .admin-actions {
    margin-top: 16px;
  }

  .table-actions .d-flex {
    flex-direction: column;
    align-items: stretch;
  }

  .search-field {
    margin-top: 16px;
    max-width: none !important;
  }
}


.no-data-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 48px 24px;
  color: #64748b;
}
</style>
