<template>
    <v-card elevation="3" class="account-card">
        <!-- Header Section -->
        <v-card-title class="account-header d-flex align-center pa-6">
            <h1 class="text-h4 font-weight-bold primary--text flex-grow-1">פרטי המשתמש</h1>
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on" @click="openEditFrom" class="edit-button">
                        <v-icon color="primary">mdi-pencil</v-icon>
                    </v-btn>
                </template>
                <span>ערוך פרטים</span>
            </v-tooltip>
        </v-card-title>

        <v-divider></v-divider>

        <!-- User Details Section -->
        <v-card-text class="pa-6">
            <v-row>
                <v-col cols="12" md="6" v-for="(detail, index) in userDetailsList" :key="index">
                    <v-card outlined class="detail-card">
                        <v-card-text>
                            <div class="d-flex align-center mb-2">
                                <v-icon color="primary" class="ml-2">{{ detail.icon }}</v-icon>
                                <span class="text-subtitle-1 font-weight-medium primary--text">{{ detail.label }}</span>
                            </div>
                            <div class="text-body-1 mt-2" :class="{ 'user-select-all': detail.selectable }">
                                {{ userDetails[detail.key] || '---' }}
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-card-text>

        <!-- Loading Indicator -->
        <v-overlay :value="progressShow" absolute>
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </v-overlay>
    </v-card>
</template>

<script>
import ApiServices from '@/services/api.service'

export default {
    name: 'UserAccount',
    data() {
        return {
            progressShow: false,
            userDetails: {
                name: "",
                phone: "",
                email: "",
                role: "",
                permission: "",
                api_token: "",
                external_system_number: ""
            },
            userDetailsList: [
                { key: 'name', label: 'שם', icon: 'mdi-account' },
                { key: 'phone', label: 'פלאפון', icon: 'mdi-phone' },
                { key: 'email', label: 'אימייל', icon: 'mdi-email' },
                { key: 'role', label: 'קבוצה', icon: 'mdi-account-group' },
                { key: 'permission', label: 'הרשאות', icon: 'mdi-shield-account' },
                { key: 'api_token', label: 'API Token', icon: 'mdi-key-variant', selectable: true },
                { key: 'external_system_number', label: 'מספרי מערכות תוכן/מרכזיות', icon: 'mdi-server-network' }
            ]
        }
    },
    methods: {
        async getUserDetails() {
            try {
                this.progressShow = true
                let token = localStorage.getItem("token")
                let api = process.env.VUE_APP_BASE_URL + "/user/get_user_details"
                const res = await fetch(api, ApiServices.requestOptions("GET", "", token))
                const jsonObject = await res.json()

                if (res.status >= 400) {
                    this.$emit('showSnackBar', "שגיאה בקבלת נתונים", "error")
                } else if (res.status === 200) {
                    this.userDetails = jsonObject
                }
            } catch (error) {
                this.$emit('showSnackBar', "שגיאה בקבלת נתונים: " + error, "error")
            } finally {
                this.progressShow = false
            }
        },
        openEditFrom() {
            this.$emit("Edit", this.userDetails)
        }
    },
    mounted() {
        this.getUserDetails()
    }
}
</script>

<style lang="scss" scoped>
.account-card {
    border-radius: 8px;
    overflow: hidden;
}

.account-header {
    background: linear-gradient(to left, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95));
}

.detail-card {
    height: 100%;
    transition: transform 0.2s, box-shadow 0.2s;
    border-radius: 8px;

    &:hover {
        transform: translateY(-2px);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
    }
}

.edit-button {
    transition: transform 0.2s;

    &:hover {
        transform: scale(1.1);
    }
}

.user-select-all {
    user-select: all;
    cursor: pointer;
}

// RTL specific styles
:deep(.v-card__title) {
    justify-content: flex-start;
}

:deep(.v-icon) {
    margin-left: 8px;
    margin-right: 0;
}
</style>