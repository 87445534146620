<template>
    <div>
        <v-navigation-drawer v-model="drawer" :mini-variant="!isExpended" :mini-variant-width="56" width="300" right app 
            permanent class="modern-drawer">
            <div @mouseenter="isExpended = true" style="height: 100%">
                <!-- Header Section -->
                <div class="drawer-header" :class="{ 'mini-header': !isExpended }" >
                    <div class="header-controls">
                        <v-btn icon small @click="expenedHandle" class="expand-btn" :class="{ 'rotated': !isExpended }">
                            <v-icon>mdi-chevron-right</v-icon>
                        </v-btn>
                        <transition name="fade">
                            <v-img v-if="isExpended" src="https://cpanel.clickcall.co.il/serverscall/sys_icon/logo.png"
                                max-width="160" contain class="logo-image"></v-img>
                        </transition>
                    </div>
                </div>

                <!-- Main Content -->
                <div class="drawer-content">
                    <!-- Create Table Button -->
                    <template v-if="permission === 'edit'">
                        <div v-if="isExpended" class="create-table-section">
                            <v-btn block color="primary" class="create-table-btn" elevation="2" @click="createTable">
                                <v-icon left>mdi-plus</v-icon>
                                <span class="font-weight-medium">צור טבלה חדשה</span>
                            </v-btn>
                        </div>
                        <div v-else class="mini-create-section">
                            <v-tooltip left>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon small dark @click="createTable" v-bind="attrs" v-on="on"
                                        class="mini-create-btn">
                                        <v-icon>mdi-plus</v-icon>
                                    </v-btn>
                                </template>
                                <span>צור טבלה חדשה</span>
                            </v-tooltip>
                        </div>
                    </template>

                    <!-- Tables Section -->
                    <div class="tables-section">
                        <v-list class="tables-list" dense>
                            <!-- Loading State -->
                            <div v-if="loading" class="loading-state">
                                <v-skeleton-loader v-for="n in 5" :key="n" type="list-item"
                                    class="mb-2"></v-skeleton-loader>
                            </div>

                            <!-- Tables List -->
                            <draggable v-else v-model="tables" :handle="'.handle'" @end="onDragEnd"
                                :disabled="!isControlPressed" ghost-class="drag-ghost" drag-class="drag-active"
                                :animation="200">
                                <div v-for="table in tables" :key="table.name" class="table-item-wrapper">
                                    <template v-if="isExpended">
                                        <v-hover v-slot="{ hover }">

                                            <v-list-item :class="{
                                                'handle': isControlPressed ? true : false,
                                                'selected': selectedTable === table.name,
                                                'hovering': hover && selectedTable !== table.name
                                            }" @click="showTable(table)"
                                                style="padding-bottom: 8px !important; padding-top: 8px !important;">
                                                <v-list-item-icon class="table-icon">
                                                    <v-icon color="#1a3a8a" >
                                                        {{ getTableIcon(table) }}
                                                    </v-icon>
                                                </v-list-item-icon>

                                                <v-list-item-content>
                                                    <v-list-item-title
                                                        >
                                                        <span class="table-title">
                                                            {{ table.hebrew_name }}
                                                        </span>
                                                    </v-list-item-title>
                                                </v-list-item-content>

                                                <v-list-item-action v-if="isControlPressed">
                                                    <v-icon small
                                                        :color="selectedTable === table.name ? 'white' : 'grey'">
                                                        mdi-drag
                                                    </v-icon>
                                                </v-list-item-action>
                                            </v-list-item>
                                        </v-hover>
                                    </template>
                                    <template v-else>
                                        <v-tooltip left>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-list-item dense class="mini-table-item"
                                                    style="padding-right: 10px !important; padding-bottom: 10px !important; padding-top: 10px !important;"
                                                    :class="{ 'selected': selectedTable === table.name }"
                                                    @click="showTable(table)" v-bind="attrs" v-on="on">
                                                    <v-list-item-icon class="mini-table-icon">
                                                        <v-icon
                                                            color="#1a3a8a"
                                                            medium>
                                                            {{ getTableIcon(table) }}
                                                        </v-icon>
                                                    </v-list-item-icon>
                                                </v-list-item>
                                            </template>
                                            <span>{{ table.hebrew_name }}</span>
                                        </v-tooltip>
                                    </template>
                                </div>
                            </draggable>
                        </v-list>
                    </div>

                    <!-- Save Changes Button -->
                    <v-slide-y-transition>
                        <div v-if="positionChanged" class="save-changes-section">
                            <template v-if="isExpended">
                                <v-btn color="success" block @click="saveTablesPositions" class="save-changes-btn"
                                    elevation="2">
                                    <v-icon left>mdi-content-save</v-icon>
                                    שמור שינויים
                                </v-btn>
                            </template>
                            <template v-else>
                                <v-tooltip left>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon small color="success" @click="saveTablesPositions" v-bind="attrs"
                                            v-on="on" class="mini-save-btn">
                                            <v-icon>mdi-content-save</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>שמור שינויים</span>
                                </v-tooltip>
                            </template>
                        </div>
                    </v-slide-y-transition>
                </div>

            </div>
        </v-navigation-drawer>
        <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>
    </div>
</template>

<script>
import SnackBar from '@/components/widgets/snackBar.vue'
import Auth from '@/services/auth.service.js'
import ApiServices from '@/services/api.service.js'
import draggable from 'vuedraggable'
import Shared from '@/util/Shared'

export default {
    name: 'TablesList',
    components: {
        SnackBar,
        draggable,
    },
    props: {
        newTable: Object,
    },
    data: () => ({
        drawer: true,
        userName: "",
        email: "",
        permission: "",
        asSpecialPage: false,
        specialPage: "",
        tables: [],
        snackbar: false,
        snackbarColorBt: "green",
        snacbarText: "",
        dropdownOpen: false,
        isActive: true,
        panel: [0],
        isExpended: true,
        selectedTable: null,
        loading: true,
        positionChanged: false,
        isControlPressed: false,
    }),
    methods: {
        async getTablesNames() {
            const tables = await Shared.getTablesNames()
            if (tables === "error") {
                this.showSnackBar("שגיאה בקבלת טבלאות", "red")
            }
            this.tables = tables
            this.$store.state.tables = this.tables
            this.loading = false
        },
        handleKeyDown(event) {
            if (event.key === "Control") {
                this.isControlPressed = true
            }
        },
        handleKeyUp(event) {
            if (event.key === "Control") {
                this.isControlPressed = false
            }
        },
        onDragEnd(event) {
            const { oldIndex, newIndex } = event
            if (oldIndex !== newIndex) {
                this.positionChanged = true
            }
        },
        async saveTablesPositions() {
            let api = process.env.VUE_APP_BASE_URL + "/user/update_table_position"
            let token = localStorage.getItem("token")
            try {
                const jsonBody = JSON.stringify({ tablesArray: this.tables })
                const response = await fetch(api, ApiServices.requestOptions("PUT", jsonBody, token))
                if (response.status >= 400) {
                    this.showSnackBar("שגיאה בעדכון מיקום טבלה", "red")
                } else {
                    this.showSnackBar("עדכון מיקום טבלה בוצע בהצלחה", "green")
                    this.positionChanged = false
                }
            } catch (error) {
                this.showSnackBar("Error update table position: " + error, "red")
            }
        },
        showTable(table) {
            this.selectedTable = table.name
            this.$emit("showTable", table)
        },
        createTable() {
            this.$emit("createTable")
        },
        getUserDetails() {
            this.userName = Auth.getUserName()
            this.email = Auth.getUserEmail()
            this.permission = Auth.getUserPermission()
        },
        showSnackBar(message, color) {
            this.snacbarText = message
            this.snackbarColorBt = color
            this.snackbar = true
        },
        expenedHandle() {
            this.isExpended = !this.isExpended
            this.$emit("isExtendedFunc")
        },
        getTableIcon(item) {
            // You can customize this method to return different icons based on table type
            const iconMap = {
                users: 'mdi-account-group',
                products: 'mdi-package-variant',
                orders: 'mdi-cart',
                settings: 'mdi-cog',
                "משתמשים": 'mdi-account-group',
                "מוצרים": 'mdi-package-variant',
                "הזמנות": 'mdi-cart',
                "הגדרות": 'mdi-cog',
            }
            // Return specific icon if mapped, otherwise return default
            return iconMap[item.hebrew_name.toLowerCase()] || 'mdi-table'
        },
    },
    watch: {
        newTable(val) {
            if (val) {
                this.tables.push(val)
            }
        }
    },
    mounted() {
        this.getTablesNames()
        this.getUserDetails()
        window.addEventListener("keydown", this.handleKeyDown)
        window.addEventListener("keyup", this.handleKeyUp)
    },
    beforeDestroy() {
        window.removeEventListener("keydown", this.handleKeyDown)
        window.removeEventListener("keyup", this.handleKeyUp)
    },
}
</script>

<style scoped>
.modern-drawer {
    background: linear-gradient(0deg, rgb(219, 218, 218) 1%, rgba(255, 255, 255, 1) 70%);
    border-left: 1px solid rgba(0, 0, 0, 0.12);
}

.drawer-header {
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 16px;
    padding-right: 16px;
    background: linear-gradient(to left, #0d2c6d, #1a3a8a);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    height: 57px;
    transition: all 0.3s ease;
}

.mini-header {
    height: 57px;
    padding: 12px;
}

.header-controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.expand-btn {
    background: rgba(255, 255, 255, 0.1);
    transition: all 0.3s ease;
}

.expand-btn:hover {
    background: rgba(255, 255, 255, 0.2);
}

.rotated {
    transform: rotate(180deg);
}

.logo-image {
    transition: opacity 0.3s ease;
}

.drawer-content {
    height: calc(100% - 80px);
    display: flex;
    flex-direction: column;
}

.create-table-section {
    padding: 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.create-table-btn {
    text-transform: none;
    letter-spacing: 0;
    height: 44px;
    border-radius: 8px;
    background: linear-gradient(45deg, #0d2c6d, #1a3a8a);
}

.tables-section {
    flex-grow: 1;
    overflow-y: auto;
}

.tables-list {
    padding: 8px;
}

.table-item-wrapper {
    margin-bottom: 4px;
}

.v-list-item {
    border-radius: 8px;
    margin-bottom: 4px;
    transition: all 0.2s ease;
}

.selected {
    background: linear-gradient(0deg, rgba(255, 185, 26, 0.8) 1%, rgba(255, 185, 26, 0.5) 70%);
    box-shadow: 0 2px 8px rgba(13, 44, 109, 0.2);
}

.hovering {
    background-color: rgba(13, 44, 109, 0.05);
}

.table-icon {
    margin-right: 0;
    margin-left: 12px;
}

.table-title {
    font-size: 1.2rem;
    font-weight: 500;
}

.save-changes-section {
    padding: 16px;
    background: linear-gradient(0deg, rgb(219, 218, 218) 1%, rgba(255, 255, 255, 1) 70%);
    border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.save-changes-btn {
    text-transform: none;
    letter-spacing: 0;
    height: 44px;
    border-radius: 8px;
}

.drag-ghost {
    opacity: 0.5;
    background-color: rgba(13, 44, 109, 0.1);
    border: 2px dashed #0d2c6d;
    border-radius: 8px;
}

.drag-active {
    cursor: grabbing;
    transform: scale(1.02);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

/* Transitions */
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s ease;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

/* Modern Scrollbar */
.tables-section::-webkit-scrollbar {
    width: 6px;
}

.tables-section::-webkit-scrollbar-track {
    background: transparent;
}

.tables-section::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 3px;
}

.tables-section::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.3);
}

/* Loading State */
.loading-state {
    padding: 8px;
}

/* RTL Specific Styles */
.v-list-item__icon.table-icon {
    margin-right: 0;
    margin-left: 12px;
}

.modern-snackbar {
    font-family: 'Heebo', sans-serif;
}

.mini-create-section {
    padding: 8px 0;
    display: flex;
    justify-content: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.mini-create-btn {
    background: linear-gradient(45deg, #0d2c6d, #1a3a8a);
    color: white;
    margin: 4px 0;
}

.mini-table-item {
    padding: 8px 0;
    height: 40px !important;
    display: flex;
    justify-content: center;
    border-radius: 8px;
    margin-bottom: 4px;
    transition: all 0.2s ease;
}

.mini-table-item:hover {
    background-color: rgba(13, 44, 109, 0.05);
}

.mini-table-item.selected {
    background: linear-gradient(45deg, #0d2c6d, #1a3a8a) !important;
}

.mini-table-icon {
    margin: 0 !important;
    min-width: 40px !important;
}

.mini-save-btn {
    margin: 8px auto;
    display: block;
}

/* RTL Tooltip Adjustment */
.v-tooltip__content {
    direction: rtl;
}

@keyframes pulse {
    0% {
        transform: scale(1);
        box-shadow: 0 2px 8px rgba(0, 128, 0, 0.2);
    }

    50% {
        transform: scale(1.02);
        box-shadow: 0 4px 12px rgba(0, 128, 0, 0.3);
    }

    100% {
        transform: scale(1);
        box-shadow: 0 2px 8px rgba(0, 128, 0, 0.2);
    }
}
</style>