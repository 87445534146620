<template>
    <v-dialog v-model="show" max-width="600px" persistent dir="rtl">
        <v-card>
            <v-card-title class="headline primary white--text">
                תוצאות בדיקת קובץ
                <v-spacer></v-spacer>
                <v-progress-circular v-if="loading" indeterminate color="white" size="24"></v-progress-circular>
            </v-card-title>

            <v-card-text class="pt-4">
                <v-row>
                    <v-col cols="6">
                        <v-card outlined :class="{ 'success lighten-5': importedSuccess.length > 0 }"
                            class="text-center pa-4">
                            <v-icon large :color="importedSuccess.length ? 'success' : 'grey'">
                                mdi-check-circle
                            </v-icon>
                            <div class="text-h4 font-weight-bold mt-2">{{ importedSuccess.length }}</div>
                            <div class="subtitle-1">רשומות תקינות</div>
                        </v-card>
                    </v-col>

                    <v-col cols="6">
                        <v-card outlined :class="{ 'error lighten-5': importedFailed.length > 0 }"
                            class="text-center pa-4">
                            <v-icon large :color="importedFailed.length ? 'error' : 'grey'">
                                mdi-alert-circle
                            </v-icon>
                            <div class="text-h4 font-weight-bold mt-2">{{ importedFailed.length }}</div>
                            <div class="subtitle-1">רשומות שגויות</div>
                        </v-card>
                    </v-col>
                </v-row>

                <v-expand-transition>
                    <div v-if="importedFailed.length > 0" class="mt-4">
                        <v-divider></v-divider>
                        <div class="subtitle-1 error--text mt-4 mb-2">
                            <v-icon small color="error" class="ml-1">mdi-alert</v-icon>
                            פירוט שגיאות:
                        </div>
                        <v-card outlined max-height="200" class="overflow-y-auto">
                            <v-list dense>
                                <v-list-item v-for="(item, index) in importedFailed" :key="index">
                                    <v-list-item-icon class="ml-3">
                                        <v-icon color="error">mdi-close-circle</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>שורה {{ item.row || index + 1 }}</v-list-item-title>
                                        <v-list-item-subtitle>{{ filteredItem(item) }}</v-list-item-subtitle>
                                        <v-list-item-title>שגיאה: </v-list-item-title>
                                        <v-list-item-subtitle>{{ item.error }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-card>
                    </div>
                </v-expand-transition>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="closeDialog" :disabled="loading">
                    ביטול
                </v-btn>
                <v-btn color="primary" :disabled="importedFailed.length > 0 || loading" @click="importToServer">
                    אישור ייבוא
                    <v-icon right>mdi-cloud-upload</v-icon>
                </v-btn>
            </v-card-actions>

            <v-row v-if="progressShow">
                <v-col class="d-flex justify-center">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                </v-col>
            </v-row>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'ImportResultsDialog',
    props: {
        value: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        importedSuccess: {
            type: Array,
            default: () => []
        },
        importedFailed: {
            type: Array,
            default: () => []
        }
    },
    data: () => ({
        progressShow: false,
    }),
    computed: {
        show: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        }
    },
    methods: {
        closeDialog() {
            this.show = false;
            this.$emit('close');
        },
        filteredItem(item) {
            if (!item || typeof item !== 'object') return item; // Handle cases where item is null or not an object
            const excludedFields = ['cdate_modified', 'date_created', 'id', "error"];
            return Object.keys(item)
                .filter(key => !excludedFields.includes(key))
                .map(key => `${key}: ${item[key]}`)
                .join(', '); // Convert object to string for display
        },
        importToServer() {

            this.$emit('import');
        }
    }
}
</script>

<style scoped>
.v-dialog {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}
</style>