<template>
    <FormLogin/>
</template>

<script>
import FormLogin from '@/components/auth/FormLogin.vue'
import Auth from '@/services/auth.service.js'

export default {
  name: 'LoginView',
  components: {
    FormLogin
  },
  mounted() {
    const auth = Auth.checkAuth();
    const role = Auth.getUserRole();
    if (auth) {
      this.$router.replace({ name: role === "user" ? "user" : "manageUsers"})
    } 
  },
}
</script>

<style scoped>
.login-view {
  transform: translateZ(0);
  animation: colorIn 2.5s ease-out;
}

@keyframes colorIn {
  0% {
    filter: grayscale(1);
  }
  100% {
    filter: grayscale(0);
  }
}
</style>