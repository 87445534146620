<template>
  <v-container fluid class="crm-integration pa-0" style="margin-top: 70px; margin-bottom: 70px;">
    <v-row class="justify-center">
      <v-col cols="12" lg="10">
        <v-card class="main-card" v-if="!operationSettings">
          <!-- Header -->
          <div class="card-header">
            <v-btn @click="backToTableData()" icon class="back-button ml-4">
              <v-icon>mdi-arrow-right</v-icon>
            </v-btn>
            <h1 class="text-h4 font-weight-bold primary-text mb-0">
              אינטיגרציה ל CRM - {{ tableName.hebrew_name }}
            </h1>
          </div>

          <v-divider></v-divider>

          <!-- Trigger List -->
          <div v-if="!isTrigerChoosed" class="pa-6">
            <h2 class="text-h6 font-weight-medium mb-6">פעיל כאשר:</h2>
            <v-row>
              <v-col cols="12" sm="6" md="4" v-for="(triger, index) in trigers" :key="index">
                <v-hover v-slot="{ hover }">
                  <v-card min-height="100" class="trigger-card" :class="{ 'on-hover': hover }"
                    @click="openOperationsList(triger)">
                    <div class="trigger-card-content">
                      <v-badge v-if="calcTrigerOperationsCount(triger) > 0" :content="calcTrigerOperationsCount(triger)"
                        color="#0d2c6d" class="trigger-badge" offset-y="20" offset-x="-7"></v-badge>
                      <div class="trigger-text">
                        <h3 class="text-h6 font-weight-bold mb-0">
                          {{ triger.hebrew_name }}
                        </h3>
                      </div>
                    </div>
                  </v-card>
                </v-hover>
              </v-col>
            </v-row>
          </div>

          <!-- Operations List -->
          <div v-else class="pa-6">
            <div class="d-flex align-center justify-space-between mb-6">
              <h2 class="text-h6 font-weight-medium mb-0">
                פעולות כאשר '{{ trigerChoosed.hebrew_name }}'
                <span class="text-subtitle-1 text-medium-emphasis">
                  ({{ operations.length }} פעולות)
                </span>
              </h2>
              <v-btn color="primary" @click="createDialog = true" class="add-button">
                <v-icon left>mdi-plus</v-icon>
                הוסף פעולה
              </v-btn>
            </div>

            <v-row>
              <v-col cols="12" sm="6" md="4" v-for="(operation, index) in operations" :key="index">
                <v-hover v-slot="{ hover }">
                  <v-card class="operation-card" :class="{ 'on-hover': hover }">
                    <v-card-text class="text-center">
                      <h3 class="text-h6 font-weight-bold mb-2">
                        {{ operation.details.name }}
                      </h3>
                      <div class="status-chip"
                        :class="operation.action.mode === 'on' ? 'status-active' : 'status-inactive'">
                        {{ operation.action.mode === 'on' ? 'פעיל' : 'לא פעיל' }}
                      </div>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions class="justify-center">
                      <v-btn text color="primary" @click="openOperationSettings(operation, index)">
                        <v-icon left>mdi-cog</v-icon>
                        ערוך פעולה
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-hover>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <!-- Dialogs -->
    <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

    <CreateDialog v-model="createDialog" v-if="createDialog" @operationCreated="operationCreated" :tableName="tableName"
      :trigerName="trigerChoosed.name" />

    <OperationSettings v-model="operationSettings" v-if="operationSettings" :operationToEdit="operationToEdit"
      @operationUpdated="operationUpdated" :tableName="tableName" />
  </v-container>
</template>


<script>
import ApiServices from '@/services/api.service.js'
import SnackBar from '@/components/widgets/snackBar.vue'
import Auth from '@/services/auth.service.js'
import CreateDialog from '@/components/user/crmIntigration/CreateOperationDialog'
import OperationSettings from '@/components/user/crmIntigration/OperationSettings'

export default {
  components: {
    SnackBar,
    CreateDialog,
    OperationSettings
  },
  props: {
    tableName: Object,
    value: { type: Boolean, default: false },
  },
  data: () => ({
    operations: [],
    trigers: [],
    snackbar: false,
    snackbarColorBt: "green",
    snacbarText: "",
    createDialog: false,
    operationSettings: false,
    operationToEdit: {},
    trigerChoosed: "",
    isTrigerChoosed: false

  }),
  computed: {
    openMode: {
      get() {
        return this.value
      },
      set(v) {
        return this.$emit('input', v)
      }
    },
  },
  methods: {
    async getOperations() {
      try {
        this.operations = [];
        let token = localStorage.getItem("token");
        const tableJSON = JSON.stringify({ tableName: this.tableName.name, triger: this.trigerChoosed.name });

        let api = process.env.VUE_APP_BASE_URL + "/user/get_table_crm_operations";
        const res = await fetch(api, ApiServices.requestOptions("POST", tableJSON, token));
        const jsonObject = await res.json();
        if (res.status >= 400) {
          this.showSnackBar(" שגיאה בקבלת נתונים" + jsonObject.message, "red");
        } else if (res.status === 200) {
          this.operations = jsonObject.operations;
          this.trigers = jsonObject.trigers;
          if (this.trigerChoosed) {
            this.operations = this.operations.filter(operation => operation.details.triger === this.trigerChoosed.name)
          }

        }
      } catch (error) {
        this.showSnackBar("Error get operations list: " + error, "red");
      }
    },
    showSnackBar(message, color) {
      this.snacbarText = message;
      this.snackbarColorBt = color;
      this.snackbar = true
    },
    operationCreated(item, text, color) {
      this.showSnackBar(text, color);
      this.getOperations()
    },
    openOperationSettings(operation, index) {
      this.operationToEdit = operation;
      this.operationSettings = true
    },
    openOperationsList(triger) {
      this.trigerChoosed = triger;
      this.operations = this.operations.filter(operation => operation.details.triger === this.trigerChoosed.name)
      this.isTrigerChoosed = true;
    },
    calcTrigerOperationsCount(triger) {
      return this.operations.filter(operation => operation.details.triger === triger.name).length
    },
    operationUpdated(operation, text, color) {
      this.showSnackBar(text, color);
      this.operationSettings = false;
      this.getOperations();
    },
    backToTableData() {
      if (this.isTrigerChoosed) {
        this.trigerChoosed = "";
        this.isTrigerChoosed = false;
        this.getOperations();
      } else {
        this.$emit('backToTableData', this.tableName)
      }
    }
  },
  mounted() {
    let auth = Auth.checkAuth();
    if (!auth) {
      this.$router.replace({ name: "login" })
    }
    this.getOperations();
  },
}
</script>
<style scoped>
.crm-integration {
  background: linear-gradient(135deg, #f8fafc 0%, #f0f7ff 100%);
  min-height: 100vh;
  padding-top: 70px;
  padding-bottom: 90px;
}

.main-card {
  border-radius: 16px;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.98);
  backdrop-filter: blur(10px);
  box-shadow: 0 4px 24px rgba(13, 44, 109, 0.08);
}

.card-header {
  padding: 1.5rem;
  background: linear-gradient(to bottom, rgba(13, 44, 109, 0.02), transparent);
  display: flex;
  align-items: center;
}

.primary-text {
  background: linear-gradient(135deg, #0d2c6d 0%, #1a4ba5 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.back-button {
  background: rgba(13, 44, 109, 0.05);
  transition: all 0.3s ease;
}

.back-button:hover {
  background: rgba(13, 44, 109, 0.1);
  transform: translateX(2px);
}

.back-button .v-icon {
  color: #0d2c6d;
}

/* Trigger Cards */
.trigger-card {
  border-radius: 12px;
  transition: all 0.3s ease;
  border: 1px solid rgba(13, 44, 109, 0.1);
  background: linear-gradient(135deg, rgba(13, 44, 109, 0.02) 0%, rgba(13, 44, 109, 0.05) 100%);
  cursor: pointer;
  min-height: 100px;
}

.trigger-card.on-hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 24px rgba(13, 44, 109, 0.12);
  border-color: rgba(13, 44, 109, 0.2);
}

.trigger-card-content {
  position: relative;
  height: 100%;
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.trigger-badge {
  position: absolute !important;
  top: 10px !important;
  right: 10px !important;
}

.trigger-text {
  text-align: center;
  padding: 1rem;
}

.trigger-card.on-hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 24px rgba(13, 44, 109, 0.12);
  border-color: rgba(13, 44, 109, 0.2);
}

/* Operation Cards */
.operation-card {
  border-radius: 12px;
  transition: all 0.3s ease;
  border: 1px solid rgba(13, 44, 109, 0.1);
}

.operation-card.on-hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 24px rgba(13, 44, 109, 0.12);
  border-color: rgba(13, 44, 109, 0.2);
}

/* Status Chips */
.status-chip {
  display: inline-block;
  padding: 4px 12px;
  border-radius: 100px;
  font-size: 0.875rem;
  font-weight: 500;
}

.status-active {
  background: rgba(34, 197, 94, 0.1);
  color: #16a34a;
}

.status-inactive {
  background: rgba(239, 68, 68, 0.1);
  color: #ef4444;
}

/* Add Button */
.add-button {
  text-transform: none;
  letter-spacing: 0.5px;
  font-size: 0.9375rem;
  font-weight: 500;
  border-radius: 8px;
  background: linear-gradient(135deg, #0d2c6d 0%, #1a4ba5 100%) !important;
  box-shadow: 0 2px 8px rgba(13, 44, 109, 0.2);
  transition: all 0.3s ease;
}

.add-button:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(13, 44, 109, 0.3);
}

/* RTL Support */
.v-application {
  direction: rtl;
}
</style>