<template>
  <v-container fluid class="fill-height pa-0">
    <v-row no-gutters class="fill-height">
      <!-- Right Side - Decorative -->
      <v-col cols="12" md="7" class="right-side d-none d-md-flex">
        <div class="right-content">
          <v-img src="@/assets/logo.png"  max-width="420" contain></v-img>
          <h2 class="text-h3 font-weight-bold text-white mb-4">
            ברוכים הבאים
          </h2>
          <p class="text-h6 font-weight-regular text-white">
            מערכת ניהול נתונים מתקדמת
          </p>
        </div>
      </v-col>

      <!-- Left Side - Login Form -->
      <v-col cols="12" md="5" class="login-form-side login-bg ">
        <v-card class="login-card" flat>
          <!-- Lock Icon -->
          <div class="lock-icon-container mb-6">
            <v-icon size="48" color="#0d2c6d">mdi-lock</v-icon>
          </div>

          <!-- Title -->
          <h1 class="text-h4 font-weight-bold text-center mb-2 primary-text">התחברות</h1>
          <p class="text-subtitle-1 text-center text-medium-emphasis mb-8">
            ברוכים הבאים חזרה! אנא התחברו למערכת
          </p>

          <!-- Login Form -->
          <v-form @submit.prevent="submitHandler" ref="form">
            <v-text-field
              v-model="email"
              label="דוא״ל"
              type="email"
              :rules="[v => !!v || 'שדה חובה']"
              outlined
              dense
              prepend-inner-icon="mdi-email"
              class="mb-4 custom-input"
              required
              dir="rtl"
              color="#0d2c6d"
              background-color="rgba(13, 44, 109, 0.02)"
            ></v-text-field>

            <v-text-field
              v-model="password"
              label="סיסמה"
              :type="showPassword ? 'text' : 'password'"
              :rules="[v => !!v || 'שדה חובה']"
              outlined
              dense
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showPassword = !showPassword"
              prepend-inner-icon="mdi-lock"
              required
              dir="rtl"
              color="#0d2c6d"
              class="custom-input"
              background-color="rgba(13, 44, 109, 0.02)"
            ></v-text-field>

            <!-- Forgot Password Link -->
            <div class="text-right mb-6">
              <v-btn
                text
                small
                class="forgot-password-btn"
                @click="forgotPassword"
              >
                שכחתי סיסמה
              </v-btn>
            </div>

            <!-- Login Button -->
            <v-btn
              block
              height="44"
              :loading="progressShow"
              :disabled="progressShow"
              @click="login"
              class="login-button"
              elevation="2"
            >
              התחבר
            </v-btn>
          </v-form>
        </v-card>
      </v-col>


    </v-row>

    <!-- Snackbar -->
    <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>
  </v-container>
</template>

<script>
import ApiServices from '@/services/api.service'
import SnackBar from '@/components/widgets/snackBar.vue'

export default {
  components: {
    SnackBar
  },

  data: () => ({
    isResponsive: false,
    email: "",
    password: "",
    progressShow: false,
    snackbar: false,
    snackbarColorBt: "success",
    snacbarText: "",
    showPassword: false
  }),

  methods: {
    async login() {
      if (this.$refs.form.validate()) {
        this.progressShow = true
        let MyJSON = JSON.stringify({ email: this.email, password: this.password });
        let api = process.env.VUE_APP_BASE_URL + "/auth/login"

        try {
          const response = await fetch(api, ApiServices.requestOptions("POST", MyJSON, ""));
          this.progressShow = false;
          const jsonObject = await response.json();
          
          if (response.status === 400) {
            this.showSnackBar("שגיאת התחברות: " + jsonObject.message, "red");
          } else if (response.status === 429) {
            this.showSnackBar("יותר מדי נסיונות כניסה", "red");
          } else if (response.status === 500) {
            this.showSnackBar("internal error", "red");
          } else if (response.status === 200) {
            this.showSnackBar("התחברת בהצלחה!", "green");
            localStorage.setItem('token', jsonObject.token);
            this.$router.replace({ name: jsonObject.role === "user" ? "user" : "manageUsers"})
          }
        } catch (error) {
          this.progressShow = false;
          this.showSnackBar("Error Login: " + error, "red");
        }
      }
    },

    showSnackBar(message, color) {
      this.snacbarText = message;
      this.snackbarColorBt = color;
      this.snackbar = true;
    },

    async forgotPassword() {
      if (!this.email) {
        this.showSnackBar("יש להזין אימייל לצורך איפוס הסיסמה", "red");
        return;
      }

      this.progressShow = true;
      let MyJSON = JSON.stringify({ email: this.email });
      let api = process.env.VUE_APP_BASE_URL + "/auth/forgotPassword"

      try {
        const response = await fetch(api, ApiServices.requestOptions("POST", MyJSON, ""));
        this.progressShow = false;

        if (response.status === 401) {
          this.showSnackBar("שגיאה: אימייל לא קיים", "red");
        } else if (response.status === 429) {
          this.showSnackBar("יותר מדי נסיונות כניסה", "red");
        } else if (response.status >= 400) {
          this.showSnackBar("שגיאת שרת", "error");
        } else if (response.status === 200) {
          this.showSnackBar("נשלח מייל לאיפוס הסיסמה", "green");
        }
      } catch (error) {
        this.progressShow = false;
        this.showSnackBar("Error Login: " + error, "red");
      }
    },

    handleResize() {
      this.isResponsive = window.innerWidth <= 500;
    },
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },

  mounted() {
    document.title = 'Login | CLICK-CALL';
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  },

  watch: {
    $route() {
      document.title = 'Login | CLICK-CALL';
    },
  },
}
</script>

<style scoped>


.login-card {
  width: 100%;
  max-width: 420px;
  padding: 2.5rem;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 24px;
  backdrop-filter: blur(10px);
  box-shadow: 0 4px 24px rgba(13, 44, 109, 0.08);
}

.lock-icon-container {
  width: 80px;
  height: 80px;
  margin: 0 auto;
  background: linear-gradient(135deg, rgba(13, 44, 109, 0.05) 0%, rgba(13, 44, 109, 0.1) 100%);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 12px rgba(13, 44, 109, 0.08);
}

.right-side {
  background: linear-gradient(135deg, #0d2c6d 0%, #0d2c6d 60%, #ffb91a 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4rem;
  position: relative;
  overflow: hidden;
}

.right-side::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 18c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm48 25c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm-43-7c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm63 31c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM34 90c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm56-76c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM12 86c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm28-65c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm23-11c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-6 60c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm29 22c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zM32 63c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm57-13c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-9-21c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM60 91c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM35 41c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM12 60c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2z' fill='rgba(255,255,255,0.1)' fill-rule='evenodd'/%3E%3C/svg%3E");
  opacity: 0.8;
}

.right-content {
  position: relative;
  z-index: 1;
  text-align: center;
  max-width: 480px;
}

.right-logo {
  filter: brightness(0) invert(1);
  opacity: 0.9;
}

/* Input Fields */
.v-text-field>>>.v-input__slot {
  border-color: #e2e8f0 !important;
  transition: all 0.3s ease;
}

.v-text-field>>>.v-input__slot:hover {
  border-color: #cbd5e1 !important;
}

.v-text-field.v-input--is-focused>>>.v-input__slot {
  border-color: #6366f1 !important;
  box-shadow: 0 0 0 1px #6366f1 !important;
}

/* Login Button */
.login-button {
  background: linear-gradient(135deg, #0d2c6d 0%, #1a4ba5 100%) !important;
  color: white !important;
  text-transform: none;
  letter-spacing: 0.5px;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.login-button:hover {
  opacity: 0.95;
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(13, 44, 109, 0.2) !important;
}

/* RTL Support */
.v-application {
  direction: rtl;
}

.v-text-field>>>.v-input__prepend-inner {
  margin-right: 0;
  margin-left: 8px;
}

.v-text-field>>>.v-input__append-inner {
  margin-left: 0;
  margin-right: 8px;
}


.gradient-bg {
  background: linear-gradient(135deg, #0d2c6d 0%, #0d2c6d 60%, #ffb91a 100%);
}

.login-bg {
  background: linear-gradient(135deg, #f0f7ff 0%, #e6f0ff 100%);
}


.login-form-side {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}

/* Responsive Design */
@media (max-width: 960px) {
  .login-form-side {
    padding: 1rem;
  }

  .login-card {
    padding: 2rem;
  }
}

.primary-text {
  background: linear-gradient(135deg, #0d2c6d 0%, #1a4ba5 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.custom-input {
  transition: all 0.3s ease;
}

.custom-input >>> .v-input__slot {
  border: 1px solid rgba(13, 44, 109, 0.1) !important;
  transition: all 0.3s ease;
}

.custom-input >>> .v-input__slot:hover {
  border-color: rgba(13, 44, 109, 0.2) !important;
  background: rgba(13, 44, 109, 0.03);
}

.custom-input.v-input--is-focused >>> .v-input__slot {
  border-color: #0d2c6d !important;
  box-shadow: 0 0 0 1px rgba(13, 44, 109, 0.2) !important;
  background: rgba(13, 44, 109, 0.02);
}


/* Forgot Password Button */
.forgot-password-btn {
  color: #0d2c6d !important;
  text-transform: none;
  font-size: 0.875rem;
  letter-spacing: 0.5px;
  opacity: 0.8;
  transition: all 0.3s ease;
}

.forgot-password-btn:hover {
  opacity: 1;
  background: rgba(13, 44, 109, 0.05) !important;
}
</style>