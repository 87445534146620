<template>
    <v-dialog v-model="openMode" max-width="600px">
      <v-card class="response-dialog">
        <!-- Header -->
        <div class="dialog-header">
          <v-icon size="32" color="#0d2c6d" class="mb-4">mdi-api</v-icon>
          <v-card-title class="text-h5 font-weight-bold primary-text pb-2">API Request Details</v-card-title>
        </div>
  
        <!-- Content -->
        <v-card-text class="response-content">
          <div v-if="data && Object.keys(data).length" dir="ltr" class="text-start">
            <v-expansion-panels>
              <!-- Iterate over the top-level keys (e.g., request, response) -->
              <v-expansion-panel
                v-for="(section, sectionName) in data"
                :key="sectionName"
                class="response-panel"
              >
                <v-expansion-panel-header class="text-subtitle-1 font-weight-medium">
                  {{ sectionName }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <!-- Iterate over the fields in each section -->
                  <div class="response-details">
                    <!-- Display key-value pairs -->
                    <template v-for="(value, key) in section">
                      <div v-if="typeof value !== 'object'" :key="key" class="detail-item">
                        <span class="detail-key">{{ key }}:</span>
                        <span class="detail-value">{{ value }}</span>
                      </div>
                      <!-- Recursively display nested objects -->
                      <div v-else :key="key" class="nested-object">
                        <div class="nested-header">{{ key }}:</div>
                        <div class="nested-content">
                          <div v-for="(nestedValue, nestedKey) in value" :key="nestedKey" class="detail-item">
                            <span class="detail-key">{{ nestedKey }}:</span>
                            <span class="detail-value">{{ nestedValue }}</span>
                          </div>
                        </div>
                      </div>
                    </template>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
          <div v-else class="empty-state">
            <v-icon size="48" color="rgba(13, 44, 109, 0.1)" class="mb-4">mdi-database-off</v-icon>
            <p class="text-subtitle-1 text-medium-emphasis">אין נתונים להצגה</p>
          </div>
        </v-card-text>
  
        <!-- Actions -->
        <v-card-actions class="dialog-actions">
          <v-btn
            text
            class="close-button"
            @click="closeCreate"
          >
            <v-icon left>mdi-close</v-icon>
            סגור
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  import SnackBar from '@/components/widgets/snackBar.vue'
  
  export default {
      props: {
          value: { type: Boolean, default: false },
          data: { type: Array },
      },
      components: {
          SnackBar,
      },
      data: () => ({
          tableName: "",
          progressShow: false,
          snackbar: false,
          snackbarColorBt: "green",
          snacbarText: "",
      }),
  
      methods: {
          closeCreate() {
              this.openMode = false;
          }
      },
      computed: {
          openMode: {
              get() {
                  return this.value
              },
              set(v) {
                  return this.$emit('input', v)
              }
          },
      },
  }
  </script>
  
  <style scoped>
  .response-dialog {
    border-radius: 16px;
    overflow: hidden;
    background: rgba(255, 255, 255, 0.98);
    backdrop-filter: blur(10px);
  }
  
  .dialog-header {
    padding: 1.5rem 1.5rem 0.5rem;
    text-align: center;
    background: linear-gradient(to bottom, rgba(13, 44, 109, 0.02), transparent);
  }
  
  .primary-text {
    background: linear-gradient(135deg, #0d2c6d 0%, #1a4ba5 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .response-content {
    max-height: 60vh;
    overflow-y: auto;
    padding: 1.5rem;
  }
  
  /* Custom Scrollbar */
  .response-content::-webkit-scrollbar {
    width: 8px;
  }
  
  .response-content::-webkit-scrollbar-track {
    background: rgba(13, 44, 109, 0.02);
    border-radius: 4px;
  }
  
  .response-content::-webkit-scrollbar-thumb {
    background: rgba(13, 44, 109, 0.1);
    border-radius: 4px;
  }
  
  .response-content::-webkit-scrollbar-thumb:hover {
    background: rgba(13, 44, 109, 0.2);
  }
  
  /* Response Panels */
  .response-panel {
    margin-bottom: 0.5rem;
    border: 1px solid rgba(13, 44, 109, 0.1) !important;
    border-radius: 8px !important;
    overflow: hidden;
  }
  
  .response-panel >>> .v-expansion-panel-header {
    padding: 1rem;
    background: rgba(13, 44, 109, 0.02);
  }
  
  .response-details {
    padding: 1rem;
  }
  
  .detail-item {
    display: flex;
    margin-bottom: 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
  }
  
  .detail-key {
    font-weight: 600;
    color: #0d2c6d;
    margin-right: 0.5rem;
    min-width: 120px;
  }
  
  .detail-value {
    color: #64748b;
    word-break: break-word;
  }
  
  .nested-object {
    margin: 1rem 0;
    padding: 1rem;
    background: rgba(13, 44, 109, 0.02);
    border-radius: 6px;
  }
  
  .nested-header {
    font-weight: 600;
    color: #0d2c6d;
    margin-bottom: 0.5rem;
  }
  
  .nested-content {
    padding-left: 1rem;
  }
  
  /* Empty State */
  .empty-state {
    text-align: center;
    padding: 3rem 1rem;
  }
  
  /* Dialog Actions */
  .dialog-actions {
    padding: 1rem 1.5rem;
    display: flex;
    justify-content: center;
  }
  
  /* Close Button */
  .close-button {
    color: #64748b !important;
    padding: 0 1.5rem !important;
    height: 40px;
    text-transform: none;
    letter-spacing: 0.5px;
    font-size: 0.9375rem;
    border-radius: 8px;
    transition: all 0.3s ease;
  }
  
  .close-button:hover {
    background: rgba(13, 44, 109, 0.05) !important;
    color: #0d2c6d !important;
  }
  </style>