<template>
  <div class="edit-table-wrapper">

    <!-- Header -->
    <div class="header-section">
      <div class="header-content">
        <div class="header-left">
          <v-btn icon @click="backToTableData" class="back-btn">
            <v-icon>mdi-arrow-right</v-icon>
          </v-btn>

          <!-- Editable Table Name -->
          <div class="table-name-section">
            <v-text-field v-model="tableName.hebrew_name" :rules="[v => !!v || 'שם הטבלה הוא שדה חובה']" hide-details
              outlined dense class="table-name-input" placeholder="שם הטבלה"></v-text-field>
          </div>
        </div>

        <div class="header-right">
          <div class="api-name-section">
            <span class="api-label">API Name:</span>
            <div class="api-value">
              {{ tableName.name }}
              <v-btn icon x-small @click="copyToClipboard(tableName.name)">
                <v-icon x-small>mdi-content-copy</v-icon>
              </v-btn>
            </div>
          </div>

          <v-btn outlined :color="$store.state.colorPrimary" class="action-btn mr-3" @click="exportJson">
            <v-icon left>mdi-code-json</v-icon>
            ייצוא JSON
          </v-btn>

          <v-btn :loading="progressShow" dark :disabled="!allowSave || !tableName.hebrew_name"
            :color="$store.state.colorPrimary" class="action-btn" @click="saveChanges">
            <v-icon left>mdi-content-save</v-icon>
            שמור שינויים
          </v-btn>
        </div>
      </div>
    </div>

    <!-- Main Content -->
    <div class="main-content">
      <!-- Settings Section -->
      <section class="settings-section">
        <div class="settings-header">
          <h2 class="section-title">הגדרות תצוגה</h2>
          <v-btn :color="$store.state.colorPrimary" outlined small @click="addFiled" class="add-field-btn">
            <v-icon left small>mdi-plus</v-icon>
            הוסף שדה
          </v-btn>
        </div>

        <div class="settings-toggles">
          <div v-for="setting in checkboxSettings" :key="setting.model" class="setting-toggle">
            <v-btn :color="settings[setting.model] ? $store.state.colorPrimary : 'grey'" dark
              :outlined="!settings[setting.model]" @click="settings[setting.model] = !settings[setting.model]"
              class="toggle-btn" small>
              <v-icon left small>{{ getSettingIcon(setting.model) }}</v-icon>
              {{ setting.label }}
            </v-btn>
          </div>
        </div>
      </section>

      <!-- Fields Section -->
      <section class="fields-section">
        <draggable v-model="fields" :handle="'.handle'" ghost-class="drag-ghost" drag-class="drag-active"
          :animation="200">
          <div v-for="(field, index) in fields" :key="index" class="field-card">
            <div class="field-header">
              <div class="field-drag handle">
                <v-icon small>mdi-drag</v-icon>
                <span class="field-number">{{ index + 1 }}</span>
              </div>

              <div class="field-type-badge" :class="getFieldTypeClass(field.type)">
                <v-icon small left>{{ getFieldTypeIcon(field.type) }}</v-icon>
                {{ field.type }}
              </div>
            </div>

            <div class="field-content">
              <div class="field-main">
                <v-text-field v-model="field.hebrew_name"
                  :disabled="['date_created', 'date_modified', 'id'].includes(field.name)" hide-details outlined dense
                  placeholder="שם השדה" label="שם השדה" class="field-name-input"></v-text-field>

                  <v-text-field  v-model="field.name"  @click:append="copyToClipboard(field.name)"  append-icon="mdi-content-copy"
                  readonly hide-details outlined dense
                  placeholder="API name" label="API name" class="field-name-input"></v-text-field>

                <v-select v-model="field.type" :items="fieldType"
                  :disabled="['date_created', 'date_modified', 'id'].includes(field.name)" hide-details dense outlined
                  class="field-type-select" label="סוג שדה" >
                  <template v-slot:selection="{ item }">
                    <v-icon left small>{{ getFieldTypeIcon(item) }}</v-icon>
                    {{ item }}
                  </template>
                </v-select>
              </div>

              <div class="field-actions">
                <div class="action-group">
                  <v-btn small :color="field.allowNull ? $store.state.colorPrimary : 'grey'" :dark="field.allowNull"
                    :outlined="!field.allowNull" @click="field.allowNull = !field.allowNull" class="action-btn"
                    :disabled="['date_created', 'date_modified', 'id'].includes(field.name)">
                    <v-icon left small>mdi-null</v-icon>
                    אפשר ריק
                  </v-btn>

                  <v-btn small :color="field.unique ? $store.state.colorPrimary : 'grey'" :outlined="!field.unique"
                    @click="field.unique = !field.unique" class="action-btn" :dark="field.unique"
                    :disabled="['date_created', 'date_modified', 'id'].includes(field.name)">
                    <v-icon left small>mdi-key-variant</v-icon>
                    ייחודי
                  </v-btn>

                  <v-btn small :color="field.show ? $store.state.colorPrimary : 'grey'" :outlined="!field.show" dark
                    @click="field.show = !field.show" class="action-btn">
                    <v-icon left small>{{ field.show ? 'mdi-eye' : 'mdi-eye-off' }}</v-icon>
                    {{ field.show ? 'מוצג' : 'מוסתר' }}
                  </v-btn>
                </div>

                <!-- Additional Actions -->
                <div class="additional-actions">
                  <v-menu v-if="field.type === 'מספר'" offset-y :close-on-content-click="true">
                    <template v-slot:activator="{ on }">
                      <v-btn icon small v-on="on" class="ml-2" :disabled="['id'].includes(field.name)">
                        <v-icon small>{{ getCurrencyIcon(field.coin) }}</v-icon>
                      </v-btn>
                    </template>
                    <v-list dense>
                      <v-list-item v-for="coin in coins" :key="coin" @click="field.coin = choosedCoin(coin)">
                        <v-list-item-icon>
                          <v-icon small>{{ getCurrencyIcon(coin) }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>{{ coin }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <v-btn v-if="field.type === 'רשימה'" icon small :color="$store.state.colorPrimary"
                    @click="openListField(field, index)">
                    <v-icon>mdi-format-list-bulleted</v-icon>
                  </v-btn>

                  <v-btn v-if="field.type === 'רשימה מקושרת'" icon small :color="$store.state.colorPrimary"
                    @click="openListLinkedField(field, index)">
                    <v-icon>mdi-link-variant</v-icon>
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
        </draggable>
      </section>
    </div>

    <!-- Dialogs -->
    <ListDialog v-model="listDialog" v-if="listDialog" :field="fieldEditList" :fieldIndex="fieldIndex"
      @listUpdated="listUpdated" />

    <ListLinkedDialog v-model="listLinkedDialog" v-if="listLinkedDialog" :field="fieldEditListLinked"
      :fieldIndex="fieldIndex" @listLinkedUpdated="listLinkedUpdated" />

    <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt" />
  </div>
</template>



<script>
import ListDialog from '@/components/user/editTable/dialogs/ListDialog'
import ListLinkedDialog from '@/components/user/editTable/dialogs/ListLinkedDialog'
import ApiServices from '@/services/api.service'
import SnackBar from '@/components/widgets/snackBar.vue'
import Auth from '@/services/auth.service.js'
import draggable from 'vuedraggable';

export default {
  components: {
    SnackBar,
    draggable,
    ListDialog,
    ListLinkedDialog
  },
  props: {
    tableName: Object,
    value: { type: Boolean, default: false },
  },
  data: () => ({
    fields: [],
    progressShow: true,
    snackbar: false,
    snackbarColorBt: "green",
    snacbarText: "",
    allowSave: true,
    fieldType: ["טקסט", "מספר", "תאריך", "תאריך ושעה", "רשימה", "רשימה מקושרת", "לינק"],
    listDialog: false,
    fieldEditList: {},
    fieldIndex: 0,
    fieldEditListLinked: {},
    listLinkedDialog: false,
    coins: ['לא נבחר', 'שקל', 'דולר', 'אירו'],
    isMenuOpen: [], // Initially, the menu is closed
    isStyleMenuOpen: false,
    settings: { multiple_selection: true, expended_rows: false, show_copy_icon: false },
    checkboxSettings: [
      {
        model: "multiple_selection",
        label: "אפשר מחיקה מרובה",
      },
      {
        model: "expended_rows",
        label: "הרחב שורות",
      },
      {
        model: "show_copy_icon",
        label: "הצגת אייקון העתק",
      },
    ],
  }),
  computed: {
    openMode: {
      get() {
        return this.value
      },
      set(v) {
        return this.$emit('input', v)
      }
    },
  },
  methods: {
    toggleMenu(index) {
      this.$set(this.isMenuOpen, index, !this.isMenuOpen[index]); // Toggle the menu state using Vue's $set method
    },
    toggleStyleMenu() {
      this.isStyleMenuOpen = !!this.isStyleMenuOpen;
    },
    getCurrencyIcon(currency) {
      switch (currency) {
        case "שקל":
          return "mdi-currency-ils";
        case "דולר":
          return "mdi-currency-usd";
        case "אירו":
          return "mdi-currency-eur";
        case "₪":
          return "₪";
        case "$":
          return "$";
        case "€":
          return "€";
        default:
          return "mdi-hand-coin-outline";
      }
    },
    choosedCoin(item) {
      switch (item) {
        case "שקל":
          return "₪";
        case "דולר":
          return "$";
        case "אירו":
          return "€";
        default:
          return "";
      }
    },
    getFieldTypeClass(type) {
      switch (type) {
        case 'טקסט': return 'type-text'
        case 'מספר': return 'type-number'
        case 'תאריך': return 'type-date'
        case 'רשימה': case 'רשימה מקושרת': return 'type-list'
        case 'לינק': return 'type-link'
        default: return 'type-text'
      }
    },

    getFieldTypeIcon(type) {
      switch (type) {
        case 'טקסט': return 'mdi-form-textbox'
        case 'מספר': return 'mdi-numeric'
        case 'תאריך': return 'mdi-calendar'
        case 'תאריך ושעה': return 'mdi-calendar-clock'
        case 'רשימה': return 'mdi-format-list-bulleted'
        case 'רשימה מקושרת': return 'mdi-link-variant'
        case 'לינק': return 'mdi-link'
        default: return 'mdi-form-textbox'
      }
    },
    getSettingIcon(model) {
      switch (model) {
        case 'multiple_selection':
          return 'mdi-checkbox-multiple-marked-outline'
        case 'expended_rows':
          return 'mdi-table-row-height'
        case 'show_copy_icon':
          return 'mdi-content-copy'
        default:
          return 'mdi-cog'
      }
    },
    handleFieldTypeAction(field, index) {
      if (field.type === 'רשימה') {
        this.openListField(field, index)
      } else if (field.type === 'רשימה מקושרת') {
        this.openListLinkedField(field, index)
      } else if (field.type === 'מספר') {
        this.toggleMenu(index)
      }
    },
    copyToClipboard(text) {
    console.log(121111);
    
      navigator.clipboard.writeText(text)
        .then(() => {
          this.showSnackBar("הועתק " + text, "green");
          // You can optionally show a success message to the user here
        })
        .catch((error) => {
          console.error('Error copying text to clipboard:', error);
          // You can optionally show an error message to the user here
        });
    },
    exportJson() {
      let jsonObject = {};
      for (const field of this.fields) {
        jsonObject[field.name] = '';
      }
      jsonObject = JSON.stringify(jsonObject, null, 2)
      navigator.clipboard.writeText(jsonObject)
        .then(() => {
          this.showSnackBar("הועתק " + "json", "green");
          // You can optionally show a success message to the user here
        })
        .catch((error) => {
          console.error('Error export json to clipboard:', error);
          // You can optionally show an error message to the user here
        });
    },
    async getData() {
      try {
        this.fields = [];
        this.progressShow = true;
        this.allowSave = false;
        let token = localStorage.getItem("token");
        const tableJSON = JSON.stringify({ tableName: this.tableName.name });

        let api = process.env.VUE_APP_BASE_URL + "/user/get_table_properties";
        const res = await fetch(api, ApiServices.requestOptions("POST", tableJSON, token));
        const jsonObject = await res.json();
        this.progressShow = false;
        if (res.status >= 400) {
          this.showSnackBar(" שגיאה בקבלת נתונים" + jsonObject.message, "red");
        } else if (res.status === 200) {
          this.allowSave = true;
          this.fields = jsonObject.fieldsData;
          this.settings = jsonObject.settingsData;
          this.isMenuOpen = this.fields.map(() => false);
        }
      } catch (error) {
        this.progressShow = false;
        this.showSnackBar("Error get items list: " + error, "red");
      }
    },
    isFieldNameValid(field) {
      return !field.includes('"');
    },
    showSnackBar(message, color) {
      this.snacbarText = message;
      this.snackbarColorBt = color;
      this.snackbar = true
    },
    addFiled() {
      const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      let fieldName = '';
      for (let i = 0; i < 2; i++) {
        const randomIndex = Math.floor(Math.random() * chars.length);
        fieldName += chars.charAt(randomIndex);
      }
      fieldName += "_"
      this.fields.push({ name: fieldName, hebrew_name: "", allowNull: true, unique: false, show: true, type: "טקסט", list: [], linkedList: {}, coin: "" });
    },
    async saveChanges() {
      const fieldsJSON = JSON.stringify({ fields: this.fields, settings: this.settings, tableName: this.tableName });
      this.progressShow = true;
      let token = localStorage.getItem("token");
      try {
        let api = process.env.VUE_APP_BASE_URL + "/user/update_table_properties";
        const res = await fetch(api, ApiServices.requestOptions("PUT", fieldsJSON, token));
        this.progressShow = false;
        const jsonObject = await res.json();
        if (res.status >= 400) {
          console.log(res);
          this.showSnackBar(" שגיאה בעדכון שדות " + jsonObject.message, "red");
        } else if (res.status === 200) {
          this.showSnackBar("הפרטים נשמרו בהצלחה!", "green");
          setTimeout(() => {
            this.$emit('backToTableData', this.tableName)
          }, 800);
        }
      } catch (error) {
        this.progressShow = false;
        this.showSnackBar("Error saved fields: " + error, "red");

      }
    },
    backToTableData() {
      this.$emit('backToTableData', this.tableName)
    },
    openListField(item, index) {
      this.fieldEditList = item
      this.fieldIndex = index;
      this.listDialog = true;
    },
    openListLinkedField(item, index) {
      this.fieldEditListLinked = item
      this.fieldIndex = index;
      this.listLinkedDialog = true;
    },
    listUpdated(fieldListUpdated, index) {
      this.fields[index] = fieldListUpdated;
    },
    listLinkedUpdated(fieldListLinkedUpdated, index) {
      this.fields[index] = fieldListLinkedUpdated;
    },
    validateTableName(name) {
      // Add any validation logic you need
      return name.length >= 2; // Example: require at least 2 characters
    },
  },
  watch: {
    tableName: {
      handler() {
        this.getData()
      },
      immediate: true
    }
  },
  created() {
    let auth = Auth.checkAuth();
    if (!auth) {
      this.$router.replace({ name: "login" })
    }
    this.getData();
  },
}
</script>
<style scoped>
/* Base Styles */
.edit-table-wrapper {
  min-height: 100vh;
  background: #f8fafc;
}

/* Header Styles */
.header-section {
  margin-top: 60px;
  background: white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.header-content {
  max-width: 1400px;
  margin: 0 auto;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

@media (min-width: 768px) {
  .header-content {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;
  }
}

.header-left {
  display: flex;
  align-items: center;
  width: 100%;
}

.header-right {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
}

@media (min-width: 768px) {
  .header-right {
    flex-direction: row;
    align-items: center;
    width: auto;
  }
}

.table-title {
  font-size: 1.25rem;
  font-weight: 600;
  margin: 0 16px;
  color: #1a202c;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (min-width: 768px) {
  .table-title {
    font-size: 1.5rem;
  }
}

.api-name-section {
  background: #f8fafc;
  padding: 8px 12px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
}

@media (min-width: 768px) {
  .api-name-section {
    width: auto;
  }
}

/* Main Content */
.main-content {
  max-width: 1400px;
  margin: 0 auto;
  padding: 140px 16px 40px;
}

@media (min-width: 768px) {
  .main-content {
    padding: 20px 24px 40px;
  }
}

/* Settings Section */
.settings-section {
  background: white;
  border-radius: 12px;
  padding: 16px;
  margin-bottom: 16px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

@media (min-width: 768px) {
  .settings-section {
    padding: 24px;
    margin-bottom: 24px;
  }
}

.settings-header {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 16px;
}

@media (min-width: 768px) {
  .settings-header {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.settings-toggles {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 8px;
}

@media (min-width: 768px) {
  .settings-toggles {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
  }
}

/* Fields Section */
.fields-section {
  background: white;
  border-radius: 12px;
  padding: 16px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

@media (min-width: 768px) {
  .fields-section {
    padding: 24px;
  }
}

.field-card {
  background: #f8fafc;
  border-radius: 8px;
  margin-bottom: 12px;
  transition: all 0.2s ease;
}

.field-content {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

@media (min-width: 1024px) {
  .field-content {
    flex-direction: row;
  }
}

.field-main {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

@media (min-width: 768px) {
  .field-main {
    flex-direction: row;
  }
}

.field-actions {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

@media (min-width: 768px) {
  .field-actions {
    flex-direction: row;
    align-items: center;
  }
}

.action-group {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 8px;
}

@media (min-width: 768px) {
  .action-group {
    display: flex;
  }
}

.action-btn {
  width: 100%;
  text-transform: none;
  letter-spacing: 0;
  white-space: nowrap;
}

@media (min-width: 768px) {
  .action-btn {
    width: auto;
  }
}

.additional-actions {
  display: flex;
  justify-content: center;
  gap: 8px;
}

@media (min-width: 768px) {
  .additional-actions {
    justify-content: flex-start;
  }
}

/* Type Badge Colors - Remain unchanged */
.type-text {
  background: #e5e7eb;
  color: #374151;
}

.type-number {
  background: #dbeafe;
  color: #1e40af;
}

.type-date {
  background: #fef3c7;
  color: #92400e;
}

.type-list {
  background: #dcfce7;
  color: #166534;
}

.type-link {
  background: #f3e8ff;
  color: #6b21a8;
}

/* RTL Support */
.v-application {
  direction: rtl;
}

/* Drag and Drop */
.drag-ghost {
  opacity: 0.5;
  background: #f8fafc;
  border: 2px dashed #0d2c6d;
}

.drag-active {
  background: white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* Mobile Optimizations */
@media (max-width: 767px) {
  .field-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }

  .field-type-badge {
    margin-right: 0;
    align-self: flex-start;
  }

  .field-drag {
    width: 100%;
    justify-content: space-between;
  }

  .action-btn {
    padding: 0 8px;
    height: 32px;
  }

  .action-btn .v-icon {
    margin-right: 4px;
    font-size: 18px;
  }

  .table-title {
    max-width: calc(100vw - 120px);
  }

  .api-value {
    max-width: calc(100vw - 150px);
    overflow: hidden;
    text-overflow: ellipsis;
  }
}


.table-name-section {
  flex: 1;
  max-width: 300px;
  margin: 0 16px;
}

.table-name-input {
  font-size: 1.25rem !important;
  font-weight: 600 !important;
}

.table-name-input>>>.v-input__slot {
  min-height: 40px !important;
}

@media (max-width: 767px) {
  .table-name-section {
    max-width: 100%;
    margin: 8px 0;
  }

  .table-name-input>>>.v-input__slot {
    min-height: 36px !important;
  }

  .table-name-input {
    font-size: 1.1rem !important;
  }
}
</style>
