<template>
    <v-dialog v-model="openMode" max-width="480px" persistent>
        <v-card class="create-operation-card">
            <!-- Header -->
            <div class="dialog-header">
                <v-icon size="32" color="#0d2c6d" class="mb-4">mdi-plus-circle</v-icon>
                <v-card-subtitle class="text-h5 font-weight-bold primary-text pb-2">
                    יצירת פעולה חדשה
                </v-card-subtitle>
                <v-card-subtitle class="text-subtitle-1 text-medium-emphasis text-center">
                    הזן שם לפעולה החדשה שברצונך ליצור
                </v-card-subtitle>
            </div>

            <!-- Content -->
            <v-card-text class="pt-4">
                <v-form ref="form" @submit.prevent="actionConfirmed">
                    <v-text-field v-model="operationName" label="שם הפעולה" :rules="[validateOperationName]"
                        :error-messages="!isTableNameValid ? 'שם הפעולה לא יכול להכיל גרשיים' : ''" outlined dense
                        autofocus dir="rtl" class="custom-input" color="#0d2c6d"
                        background-color="rgba(13, 44, 109, 0.02)" prepend-inner-icon="mdi-format-title" required>
                    </v-text-field>
                </v-form>
            </v-card-text>

            <!-- Loading Indicator -->
            <v-row v-if="progressShow" class="my-4">
                <v-col class="d-flex justify-center">
                    <v-progress-circular indeterminate color="#0d2c6d" size="32"></v-progress-circular>
                </v-col>
            </v-row>

            <!-- Actions -->
            <v-card-actions class="dialog-actions">
                <v-btn text class="cancel-button" @click="closeCreate" :disabled="progressShow">
                    <v-icon left>mdi-close</v-icon>
                    ביטול
                </v-btn>
                <v-btn class="create-button" @click="actionConfirmed" :loading="progressShow" :dark="!isTableNameValid || !operationName || progressShow"
                    :disabled="!isTableNameValid || !operationName || progressShow">
                    <v-icon left v-if="!isTableNameValid || !operationName || progressShow">mdi-close</v-icon>
                    <v-icon left v-else>mdi-check</v-icon>
                                        צור פעולה
                </v-btn>
            </v-card-actions>
        </v-card>

        <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>
    </v-dialog>
</template>


<script>
import ApiServices from '@/services/api.service'
import SnackBar from '@/components/widgets/snackBar.vue'

export default {
    props: {
        tableName: Object,
        trigerName: String,
        value: { type: Boolean, default: false },
    },
    components: {
        SnackBar,
    },
    data: () => ({
        progressShow: false,
        snackbar: false,
        snackbarColorBt: "green",
        snacbarText: "",
        operationName: ""
    }),

    methods: {
        actionConfirmed() {
            this.CreateOperation();
        },

        async CreateOperation() {
            if (!this.isTableNameValid) {
                this.$emit("operationCreated", "", 'שם הפעולה אינו יכול להכיל תו "', "red");
                return
            }
            if (this.operationName === "") {
                this.$emit("operationCreated", "", 'שדה שם לא יכול להישאר ריק', "red");
                return
            }
            try {
                this.progressShow = true;
                let token = localStorage.getItem("token");
                const taskJSON = JSON.stringify({ tableName: this.tableName.name, operationName: this.operationName, trigerName: this.trigerName });
                let api = process.env.VUE_APP_BASE_URL + "/user/create_crm_operation";
                const res = await fetch(api, ApiServices.requestOptions("POST", taskJSON, token));
                this.progressShow = false;
                const jsonObject = await res.json();
                if (res.status >= 400) {
                    this.$emit("operationCreated", "", "שגיאה ביצירת פעולה " + jsonObject.message, "red");
                } else if (res.status === 200) {
                    this.$emit("operationCreated", "", "הפעולה נוצרה בהצלחה!", "green");
                    this.openMode = false;
                }
            } catch (error) {
                this.progressShow = false;
                console.log(error);
                this.$emit("operationCreated", "", "שגיאה ביצירת פעולה", "red");
            }
        },
        methods: {
            validateOperationName(value) {
                if (!value || value.trim() === '') return 'שדה חובה';
                if (value.includes('"')) return 'שם הפעולה לא יכול להכיל גרשיים';
                if (value.length > 32) return 'שם הפעולה ארוך מדי';
                return true;
            }
        },
        closeCreate() {
            this.openMode = false;
        }
    },
    computed: {
        openMode: {
            get() {
                return this.value
            },
            set(v) {
                return this.$emit('input', v)
            }
        },
        isTableNameValid() {
            return !this.operationName.includes('"');
        },
    },
}
</script>
<style scoped>
.create-operation-card {
    border-radius: 16px;
    overflow: hidden;
    background: rgba(255, 255, 255, 0.98);
    backdrop-filter: blur(10px);
}

.dialog-header {
    padding: 1.5rem 1.5rem 0.5rem;
    text-align: center;
    background: linear-gradient(to bottom, rgba(13, 44, 109, 0.02), transparent);
}

.primary-text {
    background: linear-gradient(135deg, #0d2c6d 0%, #1a4ba5 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

/* Custom Input Styling */
.custom-input {
    transition: all 0.3s ease;
}

.custom-input>>>.v-input__slot {
    border: 1px solid rgba(13, 44, 109, 0.1) !important;
    transition: all 0.3s ease;
}

.custom-input>>>.v-input__slot:hover {
    border-color: rgba(13, 44, 109, 0.2) !important;
    background: rgba(13, 44, 109, 0.03);
}

.custom-input.v-input--is-focused>>>.v-input__slot {
    border-color: #0d2c6d !important;
    box-shadow: 0 0 0 1px rgba(13, 44, 109, 0.2) !important;
    background: rgba(13, 44, 109, 0.02);
}

/* Dialog Actions */
.dialog-actions {
    padding: 1rem 1.5rem;
    display: flex;
    justify-content: center;
    gap: 1rem;
}

/* Buttons */
.create-button {
    background: linear-gradient(135deg, #0d2c6d 0%, #1a4ba5 100%) !important;
    color: white !important;
    padding: 0 1.5rem !important;
    height: 40px;
    text-transform: none;
    letter-spacing: 0.5px;
    font-size: 0.9375rem;
    font-weight: 500;
    border-radius: 8px;
    transition: all 0.3s ease;
}

.create-button:hover:not(:disabled) {
    opacity: 0.95;
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(13, 44, 109, 0.2) !important;
}

.cancel-button {
    color: #64748b !important;
    padding: 0 1.5rem !important;
    height: 40px;
    text-transform: none;
    letter-spacing: 0.5px;
    font-size: 0.9375rem;
    border-radius: 8px;
    transition: all 0.3s ease;
}

.cancel-button:hover:not(:disabled) {
    background: rgba(13, 44, 109, 0.05) !important;
    color: #0d2c6d !important;
}

/* RTL Support */
.v-application {
    direction: rtl;
}

.v-text-field>>>.v-input__prepend-inner {
    margin-right: 0;
    margin-left: 8px;
}

.v-text-field>>>.v-input__append-inner {
    margin-left: 0;
    margin-right: 8px;
}
</style>