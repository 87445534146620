<template>
  <div>
    <nav-bar></nav-bar>
    <v-container class="contact-container" fluid>
      <v-row justify="center" align="center">
        <v-col cols="12" sm="10" md="8" lg="6">
          <v-card elevation="3" class="contact-card">
            <!-- Header Section -->
            <v-card-title class="contact-header text-center d-block pa-6">
              <h1 class="display-1 font-weight-bold primary--text">צור קשר</h1>
              <v-divider class="mx-4 my-3"></v-divider>
              <p class="subtitle-1 grey--text text--darken-1">
                אנחנו כאן לעזור! שלח לנו הודעה ונחזור אליך בהקדם
              </p>
            </v-card-title>

            <v-card-text>
              <v-form @submit.prevent="submitForm" ref="form" v-model="valid">

                <!-- Ticket Type and Priority Row -->
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-select v-model="ticketType" :items="ticketTypes" label="סוג הפנייה"
                      prepend-inner-icon="mdi-ticket" :rules="[v => !!v || 'נא לבחור סוג פנייה']" required outlined
                      dense dir="rtl"></v-select>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-select v-model="priority" :items="priorities" label="דחיפות" prepend-inner-icon="mdi-flag"
                      :rules="[v => !!v || 'נא לבחור דחיפות']" required outlined dense dir="rtl"></v-select>
                  </v-col>
                </v-row>

                <!-- Subject Field -->
                <v-text-field v-model="subject" label="נושא" prepend-inner-icon="mdi-text-subject"
                  :rules="[v => !!v || 'נא להזין נושא']" required outlined dense class="mb-4" dir="rtl"></v-text-field>

                <!-- Message Field -->
                <v-textarea v-model="message" label="הודעה" prepend-inner-icon="mdi-message-text"
                  :rules="[v => !!v || 'נא להזין הודעה']" required outlined auto-grow rows="4" class="mb-4" dir="rtl"
                  :hint="getMessageHint" persistent-hint></v-textarea>

                <!-- File Attachment -->
                <v-file-input v-model="attachments" label="צרף קבצים" prepend-inner-icon="mdi-paperclip" outlined dense
                  multiple chips small-chips class="mb-4" dir="rtl" :rules="attachmentRules"
                  accept="image/*, .pdf, .doc, .docx"></v-file-input>

                <!-- Submit Button -->
                <v-card-actions class="pa-0">
                  <v-spacer></v-spacer>
                  <v-btn :loading="progressShow" :disabled="!valid || progressShow" color="primary" large type="submit"
                    class="px-6">
                    <v-icon left>mdi-send</v-icon>
                    שלח הודעה
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-form>
            </v-card-text>

            <!-- Contact Info -->
            <v-card-text class="pt-0">
              <v-divider class="my-4"></v-divider>
              <div class="contact-info text-center">
                <v-row>
                  <v-col cols="12" sm="4">
                    <v-icon color="primary" large>mdi-phone</v-icon>
                    <div class="subtitle-1 mt-2">טלפון</div>
                    <div class="body-2 grey--text">072-382-2822</div>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-icon color="primary" large>mdi-email</v-icon>
                    <div class="subtitle-1 mt-2">אימייל</div>
                    <div class="body-2 grey--text">office@clickcall.com</div>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-icon color="primary" large>mdi-map-marker</v-icon>
                    <div class="subtitle-1 mt-2">כתובת</div>
                    <div class="body-2 grey--text"> ירושלים, ישראל</div>
                  </v-col>
                </v-row>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-snackbar v-model="snackbar" :color="snackbarColorBt" :timeout="6000" top right>
      {{ snacbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">
          סגור
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Auth from '@/services/auth.service.js'
import NavBar from '@/components/widgets/navBar.vue'
import ApiServices from '@/services/api.service'

export default {
  name: 'ContactUs',
  components: {
    NavBar
  },
  data() {
    return {
      valid: false,
      subject: '',
      message: '',
      ticketType: '',
      priority: '',
      attachments: [],
      progressShow: false,
      snackbar: false,
      snackbarColorBt: "success",
      snacbarText: "",
      attachmentRules: [
        files => !files || !files.length || files.length <= 5 || 'מקסימום 5 קבצים',
        files => !files || !files.some(file => file.size > 5000000) || 'גודל קובץ מקסימלי הוא 5MB'
      ],
      ticketTypes: [
        { text: 'דיווח על תקלה', value: 'bug' },
        { text: 'בקשת שיפור', value: 'improvement' },
        { text: 'שאלה כללית', value: 'question' },
        { text: 'בקשת תמיכה', value: 'support' },
        { text: 'אחר', value: 'other' }
      ],
      priorities: [
        { text: 'דחוף', value: 'high' },
        { text: 'בינוני', value: 'medium' },
        { text: 'נמוך', value: 'low' }
      ]
    }
  },
  computed: {
    getMessageHint() {
      if (this.ticketType === 'bug') {
        return 'אנא תאר את התקלה בפירוט, כולל שלבים לשחזור והתנהגות צפויה';
      } else if (this.ticketType === 'improvement') {
        return 'אנא תאר את השיפור המבוקש ומדוע הוא יהיה שימושי';
      }
      return '';
    }
  },
  methods: {
    async submitForm() {
      if (this.$refs.form.validate()) {
        let token = localStorage.getItem("token");
        this.progressShow = true;

        const formData = new FormData();
        formData.append('subject', this.subject);
        formData.append('message', this.message);
        formData.append('ticketType', this.ticketType);
        formData.append('priority', this.priority);

        // Append attachments if any
        if (this.attachments) {
          this.attachments.forEach((file, index) => {
            formData.append(`attachment${index}`, file);
          });
        }

        let api = process.env.VUE_APP_BASE_URL + "/user/contact_us";

        try {
          const response = await fetch(api, {
            ...ApiServices.requestOptions("POST", formData, token),
            // Override headers for FormData
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });

          const jsonObject = await response.json();

          if (response.status >= 400) {
            this.showSnackBar("שגיאת שליחה: " + jsonObject.message, "error");
          } else if (response.status === 200) {
            this.showSnackBar("הודעתך נשלחה בהצלחה! נחזור אליך בהקדם", "success");
            this.$refs.form.reset();
          }
        } catch (error) {
          this.showSnackBar("שגיאה בשליחת ההודעה: " + error, "error");
        } finally {
          this.progressShow = false;
        }
      }
    },
    showSnackBar(message, color) {
      this.snacbarText = message;
      this.snackbarColorBt = color;
      this.snackbar = true;
    }
  },
  mounted() {
    document.title = 'צור קשר | CLICKCALL';

    let auth = Auth.checkAuth();
    if (!auth) {
      this.$router.replace({ name: "login" });
    }
  }
}
</script>

<style lang="scss" scoped>
.contact-container {
  min-height: calc(100vh - 64px);
  padding-top: 64px;
  background-color: #f5f5f5;
}

.contact-card {
  border-radius: 8px;
  overflow: hidden;
}

.contact-header {
  background: linear-gradient(to left, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95));
}

.contact-info {
  .v-icon {
    transition: transform 0.3s ease;

    &:hover {
      transform: scale(1.1);
    }
  }
}

// RTL specific styles
:deep(.v-text-field),
:deep(.v-select),
:deep(.v-file-input) {
  text-align: right;

  .v-label {
    right: 0;
    left: auto;
  }

  .v-input__prepend-inner {
    margin-right: 0;
    margin-left: 8px;
  }
}

:deep(.v-textarea) {
  text-align: right;
}

// Responsive adjustments
@media (max-width: 600px) {
  .contact-container {
    padding-top: 32px;
  }

  .contact-info {
    .v-col {
      margin-bottom: 24px;
    }
  }
}
</style>