<template>
  <v-container fluid class="crm-integration pa-0" style="margin-top: 70px; margin-bottom: 70px;" v-if="!taskSettings">
    <v-row class="justify-center">
      <v-col cols="12" lg="10">
        <v-card class="main-card elevation-3">
          <!-- Header -->
          <div class="header-section">
            <v-btn @click="backToTableData()" icon class="back-button ml-4">
              <v-icon>mdi-arrow-right</v-icon>
            </v-btn>
            <h1 class="text-h4 font-weight-bold primary-text mb-0">
              תזמון משימות - {{ tableName.hebrew_name }}
            </h1>
          </div>

          <v-divider></v-divider>

          <!-- Tasks List -->
          <div class="content-section">
            <div class="d-flex align-center justify-space-between mb-6">
              <div class="tasks-counter">
                <h2 class="text-h6 font-weight-medium mb-0">
                  משימות:
                  <span class="counter-badge">{{ tasks.length }}</span>
                </h2>
              </div>
              <v-btn color="primary" @click="createDialog = true" class="add-task-btn" elevation="2">
                <v-icon left>mdi-plus</v-icon>
                הוסף משימה
              </v-btn>
            </div>

            <v-row>
              <v-col cols="12" sm="6" md="4" v-for="(task, index) in tasks" :key="index">
                <v-hover v-slot="{ hover }">
                  <v-card class="task-card" :class="{ 'on-hover': hover }">
                    <div class="task-header">
                      <h3 class="task-title">{{ task.name }}</h3>
                    </div>

                    <v-card-text class="task-content">
                      <div v-if="task.action" class="task-details">
                        <div class="task-info">
                          <div class="info-item">
                            <v-icon small color="primary" class="info-icon">mdi-flash</v-icon>
                            <span class="action-text">{{ actionNames[task.action] }}</span>
                          </div>
                          <div class="info-item">
                            <v-icon small color="primary" class="info-icon">mdi-clock-outline</v-icon>
                            <span class="trigger-text">{{trigerNames[task.triger] }}</span>
                          </div>
                        </div>
                        <div class="status-section">
                          <div class="status-chip" :class="task.mode === 'on' ? 'status-active' : 'status-inactive'">
                            <span class="status-label">מצב:</span>
                            <span class="status-value">{{ task.mode }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="empty-state" v-else>
                        <v-icon color="grey" size="24">mdi-alert-circle-outline</v-icon>
                        <span>לא הוגדרה משימה</span>
                      </div>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions class="task-actions">
                      <v-btn text color="primary" @click="openTaskSettings(task, index)" class="edit-btn">
                        <v-icon left size="20">mdi-cog</v-icon>
                        ערוך משימה
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-hover>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

    <CreateDialog v-model="createDialog" v-if="createDialog" @taskCreated="taskCreated" :tableName="tableName" />
  </v-container>

  <TaskSettings v-model="taskSettings" v-else :taskToEdit="taskToEdit" :actions="actions" :trigers="trigers"
    @taskUpdated="taskUpdated" :tableName="tableName" @tableCopied="tableCopied" />
</template>

<script>
import ApiServices from '@/services/api.service.js'
import SnackBar from '@/components/widgets/snackBar.vue'
import Auth from '@/services/auth.service.js'
import CreateDialog from '@/components/user/taskSchedule/CreateTaskDialog'
import TaskSettings from '@/components/user/taskSchedule/TaskSettings'

export default {
  components: {
    SnackBar,
    CreateDialog,
    TaskSettings
  },
  props: {
    tableName: Object,
    value: { type: Boolean, default: false },
  },
  data: () => ({
    tasks: [],
    actions: [],
    trigers: [],
    snackbar: false,
    snackbarColorBt: "green",
    snacbarText: "",
    createDialog: false,
    taskSettings: false,
    taskToEdit: {},
    actionNames: {
      "delete_row": "מחיקת רשומה",
      "update_field": "עדכון רשומה",
      "forward_row": "העברת רשומה",
    },
    trigerNames: {
      "after_days": "מספר ימים ממועד היצירה",
      "after_hours": "מספר שעות ממועד היצירה",
      "after_minutes": "מספר דקות ממועד היצירה",
      "custome_date_field":"תאריך משדה אחר",
    },
  }),
  computed: {
    openMode: {
      get() {
        return this.value
      },
      set(v) {
        return this.$emit('input', v)
      }
    },
  },
  methods: {
    async getTasks() {
      try {
        this.tasks = [];
        let token = localStorage.getItem("token");
        const tableJSON = JSON.stringify({ tableName: this.tableName.name });

        let api = process.env.VUE_APP_BASE_URL + "/user/get_table_tasks";
        const res = await fetch(api, ApiServices.requestOptions("POST", tableJSON, token));
        const jsonObject = await res.json();
        if (res.status >= 400) {
          this.showSnackBar(" שגיאה בקבלת נתונים" + jsonObject.message, "red");
        } else if (res.status === 200) {
          this.tasks = jsonObject.tasks;
          this.actions = jsonObject.actions;
          this.trigers = jsonObject.trigers;
        }
      } catch (error) {
        this.showSnackBar("Error get tasks list: " + error, "red");
      }
    },
    showSnackBar(message, color) {
      this.snacbarText = message;
      this.snackbarColorBt = color;
      this.snackbar = true
    },
    taskCreated(item, text, color) {
      this.showSnackBar(text, color);
      this.$emit('reloadTasksList')
    },
    openTaskSettings(task, index) {
      this.taskToEdit = task;
      this.taskSettings = true
    },
    taskUpdated(task, text, color) {
      this.showSnackBar(text, color);
      this.taskSettings = false;
      setTimeout(() => {
        this.$emit('reloadTasksList')
      }, 600);
    },
    tableCopied() {
      this.$emit('reloadTableList')
    },
    backToTableData() {
      this.$emit('backToTableData', this.tableName)
    },
  },
  mounted() {
    let auth = Auth.checkAuth();
    if (!auth) {
      this.$router.replace({ name: "login" })
    }
    this.getTasks();
  },
}
</script>

<style scoped>
.crm-integration {
  background: linear-gradient(135deg, #f8fafc 0%, #f0f7ff 100%);
}

.main-card {
  border-radius: 16px;
  overflow: hidden;
  background: #ffffff;
  box-shadow: 0 4px 24px rgba(13, 44, 109, 0.08) !important;
}

.header-section {
  padding: 2rem;
  background: linear-gradient(to right, #f8fafc, #ffffff);
  display: flex;
  align-items: center;
}

.back-button {
  background: rgba(13, 44, 109, 0.05);
  transition: all 0.3s ease;
  margin-left: 1rem;
}

.back-button:hover {
  background: rgba(13, 44, 109, 0.1);
  transform: translateX(2px);
}

.primary-text {
  color: #1a237e;
  font-size: 2rem;
}

.content-section {
  padding: 2rem;
}

.tasks-counter {
  display: flex;
  align-items: center;
}

.counter-badge {
  background: rgba(13, 44, 109, 0.1);
  color: #1a237e;
  padding: 0.25rem 0.75rem;
  border-radius: 1rem;
  margin-right: 0.5rem;
  font-weight: 500;
}

.add-task-btn {
  background: linear-gradient(135deg, #1a237e 0%, #3949ab 100%) !important;
  color: white;
  padding: 0.5rem 1.5rem;
  border-radius: 8px;
  text-transform: none;
  font-weight: 500;
  letter-spacing: 0;
}

.task-card {
  border-radius: 12px;
  transition: all 0.3s ease;
  border: 1px solid rgba(13, 44, 109, 0.1);
  overflow: hidden;
  direction: rtl;
}

.task-card.on-hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 24px rgba(13, 44, 109, 0.12) !important;
}

.task-header {
  background: linear-gradient(to right, #f8fafc, #ffffff);
  padding: 1.25rem;
  border-bottom: 1px solid rgba(13, 44, 109, 0.1);
}

.task-title {
  color: #1a237e;
  font-size: 1.25rem;
  font-weight: 600;
  margin: 0;
  font-family: 'Rubik', sans-serif;
}

.task-content {
  padding: 1.25rem;
}

.task-details {
  background: rgba(13, 44, 109, 0.02);
  border-radius: 8px;
  padding: 1rem;
}

.task-info {
  margin-bottom: 1rem;
}

.info-item {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.info-icon {
  margin-left: 0.5rem;
}

.action-text,
.trigger-text {
  color: #424242;
  font-size: 0.95rem;
  font-weight: 500;
}

.status-section {
  display: flex;
  justify-content: flex-start;
  margin-top: 0.5rem;
}

.status-chip {
  display: inline-flex;
  align-items: center;
  padding: 0.5rem 1rem;
  border-radius: 100px;
  font-size: 0.875rem;
  gap: 0.5rem;
}

.status-active {
  background: rgba(34, 197, 94, 0.1);
  color: #16a34a;
  border: 1px solid rgba(34, 197, 94, 0.2);
}

.status-inactive {
  background: rgba(239, 68, 68, 0.1);
  color: #ef4444;
  border: 1px solid rgba(239, 68, 68, 0.2);
}

.empty-state {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  color: #64748b;
  gap: 0.5rem;
}

.task-actions {
  padding: 1rem;
  background: #f8fafc;
  display: flex;
  justify-content: center;
}

.edit-btn {
  font-family: 'Rubik', sans-serif;
  font-weight: 500;
  text-transform: none;
  letter-spacing: 0;
  border-radius: 8px;
  padding: 0.5rem 1.5rem;
}

.edit-btn:hover {
  background: rgba(13, 44, 109, 0.05) !important;
}

.edit-btn .v-icon {
  margin-left: 0.5rem;
}
</style>